import { tokenEmailPost, tokenPost } from "../../services/gailServices";
import {STOP_LOADING,START_LOADING} from  "./actionTypes";

// export const postTokenAction = (formData) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: START_LOADING, service: 'SAVE_TOKEN' });
//     //   const token = getState().auth.token || sessionStorage.getItem("token");
//       const response = await saveNote(formData);
//       // console.log(response);
//       dispatch({ type: STOP_LOADING, service: 'SAVE_TOKEN' });
//     //   if (response) {
//     //     callbackfn(response);
//     //   }
//       dispatch({ type: 'SAVE_TOKEN_SUCCESS', payload: response }); // Dispatch the success action with response data
//     } catch (error) {
//       if (error?.includes("403")) {
//         dispatch(openSessionTimeoutModal());
//       } else {
//         dispatch({ type: STOP_LOADING, service: 'SAVE_TOKEN' });
//         dispatch({ type: 'SAVE_TOKEN_FAILURE', payload: error || 'Save token service failed. Please try again.' });
//       }
//     }
//   };


export const postTokenAction = (formData) => async (dispatch,getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'GAIL' });
      // console.log("formdata action",formData);
      const token = getState().auth.token || sessionStorage.getItem("token");;
      const response = await tokenPost(formData,token);
      console.log("response action",response);
    //   sessionStorage.setItem('token', token);
      dispatch({ type: STOP_LOADING, service: 'GAIL' });
      dispatch({ type: 'GAIL_SUCCESS', payload:response });
    //   dispatch(closeSessionTimeoutModal());
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'GAIL' });
      dispatch({ type: 'GAIL_FAILURE', payload: error || 'Gail failed. Please try again.' });
    }
  };


  // export const postEmailTokenAction = (formData) => async (dispatch,getState) => {
  //   try {
  //     dispatch({ type: START_LOADING, service: 'VERIFY_EMAIL' });
  //     // console.log("formdata action",formData);
  //     const token = getState().auth.token || sessionStorage.getItem("token");;
  //     const response = await tokenEmailPost(formData,token);
  //     console.log("response action",response);
  //   //   sessionStorage.setItem('token', token);
  //     dispatch({ type: STOP_LOADING, service: 'VERIFY_EMAIL' });
  //     dispatch({ type: 'VERIFY_EMAIL_SUCCESS', payload:response });
  //   //   dispatch(closeSessionTimeoutModal());
  //   } catch (error) {
  //     dispatch({ type: STOP_LOADING, service: 'VERIFY_EMAIL' });
  //     dispatch({ type: 'VERIFY_EMAIL_FAILURE', payload: error || 'Verify email failed. Please try again.' });
  //   }
  // };

  export const postEmailTokenAction = (formData) => async (dispatch,getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'VERIFY_EMAIL' });
      // console.log("formdata action",formData);
      // const token = getState().auth.token || sessionStorage.getItem("token");;
      const response = await tokenEmailPost(formData);
      console.log("response action",response);
    //   sessionStorage.setItem('token', token);
      dispatch({ type: STOP_LOADING, service: 'VERIFY_EMAIL' });
      dispatch({ type: 'VERIFY_EMAIL_SUCCESS', payload:response });
    //   dispatch(closeSessionTimeoutModal());
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'VERIFY_EMAIL' });
      const errorData = error ? error : {
        errors: [{
          errorMessage: 'Email verification failed. Please try again.',
          target: null
        }]
      };
      dispatch({ type: 'VERIFY_EMAIL_FAILURE', payload: errorData || 'Verify email failed. Please try again.' });
      // callbackfn(null, error);
    }
  };
