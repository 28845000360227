import { SET_THEME } from "./actionTypes";
import { getDefaultThemeService } from '../../services/themeServices';
import { openSessionTimeoutModal } from './sessionTimeOutActions';
import {
  START_LOADING,
  STOP_LOADING
} from "./actionTypes"

export const setTheme = (isAlternate) => ({
    type: SET_THEME,
    payload: isAlternate,
  });
  
export const getThemeDefaultAction = (themeid) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem("token");;    
    const response = await getDefaultThemeService(themeid, token);
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    dispatch({ type: 'GET_DEFAULT_THEME_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    dispatch({ type: 'GET_THEME_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'Get theme service failed. Please try again.' });
  }
};

