import { useEffect } from 'react'
import { AppBar, Toolbar, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { getThemeAction } from '../../redux/actions/themeActions';
import Logo from '../../logo.svg'

const useStyles = makeStyles((customTheme) => {
    return {
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      flexDirection: 'row !important',
    },
    nav: {
        padding: '1em 1em 0',
        margin: '0',
    },
    link: {
        textDecoration: "none !important",
        padding: "2em .5em 0",
        color: customTheme?.palette?.primary?.main,
        "&:hover": {
            color: 'black',
        }
    },
    icon: {
        marginBottom: '-.25em',
    },
    menuLink: {
        color: customTheme?.palette?.primary?.main,
        textDecoration: "none !important",
        padding: ".25em",
    },
    iconColor: {
        color: customTheme?.palette?.primary?.main,
        background: customTheme?.palette?.primary?.main,
    }
  }});

const Header = () => {
    const classes = useStyles();
    const user = useSelector((state) => state?.user?.user);
    const dynamicTheme = useSelector((state) => state?.theme?.theme[0]);
    const dispatch = useDispatch();

    useEffect(() => {
        if(user?.customer?.id) {
            dispatch(getThemeAction(user?.customer?.id))
        }
        
    }, [user])

    return(
        <>
            <AppBar position="fixed">                
                <Toolbar className={classes.header} sx={{position: 'relative', top:'5px'}}>
                    <Typography variant="h4">{dynamicTheme?.fileData !== "default" ? <img src={dynamicTheme?.fileData} width="65" alt='' /> : <img src={Logo} width="65"/>}</Typography>
                </Toolbar>
            </AppBar>
        </>
    )
}

export default Header;