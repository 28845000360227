import {
  CLOSE_CREATE_GENERAL_PARTY_INFO_MODAL,
  OPEN_CREATE_GENERAL_PARTY_INFO_MODAL,
  OPEN_UPDATE_GENERAL_PARTY_INFO_MODAL,
} from "../actions/actionTypes";

const initialState = {
  allGeneralPartyInfo: [],
  postGeneralData: [],
  allGeneralPartyInfoDash: [],
  allComparisionInfo: [],
  xmlJsonCombined:'',
  cityInfo: [],
  error: null,
  isCreateGeneralPartyInfoModalOpen: false,
  selectedGeneralPartyInfoId: null,
};

const generalPartyInfoReducer = (state = initialState, action) => {
  switch (action.type) {
    case "ALL_GENERAL_PARTY_INFOS_SUCCESS":
      return { ...state, allGeneralPartyInfo: action.payload, error: null };
    case "ALL_GENERAL_PARTY_INFOS_DASH_SUCCESS":
      return { ...state, allGeneralPartyInfoDash: action.payload, error: null };
    case "ALL_COMPARISION_SUCCESS":
      return { ...state, allComparisionInfo: action.payload, error: null };
     case "ALL_COMPARISION_SUCCESS_COMBINED":
      return { ...state, xmlJsonCombined: action.payload, error: null };
    case "ALL_GENERAL_PARTY_POST_INFOS_SUCCESS":
      return { ...state, postGeneralData: action.payload, error: null };
    case "FETCH_CITY_ADDRESS_SUCCESS":
      return { ...state, cityInfo: action.payload, error: null };
    case "CREATE_GENERAL_PARTY_INFO_SUCCESS":
    case "UPDATE_GENERAL_PARTY_INFO_SUCCESS":
      return {
        ...state,
        allGeneralPartyInfo: [...state.allGeneralPartyInfo, ...action.payload],
        error: null,
      };
    case OPEN_CREATE_GENERAL_PARTY_INFO_MODAL:
      return { ...state, isCreateGeneralPartyInfoModalOpen: true };
    case OPEN_UPDATE_GENERAL_PARTY_INFO_MODAL:
      return {
        ...state,
        isCreateGeneralPartyInfoModalOpen: true,
        selectedGeneralPartyInfoId: action.payload,
      };
    case CLOSE_CREATE_GENERAL_PARTY_INFO_MODAL:
      return {
        ...state,
        isCreateGeneralPartyInfoModalOpen: false,
        selectedGeneralPartyInfoId: null,
      };
    case "ALL_GENERAL_PARTY_INFOS_FAILURE":
    case "ALL_GENERAL_PARTY_POST_INFOS_FAILURE":
    case "CREATE_GENERAL_PARTY_INFO_FAILURE":
    case "FETCH_CITY_ADDRESS_FAILURE":
    case "UPDATE_GENERAL_PARTY_INFO_FAILURE":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default generalPartyInfoReducer;
