import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FrameComponent1 } from "../frame/FrameComponent1";
import { FrameComponent2 } from "../frame/FrameComponent2";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Alert,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
  Select,
} from "@mui/material";
import { DateField } from "@mui/x-date-pickers/DateField";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import {
  alphanumericWithSpaceValidation,
  validateEmail,
  validateField,
  validateDate,
} from "../../common/helpers/validation";
import {
  getGeneralPartyInfoById as getGeneralPartyInfoByIdAction,
  createGeneralPartyInfo as createGeneralPartyInfoAction,
  updateGeneralPartyInfo,
  fetchPriorInsuranceCarriers,
} from "../../redux/actions/generalPartyInfoActions";
import { CircleOutlined } from "@mui/icons-material";
import {
  CityList,
  CommonNumbers,
  CommonYesNo,
  ContactMethod,
  LanguageList,
  MonthList,
  Rental,
  StateFiling,
  accidentalDeath,
  biLimitPerAccident,
  biLimitPerPerson,
  blankOptions,
  comp,
  genderList,
  leadSource,
  liability1,
  medLimit,
  medicalPayments,
  pdLimit,
  pipDed,
  pipOption,
  priorPipClaimsList,
  residenceType,
  residenceship,
  stateList,
  towing,
  priorInsurance,
  timewithPriorInsurance,
  priorinsurancecarrierflorida,
  umBILimitPerAccident,
  umBILimitPerPerson,
  umCslLimit,
  umLimitPerAccident,
  umLimitPerPerson,
  umOptions,
  umStacked,
  uninsuredBi,
  wageLossExclusion,
} from "../../common/StaticFieldList";
// import { priorInsuranceCarriersOptions,fetchAndTransformPriorInsuranceCarriers } from "../../common/priorInsuranceCarriersData";
import { tabActions } from "../../redux/actions/tabActions";
// import PrevNextButton from "../../common/PrevNextButton";
import { populateValue } from "../../common/helpers/utils";
import { blue } from "@mui/material/colors";
import { DisplayFetchedData } from "./DisplayFetchedData";
// import { format } from 'date-fns';

const requiredFields = ["effectiveDate", "priorInsurance", "leadSource"];

// const validationRulesGeneral1 = [
//   {
//     fieldName: "policyTerms",
//     label: "Policy Terms",
//     type: "select",
//     option: CommonNumbers,
//     id: "policyterms"
//   },
//   {
//     fieldName: "allowCreditScore",
//     label: "Is Credit check allowed?",
//     type: "select",
//     option: CommonYesNo,
//     id: "allowedcreditscore"
//   },
//   {
//     fieldName: "leadSource",
//     label: "Lead Source",
//     type: "select",
//     option: leadSource,
//     id: "leadsource"
//   },
// ];

const validationFunctions = {
  // effectiveDate: validateDate,
};

const useStyles = makeStyles(() => ({
  formContainer: {
    // marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: "flex",
    alignItems: "center",
    // marginTop: '.5rem !important',
  },

  fullWidth: {
    width: "100%",
  },
}));

// const formatDate = (date) => {
//   // if (!date) return '';
//   return format(new Date(date), "MM/dd/yyyy");
// };

const GeneralQuote = ({ handleNext, handleBack, data ,id}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  // console.log(priorInsuranceCarriersOptions)
  let { quoteId } = useParams();
  console.log("quoteId params",quoteId);
  const loading = useSelector((state) => state.loading.CREATE_USER);
  // const formattedValue = type === 'date' ? formatDate(formData[fieldName]) : formData[fieldName];
  const priorInsuranceCarriers = useSelector(
    (state) => state.priorInsuranceCarriers.priorInsuranceCarriers
  );
  const error = useSelector((state) => state.priorInsuranceCarriers.error);

  const userid = useSelector((state) => state?.user?.user?.id);

  useEffect(() => {
    dispatch(fetchPriorInsuranceCarriers());
  }, [dispatch]);

  let priorInsuranceCarriersOptionss = [];
  console.log("priorInsuranceCarriers", priorInsuranceCarriers);
  if (Array.isArray(priorInsuranceCarriers)) {
    priorInsuranceCarriersOptionss = priorInsuranceCarriers.map((carrier) => ({
      // value: carrier.carrierCode,
      value: carrier.carrierName,
      label: carrier.carrierName,
    }));
  }
  console.log("priorInsuranceCarriersOptionss", priorInsuranceCarriersOptionss);
  const validationRulesGeneral = [
    {
      fieldName: "effectiveDate",
      label: "Policy Effective Date",
      type: "date",
      format: "mm/dd/yyyy",
      id: "effectivedate"
    },
    {
      fieldName: "policyTerms",
      label: "Policy Terms",
      type: "select",
      option: CommonNumbers,
      id: "policyterms"
    },
    {
      fieldName: "priorInsurance",
      label: "Prior Insurance",
      type: "select",
      option: priorInsurance,
      id: "priorinsurance"
    },
    {
      fieldName: "allowCreditScore",
      label: "Is Credit check allowed?",
      type: "select",
      option: CommonYesNo,
      id: "allowedcreditscore"
    },
    {
      fieldName: "priorExpirationDate",
      label: "Prior Carrier End Date",
      type: "date",
      id: "priorexpirationdate"
    },

    {
      fieldName: "priorInsuranceCarrier",
      label: "Prior Insurance Carrier",
      type: "select",
      option: priorInsuranceCarriersOptionss,
      id: "priorinsurancecarrier"
    },
    {
      fieldName: "leadSource",
      label: "Lead Source",
      type: "select",
      option: leadSource,
      id: "leadsource"
    },
    {
      fieldName: "priorpolicyTerms",
      label: "Prior Policy Terms",
      type: "select",
      option: CommonNumbers,
      id: "policyterms"
    },
  ];

  const newFieldArg = [...validationRulesGeneral];

  const [formState, setFormState] = useState({
    formData: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: "",
      }),
      {}
    ),
    touchedFields: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }),
      {}
    ),
    fieldErrors: newFieldArg.reduce(
      (errors, { fieldName }) => ({
        ...errors,
        [fieldName]: "",
      }),
      {}
    ),
  });

  // if (priorInsuranceCarriers.length === 0) {
  //   return <div>Loading...</div>;
  // }

  // if (error) {
  //   return <div>Error: {error}</div>;
  // }
  // const priorInsuranceCarriers = useSelector(state => state.priorInsuranceCarriers);
  // const error = useSelector(state => state.error);
  // useEffect(() => {
  //   dispatch(fetchPriorInsuranceCarriers());
  // })

  // const priorInsuranceCarriersOptionss = priorInsuranceCarriers.map(carrier => ({
  //   value: carrier.value,
  //   label: carrier.label,
  // }));
  // console.log("priorInsuranceCarriersOptionss",priorInsuranceCarriersOptionss)

  // useEffect(() => {
  //   dispatch(fetchPriorInsuranceCarriers());
  // }, [dispatch]);

  // useEffect(() => {
  //   fetchAndTransformPriorInsuranceCarriers();
  // }, []);
  // dispatch(getGeneralPartyInfoByIdAction(id));

  useEffect(() => {
    // if (!Object.keys(user).length) {
    // dispatch(getUserAction());
    if (id) {
      // console.log("quoteId in useEffect", quoteId)
      dispatch(getGeneralPartyInfoByIdAction(id));
    }
    // }
  }, []);
  useEffect(() => {
    // if (!Object.keys(user).length) {
    // dispatch(getUserAction());
    if (quoteId) {
      console.log("quoteId in useEffect", quoteId)
      dispatch(getGeneralPartyInfoByIdAction(quoteId));
    }
    // }
  }, []);


  const selectedUserId = useSelector((state) => state.user.selectedUserId);

  const generalPartyInfo = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfo
  );

  // console.log("generalPartyinfo all values",generalPartyInfo.quoteId);
  // const newquoteId=generalPartyInfo.quoteId;
  const { formData, touchedFields, fieldErrors } = formState;

  const handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };

  const handleDateChange = (date, fieldName) => {
    const formattedDate = date ? dayjs(date).format("MM-DD-YYYY") : "";

    // Construct a custom event object with preventDefault method
    const customEvent = {
      preventDefault: () => {}, // Define a dummy preventDefault method
      target: {
        name: fieldName,
        value: formattedDate,
      },
    };

    // Call handleInputChange with the custom event object
    handleInputChange(customEvent);
  };

  // const handleChange = (e) => {
  //   // If the field is a date, parse and format it
  //   if (type === 'date') {
  //     const date = new Date(e.target.value);
  //     handleInputChange({ target: { name: validationRulesGeneral.fieldName, value: date.toISOString() } });
  //   } else {
  //     handleInputChange(e);
  //   }
  // };

  const handleBlur = (field) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      touchedFields: {
        ...prevFormState.touchedFields,
        [field]: true,
      },
    }));

    if (!requiredFields.includes(field)) {
      return;
    }

    const value = formData[field];
    const error = validateField(
      value,
      newFieldArg.find((rule) => rule.fieldName === field).label
    );

    // Additional validations
    const additionalError = validationFunctions[field]
      ? validationFunctions[field](value)
      : "";

    setFormState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        [field]: error || additionalError,
      },
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };
  // console.log("formData", formData);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = newFieldArg.reduce(
      (errors, { fieldName, label }) => {
        if (requiredFields.includes(fieldName)) {
          const value = formData[fieldName];
          const error = validateField(value, label);

          // Additional validations
          const additionalError = validationFunctions[fieldName]
            ? validationFunctions[fieldName](value)
            : "";

          setFormState((prevFormState) => ({
            ...prevFormState,
            fieldErrors: {
              ...prevFormState.fieldErrors,
              [fieldName]: error || additionalError,
            },
          }));

          return {
            ...errors,
            [fieldName]: error || additionalError,
          };
        }

        return errors;
      },
      {}
    );

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        const formattedFormData = {
          ...formData,
          effectiveDate: formData.effectiveDate ? dayjs(formData.effectiveDate).format('MM-DD-YYYY') : "", // Format effectiveDate
        priorExpirationDate: formData.priorExpirationDate ? dayjs(formData.priorExpirationDate).format('MM-DD-YYYY') : "", // Format priorExpirationDate
        };
        if (quoteId || id) {
          console.log("quote id in update", quoteId);
          const newFormData = {
            ...formattedFormData,
            ...data,
            // gpId: quoteId,
            gpId: id ? parseInt(id) : parseInt(quoteId),
            createdBy:userid,
            // id: parseInt(quoteId),
          };
          await dispatch(
            updateGeneralPartyInfo(newFormData, getCreateUserSuccess)
          );
        } else {
          const newFormData = {
            ...formData,
            ...data,
          };
          await dispatch(
            createGeneralPartyInfoAction(newFormData, getCreateUserSuccess)
          );
        }
      } catch (error) {
        handleSnackbarOpen(
          error.message || "User service failed. Please try again.",
          "error"
        );
      }
    } else {
      handleSnackbarOpen(
        "Validation errors detected. Please correct them.",
        "error"
      );
    }
    handleNext();
  };

  const getCreateUserSuccess = async (uid) => {
    handleSnackbarOpen("Created successfully.", "success");
    navigate(`/quote/${uid}`);
    await dispatch(tabActions(1));
  };
  // const getCreateUserSuccess = async (newquoteId) => {
  //   handleSnackbarOpen("Created successfully.", "success");
  //   console.log("getcreateusersuccessquoteid",quoteId);
  //   navigate(`/quote/${newquoteId}`);
  //   await dispatch(tabActions(1));
  // };


  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      formData: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]:
            (generalPartyInfo &&
              populateValue(
                generalPartyInfo[fieldName],
                fieldName,
                newFieldArg
              )) ||
            "",
          // organizationId: generalPartyInfo?.organization?.id
        }),
        {}
      ),
    }));
  }, [generalPartyInfo]);

  return (
    <>
      {/* <PrevNextButton /> */}
      {/* <Box
            sx={{
                width: "100%",
                position: "relative",
                backgroundColor: "#f4f5f9",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                padding: "0px 0px 156px",
                boxSizing: "border-box",
                lineHeight: "normal",
                letterSpacing: "normal",
            }}
        > */}
      {/* <FrameComponent2 /> */}
      <Box
        sx={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "36px",
          maxWidth: "100%",
        }}
      >
        <section
          sx={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 23px 0px 20px",
            boxSizing: "border-box",
            maxWidth: "100%",
            textAlign: "left",
            fontSize: "14px",
            color: "#242929",
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          <Box
          id= "form"
            component="form"
            onSubmit={handleSubmit}
            sx={{
              width: "853px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              border: "1px solid #e3e3e3",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "flex-start",
              padding: "30px 0px 24px",
              gap: "23px",
              maxWidth: "100%",
            }}
          >
            <Box
              sx={{
                alignSelf: "stretch",
                height: "798px",
                position: "relative",
                borderRadius: "4px",
                backgroundColor: "#fff",
                border: "1px solid #e3e3e3",
                boxSizing: "border-box",
                display: "none",
              }}
            />
            <Typography
            id= "generalquoteinformation"
              variant="h1" // Adjust the variant as needed, e.g., h1, h2, h3, etc.
              component="div"
              sx={{
                border: "none",
                backgroundColor: "transparent",
                alignSelf: "stretch",
                height: "51px",
                fontFamily: '"Poppins", sans-serif',
                fontWeight: "600",
                fontSize: "25px",
                color: "#242929",
                paddingLeft: "50px",
                display: "flex",
                alignItems: "center",
              }}
            >
              General Quote Information
            </Typography>
            <hr style={{ borderTop: "1px solid #ccc", width: "100%" }} />
            <Box
              sx={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                padding: "0px 44px 0px 52px",
                boxSizing: "border-box",
                maxWidth: "100%",
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                  maxWidth: "100%",
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4px",
                    minWidth: "240px",
                    fontFamily: '"Poppins", sans-serif',
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: "stretch",
                      display: "grid",
                      gridTemplateColumns: "1fr 1fr",
                      gap: "5px 20px",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 8px",
                    }}
                  >
                    {validationRulesGeneral.map(
                      (
                        { fieldName, label, defaultValue, type, option },
                        index
                      ) => (
                        <React.Fragment key={index}>
                          <Box
                          sx={{
                            display: "flex",
                            flexFlow: "column",
                            margin: "0 0 15px 0",
                          }}
                          key={index}
                        >
                          <Typography
                            key={fieldName} // Assign a unique key to each element
                            variant="body1"
                            sx={{
                              position: "relative",
                              lineHeight: "40px",
                              fontWeight: "500",
                              display: "inline-block",
                              minWidth: "83px",
                              zIndex: "1",
                              fontFamily: '"Poppins", sans-serif',
                            }}
                          >
                            {label}
                          </Typography>
                          {type === "date" ? (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                format="MM - DD - YYYY"

                                value={formData[fieldName] ? dayjs(formData[fieldName], 'YYYY-MM-DD') : null}
                                maxDate={dayjs('2040-04-04')}
                                minDate={dayjs("2001-01-01")}
                                // value={formData[fieldName]}
                                onChange={(date) => handleDateChange(date, fieldName)}
                                slotProps={{
                                  textField: {
                                    size: "medium",
                                    sx: {
                                      width: 370,
                                      border: "none",
                                      backgroundColor: "transparent",
                                      alignSelf: "stretch",
                                      fontFamily: '"Poppins", sans-serif',
                                      fontWeight: "500",
                                      fontSize: "16px",
                                      color: "#242929",
                                      zIndex: "1",
                                      "& .MuiOutlinedInput-root": {
                                        "& fieldset": { borderColor: "#242929 !important" }, // Forces border color to be black
                                      },
                                    },
                                    inputProps: {
                                      readOnly: true, // Prevents typing
                                    },
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          ):
                          (<TextField
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              alignSelf: "stretch",
                              height: "52px",
                              fontFamily: '"Poppins", sans-serif',
                              fontWeight: "500",
                              fontSize: "14px",
                              color: "#242929",
                              zIndex: "1",
                            }}
                            type={type}
                            variant="outlined"
                            defaultValue={formData[fieldName]}
                            select={type === "select"}
                            required={requiredFields.includes(fieldName)}
                            id={fieldName}
                            // label={label}
                            placeholder={label}
                            name={fieldName}
                            autoComplete={fieldName}
                            value={formData[fieldName]}
                            // value={formattedValue}
                            // onChange={handleChange}
                            onChange={(e) => handleInputChange(e)}
                            onBlur={() => handleBlur(fieldName)}
                            error={
                              touchedFields[fieldName] &&
                              !!fieldErrors[fieldName]
                            }
                            helperText={
                              touchedFields[fieldName]
                                ? fieldErrors[fieldName]
                                : ""
                            }
                            sx={{
                              "& fieldset": { borderColor: "#C1C1C1", fontFamily: '"Poppins", sans-serif', },
                              "& .MuiInputBase-root": {
                                height: "52px",
                                backgroundColor: "#fff",
                                fontSize: "16px",
                                fontFamily: '"Poppins", sans-serif',
                              },
                              "& .MuiInputBase-input": { color: "#242929", fontFamily: '"Poppins", sans-serif', },
                            }}
                          >
                            {type === "select" &&
                              option.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  placeholder={label}
                                  value={option.value}
                                  sx={{fontFamily: '"Poppins", sans-serif',}}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                            {/* console.log(value) */}
                          </TextField>)}
                          </Box>
                        </React.Fragment>
                      )
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <div
              style={{
                width: "222px",
                height: "42px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-start",
                gap: "14px",
                fontFamily: '"Poppins", sans-serif',
              }}
            >
              <Button
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  cursor: "pointer",
                  zIndex: "1",
                  fontFamily: '"Poppins", sans-serif',
                }}
                startIcon={<ArrowBackIosIcon />}
                disableElevation={true}
                variant="outlined"
                className="btn-secondary"
                id="backbutton"
                sx={{
                  textTransform: "none",
                  color: "#077BFD",
                  fontSize: "16px",
                  borderColor: "#C1C1C1",
                  fontFamily: '"Poppins", sans-serif',
                  borderRadius: "4px",
                  "&:hover": { borderColor: "#077BFD" },
                }}
                // onClick={}
                onClick={handleBack}
              >
                Back
              </Button>

              <Button
                type="submit"
                style={{
                  alignSelf: "stretch",
                  flex: "1",
                  cursor: "pointer",
                  zIndex: "1",
                  fontFamily: '"Poppins", sans-serif',
                }}
                endIcon={<ArrowForwardIosIcon />}
                disableElevation={true}
                variant="contained"
                className="btn-secondary"
                id="nextbutton"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontSize: "16px",
                  fontFamily: '"Poppins", sans-serif',
                  background: "rgba(7, 123, 253, 0.6)",
                  borderRadius: "4px",
                  marginRight: "20px",
                  "&:hover": { background: "rgba(7, 123, 253, 1)" },
                }}
                // onClick={handleNext}
              >
                Next
              </Button>
            </div>
          </Box>
          <div
            style={{
              alignSelf: "stretch",
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-start",
              justifyContent: "center",
              padding: "20px 20px 50px 23px",
              fontFamily: '"Poppins", sans-serif',
            }}
          >
            <Button
              style={{ height: "42px", width: "140px", cursor: "pointer" }}
              disableElevation={true}
              variant="contained"
              id="continuebutton"
              sx={{
                textTransform: "none",
                color: "#fff",
                fontSize: "16px",
                background: "#077BFD",
                borderRadius: "4px",
                fontFamily: '"Poppins", sans-serif',
                "&:hover": { background: "#077BFD" },
                width: 140,
                height: 42,
              }}
              className="btn-primary"
              onClick={handleNext}
            >
              Continue
            </Button>
          </div>
        </section>
      </Box>
      {/* </Box> */}
    </>
  );
};

export default GeneralQuote;
