import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography
} from '@mui/material';

const transactions = [
  {
    transaction: "New Business",
    entryDate: "01-27-2023",
    effDate: "01-27-2023",
    amount: "$145.00",
    commission: "$40.00",
    policyPremium: "$140.00"
  },
  {
    transaction: "Endorsement",
    entryDate: "06-27-2023",
    effDate: "06-27-2023",
    amount: "$153.00",
    commission: "$30.00",
    policyPremium: "$130.00"
  },
  {
    transaction: "Renewal",
    entryDate: "01-28-2023",
    effDate: "01-28-2023",
    amount: "$145.00",
    commission: "$30.00",
    policyPremium: "$130.00"
  }
];

const TransactionHistory = () => {
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow className="styled-table-row">
            <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>
              <Typography id="transaction" variant="subtitle1" color="black">Transaction</Typography>
            </TableCell>
            <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>
              <Typography id="entrydate" variant="subtitle1" color="black">Entry Date</Typography>
            </TableCell>
            <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>
              <Typography id="effdate" variant="subtitle1" color="black">Eff Date</Typography>
            </TableCell>
            <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>
              <Typography id="amount" variant="subtitle1" color="black">Amount</Typography>
            </TableCell>
            <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>
              <Typography id="commission" variant="subtitle1" color="black">Commission</Typography>
            </TableCell>
            <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>
              <Typography id="policypremium" variant="subtitle1" color="black">Policy Premium</Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {transactions.map((transaction, index) => (
            <TableRow key={index}  className="styled-table-row">
              <TableCell>{transaction.transaction}</TableCell>
              <TableCell>{transaction.entryDate}</TableCell>
              <TableCell>{transaction.effDate}</TableCell>
              <TableCell>{transaction.amount}</TableCell>
              <TableCell>{transaction.commission}</TableCell>
              <TableCell>{transaction.policyPremium}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TransactionHistory;
