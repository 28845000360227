import { SET_THEME } from '../actions/actionTypes';

const initialState = {
  useAlternateTheme: 'deltorotheme',
};

const themeSettingReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_THEME':
      return {
        ...state,
        useAlternateTheme: action.payload,
      };
    default:
      return state;
  }
};
export default themeSettingReducer;