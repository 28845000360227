import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  TextField,
  Box,
  Alert,
  Grid,
  Typography,
  Modal,
  Paper,
  FormControlLabel,
  Checkbox
} from "@mui/material";
import { useNavigate, useLocation } from 'react-router-dom';
import { alphanumericWithSpaceValidation, validateField } from '../../../../common/helpers/validation';
  import {
    createMaindropdown as createMaindropdownAction,getMaindropdownBylabel } from '../../../../redux/actions/dropdownActions';

 //////////// teams data
 import {
  getUser as getUserAction,
  getAllUser as getAllUserAction,
  openCreateUserModal as openCreateUserModalAction,
  openUpdateUserModal as openUpdateUserModalAction
} from '../../../../redux/actions/userActions';


const requiredFields = ['cfieldLabel', 'cfieldName'];

const validationRules = [
  { fieldName: 'cfieldLabel', label: 'formLabel',minRows:0,maxRows:0,xs:6 },
  { fieldName: 'cfieldName', label: 'fieldName',minRows:0,maxRows:0,xs:6 },
  { fieldName: 'cfieldValue', label: 'Enter values, with each value separated by a new line',minRows:4,maxRows:4,xs:12 },
  { fieldName: 'file', label: 'Upload CSV',xs:12 },
  { fieldName: 'description', label: 'Description',minRows:4,maxRows:4,xs:12 },
  { fieldName: 'helpText', label: 'Help Text',minRows:4,maxRows:4,xs:12 },
];

const validationFunctions = {
  name: validateField,
  description: alphanumericWithSpaceValidation,
};

const MainDropdownForm = () => {
 const allUser = useSelector((state) => state?.user?.allUser);
 const loading = useSelector((state) => state.loading.ALL_USERS);
 const user = useSelector((state) => state.user.user);

  // const allCustomer = useSelector((state) => state?.newdropdowns?.allDropdowns);
  // const dropdownDataForUpdate = newdropdowns && allCustomer.length && allCustomer.filter((customer) => customer.id === selectedCustomerId);
  // const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  // csv file
  const [file, setFile] = useState();
  const [csvdata, setCsvdata] = useState();
  const fileReader = new FileReader();
  ///////////

  const [allUserData, setMyAllUsersData] = React.useState(allUser || []);

  const [formState, setFormState] = useState({
    formData: validationRules.reduce((fields, { fieldName }) => ({
      ...fields,
      [fieldName]: '',
    }), {}),
    touchedFields: validationRules.reduce((fields, { fieldName }) => ({
      ...fields,
      [fieldName]: false,
    }), {}),
    fieldErrors: validationRules.reduce((errors, { fieldName }) => ({
      ...errors,
      [fieldName]: '',
    }), {})
  });
  const { formData, touchedFields, fieldErrors } = formState;

  ///////////////// data
// React.useEffect(() => {
//     //  dispatch(getAllUserAction(user?.id));
//    // dispatch(getMaindropdownBylabel('US_STATES'));
//      dispatch(getMaindropdownBylabel(formData['formLabel']));
// }, [])

// React.useEffect(() => {
//   setMyAllUsersData(allUser || []);
// }, [allUser, dispatch, user]);

// console.log(allUserData['cfieldName'],formData['formLabel']);

  const handleInputChange = (e) => {

    //setFile(e.target.files[0]);
    const { name, value } = e.target;

    if(name=='cfieldLabel'){
      /// Fetch record by Label
     // dispatch(getMaindropdownBylabel(value));
    }
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [name]: value,
      },
    }));
  };
    /// Fetch record by Label set data
  React.useEffect(() => {
    setMyAllUsersData(allUser || []);
  }, [allUser, dispatch, user]);
  console.log(allUserData,'HERE');

  const handleBlur = (field) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      touchedFields: {
        ...prevFormState.touchedFields,
        [field]: true,
      },
    }));

    if (!requiredFields?.includes(field)) {
      return;
    }

    const value = formData[field];
    const error = validateField(value, validationRules.find(rule => rule.fieldName === field).label);

    // Additional validations
    const additionalError = validationFunctions[field] ? validationFunctions[field](value) : '';

    setFormState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        [field]: error || additionalError,
      },
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleCancel = (event) => {
    event.preventDefault();
    setFormState((prevFormState) => ({
      ...prevFormState,
      formData: {
        "cfieldLabel": '',
        "cfieldName": '',
        "cfieldValue": '',
        "description": '',
        "helpText": '',
        "crequired": ''
    },
    }));

  };

const handleFiles = (e) => {
  // console.log(e.target.files[0],'here');
   setFile(e.target.files[0]);
 };

  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = validationRules.reduce((errors, { fieldName, label }) => {
      if (requiredFields?.includes(fieldName)) {
        const value = formData[fieldName];
        const error = validateField(value, label);

        // Additional validations
        const additionalError = validationFunctions[fieldName] ? validationFunctions[fieldName](value) : '';

        setFormState((prevFormState) => ({
          ...prevFormState,
          fieldErrors: {
            ...prevFormState.fieldErrors,
            [fieldName]: error || additionalError,
          },
        }));

        return {
          ...errors,
          [fieldName]: error || additionalError,
        };
      }

      return errors;
    }, {});

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        //console.log(formData,'hello');
          //for csv Files
          if (file) {
            fileReader.onload = function (event) {
              const csvOutput = event.target.result;
              var csvOutputData = csvOutput.split("\n");
              setCsvdata(csvOutputData);
            };
            fileReader.readAsText(file);
          }
          // For csv end
          //  const newFormData = {
          //   ...formData
          // };
          let values2;
          if(file){
            console.log('first');
            values2 = csvdata;
          }else{
            let values1 = formData.cfieldValue;
             values2 = values1.split("\n");
             console.log('second');
        }
        let fieldValue = values2.filter(function (el) {
          return el != "";
        });
          const newFormData = {
          cfieldLabel: formData.cfieldLabel,
          cfieldName: formData.cfieldName,
          cfieldValue: fieldValue,
          cdescLong: formData.cdescLong,
          helpText: formData.helpText,
          crequired: (formData.isRequired)?true:false,
          dtype: "P",
          bretired: true,
       };
       console.log(newFormData,'h1');
          await dispatch(createMaindropdownAction(newFormData, getCreateRoleSuccess));
      } catch (error) {
        handleSnackbarOpen(error || 'Dropdown failed. Please try again.', 'error');
      }
    } else {
      handleSnackbarOpen('Validation errors detected. Please correct them.', 'error');
    }
  };

  const getCreateRoleSuccess = async() => {
   // selectedRoleId ? handleSnackbarOpen('Role updated successfully.', 'success') : handleSnackbarOpen('Role created successfully.', 'success');
   // await dispatch(getAllRolesAction());

    setFormState({
      formData: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: '',
      }), {}),
      touchedFields: validationRules.reduce((fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }), {}),
      fieldErrors: validationRules.reduce((errors, { fieldName }) => ({
        ...errors,
        [fieldName]: '',
      }), {}),
    });
  };
  ///// edit data
  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      formData: {
        "cfieldLabel": allUserData.cfieldLabel,
        "cfieldName": allUserData.cfieldName,
        "cfieldValue": (allUserData.cfieldValue)?allUserData.cfieldValue.join('\n'):'',
        "description": allUserData.description,
        "helpText": allUserData.helpText,
        "crequired": allUserData.crequired,
    },
    }));
  }, [allUserData]);
  ///////////

  return (
          <Box>
            <Grid onSubmit={handleSubmit} container item spacing={2} xs={6} >
            {/* <Box component="form" onSubmit={handleSubmit} container item spacing={2} xs={6}> */}
              {/* <Grid container spacing={2}> */}
                {validationRules.map(({ fieldName, label,minRows,maxRows,xs }) => (

                  <Grid item xs={xs} key={fieldName}>
                    {fieldName=='file' ?
                  <Button id="uploadcsv" variant="contained" component="label">Upload CSV
                   <input type={"file"} id={"csvFileInput"} accept={".csv"} onChange={handleFiles} hidden/></Button>
                   :
                    <TextField
                      required={requiredFields?.includes(fieldName)}
                      fullWidth
                      id={fieldName}
                      label={label}
                      name={fieldName}
                      minRows={minRows}
                      maxRows={maxRows}
                      multiline
                      autoComplete={fieldName}
                      value={formData[fieldName]}
                      onChange={handleInputChange}
                      onBlur={() => handleBlur(fieldName)}
                      error={touchedFields[fieldName] && !!fieldErrors[fieldName]}
                      helperText={touchedFields[fieldName] ? fieldErrors[fieldName] : ''}
                    /> }
                  </Grid>
                ))}
            </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={<Checkbox onChange={handleInputChange} name="isRequired" />}
            label="Required"/>
        </Grid>
        <Grid item xs={12}>
             <Button id="cancel" variant="outlined" type="submit" onClick={handleCancel}>
              cancel
            </Button> &nbsp;
            <Button id="save" variant="contained" type="submit" onClick={handleSubmit}>
              Next
            </Button>
        </Grid>
      </Box>
  );
};

export default MainDropdownForm;
