import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Grid, FormControl, Typography, MenuItem, TextField, Select, RadioGroup, FormControlLabel, Radio, FormHelperText } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import FormLabelText from '../../../common/FormLabelText';
import AddressSection from './AddressSection';

const loginUserName = sessionStorage.getItem('loginUserName');
const userId = sessionStorage.getItem('userId');

// const ownerNameList = [
//   { label: 'Bertha Boxer' },
//   { label: 'John' },
//   { label: 'Steve' },
//   { label: 'Mark' },
// ];
const leadFormFiledData = [
  {
    fieldName: 'ownerName1',
    label: 'Owner Name *',
    type: 'text',
    defaultValue: loginUserName,
    disabled:true,
    id:'ownerName1'
  },
  // {
  //   fieldName: 'ownerName',
  //   label: 'Owner Name *',
  //   type: 'autocomplete',
  //   options: ownerNameList,
  //   rules: { required: 'Owner Name is required' },
  //   defaultValue: loginUserName
  // },
  {
    fieldName: 'customerType',
    label: 'Customer Type *',
    id:'customertype',
    type: 'dropdown',
    options: ['Personal Lines', 'Commercial'],
    rules: { required: 'Customer Type is required' },
    defaultValue: 'Personal Lines'
  },
  {
    fieldName: 'firstName',
    id:'firstname',
    label: 'First Name *',
    type: 'text',
    rules: {
      required: 'First name is required',
    },
    defaultValue: ''
  },
  {
    fieldName: 'middleName',
    id:'middlename',
    label: 'Middle Name',
    type: 'text',
  },
  {
    fieldName: 'lastName',
    id:'lastname',
    label: 'Last Name *',
    type: 'text',
    rules: {
      required: 'Last name is required',
      maxLength: { value: 50, message: 'Last Name cannot exceed 50 characters' }
    },
    defaultValue: ''
  },
  // {
  //   fieldName: 'name',
  //   label: 'Name *',
  //   type: 'text',
  //   rules: {
  //     required: 'Name is required',
  //   },
  //   defaultValue: ''
  // },
  {
    fieldName: 'status',
    label: 'Status',
    id:'status',
    type: 'dropdown',
    options: ['Active', 'New', 'In progress', 'Rejected', 'Completed'],
    rules: { required: 'Status is required' },
    defaultValue: 'Active'
  },
  {
    fieldName: 'email',
    label: 'Email *',
    id:'email',
    type: 'text',
    rules: {
      required: 'Email is required',
      pattern: {
        value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        message: 'Enter a valid email address'
      }
    },
    defaultValue: ''
  },
  {
    fieldName: 'userId',
    label: '',
    id:'userId',
    type: 'hidden',
    defaultValue: userId
  },
  {
    fieldName: 'ownerName',
    label: '',
    id:'ownername',
    type: 'hidden',
    defaultValue: loginUserName,
  },
];

const LeadSection = () => {

  const { control, formState: { errors } } = useFormContext();

  return (
    <>
      <Box sx={{ border: '#ccc solid 1px', borderRadius: '4px', padding: '20px', margin: '20px 0 0 0', position: 'relative' }}>
        <FormLabelText labelText="Lead" />
        <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ margin: '0' }}>
          {leadFormFiledData.slice(0, 2).map(({ fieldName, label, type, options, rules, defaultValue,disabled }) => (
            <Grid key={fieldName} item xs={12} sm={6} md={4} sx={{ margin: '0', padding: '0 25px !important' }}>
              <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                <Typography>{label}</Typography>
                <Controller
                  name={fieldName}
                  control={control}
                  rules={rules}
                  defaultValue={defaultValue}
                  disabled={disabled}
                  render={({ field }) => {
                    if (type === 'text' || type === 'number') {
                      return (
                        <TextField
                          {...field}
                          type="text"
                          inputProps={type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 } : {}}                          
                          error={!!errors[fieldName]}
                        />
                      );
                    } else if (type === 'dropdown') {
                      return (
                        <Select
                          {...field}
                          error={!!errors[fieldName]}
                        >
                          {options.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    } else if (type === 'autocomplete') {
                      return (
                        <Autocomplete
                          {...field}
                          options={options}
                          getOptionLabel={(option) => option.label}
                          onChange={(_, data) => field.onChange(data)}
                          defaultValue={defaultValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!errors[fieldName]}
                            />
                          )}
                        />
                      );
                    }
                    return null;
                  }}
                />
                <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
              </FormControl>
            </Grid>
          ))}
        </Grid>
        <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ margin: '0' }}>
          {leadFormFiledData.slice(2,11).map(({ fieldName, label, type, options, rules, defaultValue }) => (
            <Grid key={fieldName} item xs={12} sm={6} md={4} sx={{ margin: '0', padding: '0 25px !important' }}>
              <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                <Typography>{label}</Typography>
                <Controller
                  name={fieldName}
                  control={control}
                  rules={rules}
                  defaultValue={defaultValue}
                  render={({ field }) => {
                    if (type === 'text' || type === 'number') {
                      return (
                        <TextField
                          {...field}
                          type="text"
                          inputProps={type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 } : {}}                          
                          error={!!errors[fieldName]}
                        />
                      );
                    } else if (type === 'dropdown') {
                      return (
                        <Select
                          {...field}
                          error={!!errors[fieldName]}
                        >
                          {options.map((option, index) => (
                            <MenuItem key={index} value={option}>
                              {option}
                            </MenuItem>
                          ))}
                        </Select>
                      );
                    } else if (type === 'autocomplete') {
                      return (
                        <Autocomplete
                          {...field}
                          options={options}
                          getOptionLabel={(option) => option.label}
                          onChange={(_, data) => field.onChange(data)}
                          defaultValue={defaultValue}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={!!errors[fieldName]}
                            />
                          )}
                        />
                      );
                    } else if (type === 'radio') {
                      return (
                        <RadioGroup
                          {...field}
                          row
                          aria-labelledby={fieldName}
                          value={field.value || ''}
                          onChange={(e) => field.onChange(e.target.value)}
                        >
                          {options.map((option, index) => (
                            <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
                          ))}
                        </RadioGroup>
                      );
                    }
                    return null;
                  }}
                />
                <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
              </FormControl>
            </Grid>
          ))}
        </Grid>
      </Box>
      <AddressSection />
    </>
  );
};

export default LeadSection;
