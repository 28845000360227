import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { FrameComponent1 } from "../frame/FrameComponent1";
import { FrameComponent2 } from "../frame/FrameComponent2";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import GeneralQuote from "./generalQuote";
// import NavigationButton from "../../common/PrevNext";
import { getGpIdFromRandomString } from "../../common/helpers/utils";
import {
  Button,
  TextField,
  Box,
  Snackbar,
  Alert,
  Grid,
  Typography,
  FormControl,
  MenuItem,
  InputLabel,
  FormHelperText,
  Select,
} from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import dayjs from "dayjs";
import { makeStyles } from "@mui/styles";
import { useNavigate, useParams } from "react-router-dom";
import { format, parseISO, parse, isValid } from "date-fns";
import {
  alphanumericWithSpaceValidation,
  validateEmail,
  validateField,
  validateMobile,
  zipCodeValidation,
  // apartmentNumberValidation,
} from "../../common/helpers/validation";
import {
  getGeneralPartyInfoById as getGeneralPartyInfoByIdAction,
  createGeneralPartyInfo as createGeneralPartyInfoAction,
  updateGeneralPartyInfo,
} from "../../redux/actions/generalPartyInfoActions";
import { CircleOutlined } from "@mui/icons-material";
import {
  CityList,
  genderList,
  residenceType,
  residenceship,
  stateList,
  MonthList,
  YearList,
} from "../../common/StaticFieldList";
import { tabActions } from "../../redux/actions/tabActions";
// import PrevNextButton from "../../common/PrevNextButton";
import { populateValue } from "../../common/helpers/utils";

const requiredFields = [
  "firstName",
  "lastName",
  "email",
  "address",
  "cellPhone",
  "state",
  "gender",
  "ownedRented",
  "city",
  "zip",
  "dob",
  "residenceMonth",
  "residenceYear",
];
const validationRules = [
  { fieldName: "firstName", label: "First Name", type: "text", id: "firstnamefield" },
  { fieldName: "lastName", label: "Last Name", type: "text", id: "lastnamefield" },
  { fieldName: "email", label: "Email Address", type: "email", id: "emailfield" },
  { fieldName: "dob", label: "Date Of Birth", type: "date", id: "dobfield" ,  required: true,
    format: "MM-DD-YYYY",
    placeholder: "MM-DD-YYYY"},
  { fieldName: "gender", label: "Gender", type: "select", option: genderList, id: "genderfield" },
  { fieldName: "cellPhone", label: "Cell Phone", type: "number", id: "cellphonefield" },
  { fieldName: "address", label: "Address", type: "text", id: "addressfield" },
  { fieldName: "apartmentNumber", label: "Apartment Number", type: "number", id: "taxidfield" },
  //   { fieldName: "city", label: "City", type: "select", option: CityList }, // Unchanged commented line
  { fieldName: "city", label: "City", type: "text",  id: "cityfield" },
  { fieldName: "state", label: "State", type: "select", option: stateList, id: "statefield" },
  { fieldName: "zip", label: "Zipcode", type: "number", id: "zipfield" },

  {
    fieldName: "ownedRented",
    label: "Owned / Rented",
    type: "select",
    option: residenceship,
    id: "ownedrentedfield",
  },

  {
    fieldName: "residenceYear",
    label: "Years at Residence",
    type: "select",
    option: YearList,
    id: "residenceyearfield",
  },
  {
    fieldName: "residenceMonth",
    label: "Months at Residence",
    type: "select",
    // group: true, // Unchanged commented line
    option: MonthList,
    id: "residencemonthfield",
  },
];


const validationFunctions = {
  firstName: alphanumericWithSpaceValidation,
  lastName: alphanumericWithSpaceValidation,
  email: validateEmail,
  cellPhone: validateMobile,
  zip: zipCodeValidation,
  dob: (value) => {
    if (!value) return "Date of Birth is required";
    if (!dayjs(value, "MM-DD-YYYY").isValid()) return "Please enter a valid date";
    return "";
  }
  // taxId: apartmentNumberValidation,
};

const useStyles = makeStyles(() => ({
  formContainer: {
    // marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: "flex",
    alignItems: "center",
    // marginTop: '.5rem !important',
  },

  fullWidth: {
    width: "100%",
  },
}));

const GeneralTab = ({ handleNext, handleSubmitGeneralTab, id }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  let { quoteId } = useParams();
  const loading = useSelector((state) => state.loading.CREATE_USER);

  const newFieldArg = [...validationRules];

  const [formState, setFormState] = useState({
    formData: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: "",
      }),
      {}
    ),
    touchedFields: newFieldArg.reduce(
      (fields, { fieldName }) => ({
        ...fields,
        [fieldName]: false,
      }),
      {}
    ),
    fieldErrors: newFieldArg.reduce(
      (errors, { fieldName }) => ({
        ...errors,
        [fieldName]: "",
      }),
      {}
    ),
  });
  const dynamicTheme = useSelector((state) => state?.theme?.theme[0]);

  // if(id)
  // {
  //   dispatch(getGeneralPartyInfoByIdAction(id));
  // }

  useEffect(() => {
    // if (!Object.keys(user).length) {
    // dispatch(getUserAction());
    if (id) {
      dispatch(getGeneralPartyInfoByIdAction(id));
    }
    // }
  }, []);

  useEffect(() => {
    // if (!Object.keys(user).length) {
    // dispatch(getUserAction());
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getGeneralPartyInfoByIdAction(quoteId));
    }
    // }
  }, []);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);

  const generalPartyInfo = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfo
  );

  const { formData, touchedFields, fieldErrors } = formState;

  const handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
       [e.target.name]: e.target.value,
      },
      fieldErrors: {
        ...prevState.fieldErrors,
        [e.target.name]: "", // Clear error when user starts typing
      },
    }));
  };

  const handleDateChange = (date, fieldName) => {
    const formattedDate = date ? dayjs(date).format("MM-DD-YYYY") : "";

    setFormState((prevState) => ({
      ...prevState,
      formData: {
        ...prevState.formData,
        [fieldName]: formattedDate,
      },
      touchedFields: {
        ...prevState.touchedFields,
        [fieldName]: true
      },
      fieldErrors: {
        ...prevState.fieldErrors,
        [fieldName]: validationFunctions.dob(formattedDate)
      }
    }));

    // Call handleBlur to trigger validation
    handleBlur(fieldName);
  };



  const handleBlur = (field) => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      touchedFields: {
        ...prevFormState.touchedFields,
        [field]: true,
      },
    }));

    if (!requiredFields.includes(field)) {
      return;
    }

    const value = formData[field];
    const error = validateField(
      value,
      newFieldArg.find((rule) => rule.fieldName === field).label
    );

    // Additional validations
    const additionalError = validationFunctions[field]
      ? validationFunctions[field](value)
      : "";

    setFormState((prevFormState) => ({
      ...prevFormState,
      fieldErrors: {
        ...prevFormState.fieldErrors,
        [field]: error || additionalError,
      },
    }));
  };

  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  const handleSnackbarOpen = (message, severity) => {
    setSnackbar({
      open: true,
      message,
      severity,
    });
  };

  const handleFormSubmit = (event) => {
    event.preventDefault();
    const formattedFormData = {
      ...formData,
      dob: dayjs(formData.dob).format('MM-DD-YYYY')
    };
    handleSubmitGeneralTab(formattedFormData); // Call the handleSubmit function with form data
  };
  // console.log("formData", formData);
  const handleSubmit = async (event) => {
    event.preventDefault();

    const validationErrors = newFieldArg.reduce(
      (errors, { fieldName, label }) => {
        if (requiredFields.includes(fieldName)) {
          const value = formData[fieldName];
          const error = validateField(value, label);

          // Additional validations
          const additionalError = validationFunctions[fieldName]
            ? validationFunctions[fieldName](value)
            : "";

          setFormState((prevFormState) => ({
            ...prevFormState,
            fieldErrors: {
              ...prevFormState.fieldErrors,
              [fieldName]: error || additionalError,
            },
          }));

          return {
            ...errors,
            [fieldName]: error || additionalError,
          };
        }

        return errors;
      },
      {}
    );

    const hasErrors = Object.values(validationErrors).some((error) => !!error);

    if (!hasErrors) {
      try {
        if (quoteId || id) {
          const newFormData = {
            ...formData,
            id: parseInt(quoteId),
          };
          await dispatch(
            updateGeneralPartyInfo(newFormData, getCreateUserSuccess)
          );
        } else {
          const newFormData = {
            ...formData,
          };
          await dispatch(
            createGeneralPartyInfoAction(newFormData, getCreateUserSuccess)
          );
        }
      } catch (error) {
        handleSnackbarOpen(
          error.message || "User service failed. Please try again.",
          "error"
        );
      }
    } else {
      handleSnackbarOpen(
        "Validation errors detected. Please correct them.",
        "error"
      );
    }
    handleNext();
  };

  const getCreateUserSuccess = async (uid) => {
    handleSnackbarOpen("Created successfully.", "success");
    navigate(`/quote/${uid}`);
    await dispatch(tabActions(1));
  };

  useEffect(() => {
    setFormState((prevFormState) => ({
      ...prevFormState,
      formData: newFieldArg.reduce(
        (fields, { fieldName }) => ({
          ...fields,
          [fieldName]:
            (generalPartyInfo &&
              populateValue(
                generalPartyInfo[fieldName],
                fieldName,
                newFieldArg
              )) ||
            "",
          // organizationId: generalPartyInfo?.organization?.id
        }),
        {}
      ),
    }));
  }, [generalPartyInfo]);

  return (
    <>
      <Box
        sx={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          gap: "36px",
          maxWidth: "100%",
          boxShadow: "None",
          border: 0,
        }}
      >
        <section
          sx={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            padding: "0px 23px 0px 20px",
            boxSizing: "border-box",
            maxWidth: "100%",
            textAlign: "left",
            fontSize: "14px",
            color: dynamicTheme?.secondaryColor,
            fontFamily: '"Poppins", sans-serif',
          }}
        >
          <Box
              id="form"
            component="form"
            onSubmit={handleFormSubmit}
            sx={{
              width: "853px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              border: "1px solid #e3e3e3",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              padding: "30px 0px 24px",
              maxWidth: "100%",
              margin: "0 auto",
            }}
          >
            <Box
              sx={{
                alignSelf: "stretch",
                height: "798px",
                position: "relative",
                borderRadius: "4px",
                backgroundColor: "#fff",
                border: "1px solid #e3e3e3",
                boxSizing: "border-box",
                display: "none",
              }}
            />
            <Typography
           id="clientinformation"
              variant="h1" // Adjust the variant as needed, e.g., h1, h2, h3, etc.
              component="div"
              sx={{
                border: "none",
                backgroundColor: "transparent",
                alignSelf: "stretch",
                height: "51px",
                fontFamily: '"Poppins", sans-serif',
                fontWeight: "600",
                fontSize: "25px",
                color: dynamicTheme?.secondaryColor,
                paddingLeft: "50px",
                display: "flex",
                marginBottom: "1.5rem",
                alignItems: "center",
              }}
            >
              Client Information
            </Typography>
            <hr style={{ borderTop: "1px solid #ccc", width: "100%" }} />
            <Box
  sx={{
    alignSelf: "stretch",
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "flex-end",
    padding: "0px 44px 0px 52px",
    boxSizing: "border-box",
    maxWidth: "100%",
    marginTop: "1rem",
  }}
>
  <Box
    sx={{
      flex: "1",
      display: "flex",
      flexDirection: "row",
      flexWrap: "wrap",
      alignItems: "flex-start",
      justifyContent: "flex-start",
      gap: "16px",
      maxWidth: "100%",
    }}
  >
    <Box
      sx={{
        flex: "1",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        justifyContent: "flex-start",
        minWidth: "240px",
        maxWidth: "100%",
      }}
    >
      <Box
        sx={{
          alignSelf: "stretch",
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "5px 20px",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          padding: "0px 0px 8px",
        }}
      >
     {validationRules.map(
  ({ fieldName, label, type, option, groupLabel = "" }, index) => (
    <Box
      sx={{
        display: "flex",
        flexFlow: "column",
        margin: "0 0 15px 0",
      }}
      key={index}
    >
      {groupLabel && (
        <Grid item xs={12} style={{ marginBottom: 0 }}>
          <Typography
            variant="subtitle1"
            style={{
              position: "relative",
              fontWeight: "500",
              display: "inline-block",
              minWidth: "83px",
              zIndex: "1",
              fontFamily: '"Poppins", sans-serif',
              color: dynamicTheme?.secondaryColor,
            }}
          >
            {groupLabel}
          </Typography>
        </Grid>
      )}
      <Typography
        key={fieldName}
        variant="body1"
        sx={{
          position: "relative",
          lineHeight: "18px",
          fontWeight: "500",
          display: "inline-block",
          zIndex: "1",
          margin: "0 0 10px 0",
          fontFamily: '"Poppins", sans-serif',
          color: dynamicTheme?.secondaryColor,
        }}
      >
        {label}
      </Typography>

      {type === "date" && fieldName === "dob" ? (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <DatePicker
      format="MM-DD-YYYY"
      value={formData[fieldName] ? dayjs(formData[fieldName]) : null}
      maxDate={dayjs("2008-04-04")}
      minDate={dayjs("1940-04-04")}
      onChange={(date) => handleDateChange(date, fieldName)}
      onClose={() => handleBlur("dob")}
      slotProps={{
        textField: {
          size: "medium",
          required: true,
          error: touchedFields[fieldName] && !!fieldErrors[fieldName],
          helperText: touchedFields[fieldName] ? fieldErrors[fieldName] : "",
          onBlur: () => handleBlur("dob"),
          sx: {
            width: 370,
            border: "none",
            backgroundColor: "transparent",
            alignSelf: "stretch",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "500",
            fontSize: "16px",
            zIndex: "1",
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: touchedFields[fieldName] && !!fieldErrors[fieldName] ? "#d32f2f" : "#242929",
              },
            },
          },
          inputProps: {
            placeholder: "MM-DD-YYYY"
          }
        }
      }}
    />
  </LocalizationProvider>
) : (
        <TextField
          style={{
            border: "none",
            backgroundColor: "transparent",
            alignSelf: "stretch",
            fontFamily: '"Poppins", sans-serif',
            fontWeight: "500",
            fontSize: "16px",
            zIndex: "1",
          }}
          type={type}
          variant="outlined"
          select={type === "select"}
          required={requiredFields.includes(fieldName)} // Ensure required works for other fields as well
          InputProps={{
            style: { height: 52, fontSize: 16 },
            inputProps: {
              min: type === "number" ? "0" : "",
              max: type === "date" ? "2008-04-04" : "",
              maxLength: type === "email" ? 50 : 50,
            },
          }}

          id={fieldName}
          placeholder={label}
          name={fieldName}
          autoComplete={fieldName}
          value={formData[fieldName]}
          maxLength={15}
          onChange={(e) => {
            if (type === "number" && e.target.value.length > 10) {
              {
                return;
              }
              if (
                (fieldName === "firstName" ||
                  fieldName === "lastName" ||
                  fieldName === "address") &&
                !/^[A-Za-z0-9\s]*$/.test(e.target.value)
              )
              return;
            }
            handleInputChange(e);
          }}
          onBlur={() => handleBlur(fieldName)}
          error={
            touchedFields[fieldName] &&
            !!fieldErrors[fieldName]} // Trigger error if field is empty
          helperText={
            touchedFields[fieldName]
            ? fieldErrors[fieldName]
             : ""

          }
           // Show error message
          sx={{
            "& fieldset": {
              borderColor: "#C1C1C1",
              fontFamily: '"Poppins", sans-serif',
            },
            "& .MuiInputBase-root": {
              height: "52px",
              backgroundColor: "#fff",
              fontSize: "14px",
              fontFamily: '"Poppins", sans-serif',
            },
            "& .MuiInputBase-input": {
              color: dynamicTheme?.secondaryColor,
              fontFamily: '"Poppins", sans-serif',
            },
          }}
        >
          {type === "select" &&
            option.map((option) => (
              <MenuItem
              key={option.value}
              placeholder={label}
              value={option.value}
              >
                {option.label}
              </MenuItem>
            ))}
        </TextField>
      )}
    </Box>
  )
)}

  {/* <Typography
                      // key={fieldName} // Assign a unique key to each element
                      variant="body1"
                      sx={{
                        position: "relative",
                        lineHeight: "40px",
                        fontWeight: "500",
                        display: "inline-block",
                        minWidth: "83px",
                        zIndex: "1",
                      }}
                    >
                      Years/Month at Residence*
                    </Typography>
                    {validationRulesResidence.map(
                      ({ fieldName, label, type, option }, index) => (
                        <React.Fragment key={index}>
                          <TextField
                            style={{
                              border: "none",
                              backgroundColor: "transparent",
                              alignSelf: "stretch",
                              height: "52px",
                              fontFamily: '"Poppins", sans-serif'
                              fontWeight: "500",
                              fontSize: "14px",
                              // color: '#077BFD',
                              zIndex: "1",
                            }}
                            type={type}
                            variant="outlined"
                            select={type === "select"}
                            required={requiredFields.includes(fieldName)}
                            id={fieldName}
                            // label={label}
                            placeholder={label}
                            name={fieldName}
                            autoComplete={fieldName}
                            value={formData[fieldName]}
                            onChange={(e) => handleInputChange(e)}
                            // onBlur={() => handleBlur(fieldName)}
                            error={
                              touchedFields[fieldName] &&
                              !!fieldErrors[fieldName]
                            }
                            helperText={
                              touchedFields[fieldName]
                                ? fieldErrors[fieldName]
                                : ""
                            }
                            sx={{
                              "& fieldset": { borderColor: "#077BFD" },
                              "& .MuiInputBase-root": {
                                height: "52px",
                                backgroundColor: "#fff",
                                fontSize: "14px",
                              },
                              "& .MuiInputBase-input": { color: "#242929" },
                            }}
                          >
                            {type === "select" &&
                              option.map((option) => (
                                <MenuItem
                                  key={option.value}
                                  placeholder={label}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              ))}
                          </TextField>
                        </React.Fragment>
                      )
                    )} */}
      </Box>
    </Box>
  </Box>
</Box>


            {/* <NavigationButton /> */}
            <div
              style={{
                alignSelf: "stretch",
                height: "42px",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                marginRight: "45px",
              }}
            >
              <Button
              id="submitbutton"
                type="submit"
                style={{
                  alignSelf: "stretch",
                  width: "104px",
                  cursor: "pointer",
                  zIndex: "1",
                  fontFamily: '"Poppins", sans-serif',
                }}
                endIcon={<ArrowForwardIosIcon />}
                // disableElevation={true}
                variant="contained"
                className="btn-primary"
                sx={{
                  textTransform: "none",
                  color: "#fff",
                  fontFamily: '"Poppins", sans-serif',
                  fontSize: "16px",
                  background: "rgba(7, 123, 253, 0.6)",
                  borderRadius: "4px",
                  // "&:hover": { background: "rgba(14, 147, 132, 0.6)" },
                  "&:hover": { background: "rgba(7, 123, 253, 1)" },
                  width: 104,
                }}
                // onClick={handleNext}
              >
                Next
              </Button>
            </div>
            {/* <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              sx={{
                mt: 3,
                mb: 2,
                marginRight: "1rem",
                // backgroundColor: "#077BFD",
              }}
            >
              {loading ? (
                <CircleOutlined size="24" color="secondary" />
              ) : (
                "Save"
              )}
            </Button>
          </Box> */}
          </Box>
        </section>
      </Box>
      {/* </Box> */}
    </>
  );
};

export default GeneralTab;
