import * as driverInfoServices from '../../services/driverInfoServices';
import {
  CLOSE_CREATE_DRIVER_INFO_MODAL,
  OPEN_CREATE_DRIVER_INFO_MODAL,
    OPEN_UPDATE_DRIVER_INFO_MODAL,
    START_LOADING,
    STOP_LOADING
} from "./actionTypes";

export const getAllDriverInfo = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_DRIVER_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await driverInfoServices.getAllDriverInfo(quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER_INFOS' });
    dispatch({ type: 'ALL_DRIVER_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER_INFOS' });
    dispatch({ type: 'ALL_DRIVER_INFOS_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
  }
};

export const getDriverInfoById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_DRIVER_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await driverInfoServices.getDriverInfoById(quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER_INFOS' });
    dispatch({ type: 'ALL_DRIVER_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER_INFOS' });
    dispatch({ type: 'ALL_DRIVER_INFOS_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
  }
};

export const getDriverNameInfoById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_DRIVER_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await driverInfoServices.getDriverNameInfoById(quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER_INFOS' });
    dispatch({ type: 'ALL_DRIVER_NAME_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER_INFOS' });
    dispatch({ type: 'ALL_DRIVER_INFOS_NAME_FAILURE', payload: error.message || 'All DriverInfos name service failed. Please try again.' });
  }
};

export const createDriverInfo = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'CREATE_DRIVER_INFO' });
    const token = getState().auth.token|| sessionStorage.getItem("token");
    // console.log(formData,"raushan");
    const response = await driverInfoServices.createDriverInfo(formData, token);
    dispatch({ type: STOP_LOADING, service: 'CREATE_DRIVER_INFO' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'CREATE_DRIVER_INFO_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'CREATE_DRIVER_INFO' });
    dispatch({ type: 'CREATE_DRIVER_INFO_FAILURE', payload: error?.body?.message || 'Create DriverInfo service failed. Please try again.' });
  }
};

export const updateDriverInfo = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_DRIVER_INFO' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    const response = await driverInfoServices.updateDriverInfo(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_DRIVER_INFO' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'UPDATE_DRIVER_INFO_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'UPDATE_DRIVER_INFO' });
    dispatch({ type: 'UPDATE_DRIVER_INFO_FAILURE', payload: error?.body?.message || 'Update DriverInfo service failed. Please try again.' });
  }
};

export const deleteDriverInfoById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_DRIVER_INFOS' });
    const token = getState().auth.token ||sessionStorage.getItem("token");
    return await driverInfoServices.deleteDriverInfoById(quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER_INFOS' });
    // dispatch({ type: 'ALL_DRIVER_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER_INFOS' });
    dispatch({ type: 'ALL_DRIVER_INFOS_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
  }
};

export const openCreateDriverInfoModal = () => ({
  type: OPEN_CREATE_DRIVER_INFO_MODAL,
});

export const closeCreateDriverInfoModal = () => ({
  type: CLOSE_CREATE_DRIVER_INFO_MODAL,
});

export const openUpdateDriverInfoModal = (id) => async (dispatch) => {
  dispatch({ type:OPEN_UPDATE_DRIVER_INFO_MODAL, payload: id });
};

//Policyside
export const saveDriverAction = (formData, callbackfn) => async (dispatch, getState) => {
  // console.log(formData, 'formData save driver api')
  try {
    dispatch({ type: START_LOADING, service: 'SAVE_DRIVER_INFO' });
    const token = getState().auth.token|| sessionStorage.getItem("token");
    const response = await driverInfoServices.saveDriver(formData, token);
    dispatch({ type: STOP_LOADING, service: 'SAVE_DRIVER_INFO' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'SAVE_DRIVER_INFO_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'SAVE_DRIVER_INFO' });
    dispatch({ type: 'SAVE_DRIVER_INFO_FAILURE', payload: error?.body?.message || 'Save Driver service failed. Please try again.' });
  }
};

export const getDriverAction= (page = 0, size = 5) => async (dispatch, getState) => {
  // console.log("in policydriver action");
  try {
    dispatch({ type: START_LOADING, service: 'ALL_DRIVER' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await driverInfoServices.getDriver(token, page, size);
    // console.log("response value of drivers",response);
    dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER' });
    dispatch({ type: 'GET_DRIVER_SUCCESS', payload: response });
  } catch (error) {

      dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER' });
      dispatch({ type: 'GET_DRIVER_FAILURE', payload: error || 'All driver failed. Please try again.' });

  }
};


// export const getDriverAction= (page = 0, size = 5) => async (dispatch, getState) => {
//   console.log("in note action");
//   try {
//     dispatch({ type: START_LOADING, service: 'ALL_DRIVER' });
//     const token = getState().auth.token || sessionStorage.getItem("token");
//     const response = await getAllNote(token, page, size);
//     console.log("response value of notes",response);
//     dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER' });
//     dispatch({ type: GET_DRIVER_SUCCESS, payload: response });
//   } catch (error) {
//     if(error?.includes("403")) {
//       dispatch(openSessionTimeoutModal());
//     } else {
//       dispatch({ type: STOP_LOADING, service: 'ALL_DRIVER' });
//       dispatch({ type: GET_DRIVER_FAILURE, payload: error || 'All driv service failed. Please try again.' });
//     }
//   }
// };

