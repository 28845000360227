
import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { Container } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import Header from './components/SidebarLayout/Header';
import SignIn from './pages/Login';
import theme from './common/theme';
import SignUp from './pages/Signup';
import { useSelector, useDispatch } from 'react-redux';
import SessionTimeoutModal from './common/SessionTimeOutModal';
import { closeSessionTimeoutModal } from './redux/actions/sessionTimeOutActions';
import { signOut } from './redux/actions/authActions';
import { accessRules } from './common/helpers/utils';
import { getThemeAction } from './redux/actions/themeActions';
import { getUser } from './redux/actions/userActions';
import ResetPasswordForm from './pages/ResetPassword';
import ForgotPasswordForm from './pages/ForgotPassword';
import LeadListing from './pages/Layout/SidebarLayout/AllLeadListing/LeadListing';
import AllAccount from './pages/Layout/SidebarLayout/Account/All_Account';
import CreateLeadV2 from './pages/Layout/SidebarLayout/CreateLead/CreateLead';
import SideBar from './components/SidebarLayout/SideBar';
import EditLead from './pages/Layout/SidebarLayout/EditLead/EditLead';
import AddPolicy from './pages/Layout/SidebarLayout/Account/AddPolicy';

    const useStyles = makeStyles({
    mainContainer: {
        minHeight: '90vh',
        padding: '1em',
    }
    });
    const Crm = () => {
        
    const classes = useStyles();
    const token = useSelector(state => state.auth?.token || sessionStorage.getItem('token'));
    const user = useSelector((state) => state?.user?.user);
    const isOpen = useSelector(state => state.sessionTimeoutModal?.isOpen);
    const dispatch = useDispatch();
    const permissions = user?.roleInfo?.permissions || [];
  

    const dynamicTheme = useSelector((state) => state?.theme?.theme[0]);

        useEffect(() => {
            if(user?.customer?.id) {
                dispatch(getThemeAction(user?.customer?.id))
            }

        }, [user])

        const customTheme = createTheme({
        ...theme,
        palette: {
            primary: {
                main: dynamicTheme?.primaryColor || '#077BFD',
            },
            secondary: {
                main: dynamicTheme?.secondaryColor || '#000',
            },
            iconColors: {
                customIconColor1: dynamicTheme?.primaryColor || '#077BFD',
            }
            },
        });

        useEffect(() => {
            if(!user && token) {
                dispatch(getUser());
            } else if(token === null){
            console.log(token, 'token')
            dispatch(signOut());
            }
        }, [token]);

    const handleCloseSessionTimeoutModal = async () => {
        await dispatch(closeSessionTimeoutModal());
        await dispatch(signOut());
    }
    return(
        <>
            <ThemeProvider theme={customTheme}>
                {token && <Header />}
                <Container maxWidth="x1" className={classes.mainContainer}>
                    {token && <SideBar />}
                    <Routes>
                    {!token && <Route path="/*" element={<Navigate to="/login" />} />}
                    {token && <Route path="/" element={<Navigate to="/allLeads" />} />}
                    {token && <Route path="/createLead" element={<CreateLeadV2 />} />}
                    {token && <Route path="/editleads/:leadId" element={<EditLead />} />}
                    {token && <Route path="/allLeads" element={<LeadListing />} />}
                    {token && <Route path="/addpolicy" element={<AddPolicy />} />}
                    {token && <Route path="/allaccount" element={<AllAccount />} />}
                    {token && <Route path="/login" element={<Navigate to="/allLeads" />} />}
                    {token && <Route path="/signup" element={<Navigate to="/home" />} />}
                    {!token && <Route path="/login" element={<SignIn />} />}
                    {!token && <Route path="/reset_password" element={<ResetPasswordForm />} />}
                    {!token && <Route path="/forgotPassword" element={<ForgotPasswordForm />} />}
                    {!token && <Route path="/signup" element={<SignUp />} />}
                    </Routes>
                    {token && <SessionTimeoutModal open={isOpen} onClose={handleCloseSessionTimeoutModal}/>}
                </Container>
            </ThemeProvider>
        </>
    )
}

export default Crm;