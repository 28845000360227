import apiService from "./apiServices";

export const createRole = async (formData, token) => {
  try {
    
    const response = await apiService('/roles/save-roles', 'POST', formData, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'Creating Role is failed. Please try again.');
  }
}

export const updateRole = async (formData, token) => {
  try {
    
    const response = await apiService('/roles/update-roles', 'PUT', formData, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'Updating Role is failed. Please try again.');
  }
}

export const getRoles = async (customerId, token) => {
  try {
    
    const response = await apiService(`/roles/all-roles/customer/${customerId.toString()}`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'service is failed. Please try again.');
  }
}

export const getAllRoles = async (token) => {
  try {
    
    const response = await apiService(`/newroles/user/reachable-roles`, 'GET', null, null, token);
    return response;
  } catch (error) {
    throw (error?.message || 'service is failed. Please try again.');
  }
}



