import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  TextField,
  SnackbarContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Alert,
  Grid,
  Typography,
  MenuItem,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { CircularProgress } from "@mui/material";
import { styled } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";

import { useNavigate, useLocation, useParams } from "react-router-dom";
import ImageLoader from "./imageLoader";
import { getUser as getUserAction } from "../../redux/actions/userActions";
import {
  CommonYesNo,
  liabbi,
  liabpd,
  Rental,
  Rentals,
  Roadside,
  TermTenture,
  umbi,
  medPay,
  coll,
  comp,
  payPlanOptionIns,
  payPlanOptions,
  pipDed,
  pipDedOption,
  defaultRates
} from "../../common/StaticFieldList";
import { CircleOutlined } from "@mui/icons-material";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

import { GridRowModes, DataGrid, GridCheckCircleIcon } from "@mui/x-data-grid";
import { getRatesAction } from "../../redux/actions/carrierSelectionsInfoActions";
import {
  findValueByKey,
  formattedCurrency,
  getValueByKey,
  populateValue,
} from "../../common/helpers/utils";
import { tabActions } from "../../redux/actions/tabActions";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useTheme } from "@emotion/react";
import {
  getRates as getJSONAction,
  getAllRatesAction,
  getAllVehicleDetailsAction,
} from "../../redux/actions/userActions";
import { getRatesUnited } from "../../redux/actions/userActions";
import {
  getcompanyselectionAction,
  getcompanyselectOptionAction,
  getcompanyDetialsAction,
  // resetCompanySelection
} from "../../redux/actions/companySelectionAction";
import {
  getDriverNameInfoById,
  getAllDriverInfo,
} from "../../redux/actions/driverInfoActions";
import { getAllVehicleInfo } from "../../redux/actions/vehicleInfoActions";
import { comparisionResponseById } from "../../redux/actions/generalPartyInfoActions";

const useStyles = makeStyles((theme) => ({
  formContainer: {
    // marginBottom: theme.spacing(2),
  },
  select: {
    minWidth: 150,
  },
  centerContent: {
    display: "flex",
    alignItems: "center",
    // marginTop: '.5rem !important',
  },
  fullWidth: {
    width: "100%",
  },
  root: {
    "& .MuiDataGrid-columnHeaders": {
      borderBottom: "none",
    },
    "& .MuiDataGrid-window": {
      borderTop: "none",
    },
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    fontFamily: '"Poppins", sans-serif',
    id: "table",
  },
  thead: {
    backgroundColor: "#f5f5f5",
    textAlign: "left",
    fontWeight: 600,
    fontSize: "16px",
    id: "thead",
  },
  th: {
    padding: "12px 16px",
    textAlign: "left",
    fontWeight: "500",
    borderBottom: "2px solid #ddd",
    id: "th",
  },
  tbody: {
    fontSize: "14px",
    color: "#333",
    id: "tbody",
  },
  td: {
    padding: "12px 16px",
    borderBottom: "1px solid #ddd",
    fontWeight: "500",
    id: "td",
  },
  actionCell: {
    display: "flex",
    gap: "12px",
    alignItems: "center",
    id: "actionCell",
  },
}));

const CompareQuotesTab = ({ setShowHideDriverForm, id }) => {
  const theme = useTheme();
  const classes = useStyles();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  let { quoteId } = useParams();
  const driverDetails = useSelector((state) => state.driverInfo.allDriverInfo);
  const [isReRated, setIsReRated] = useState(false);
  const [isReRating, setIsReRating] = useState(false);
  const [open, setOpen] = useState(false);
  const allVehicles = useSelector((state) => state.user.allVehicles) || [];
  const normalizedVehciles = Array.isArray(allVehicles)
    ? allVehicles
    : [allVehicles];
  // const vehiclevalues=normalizedVehciles[0] || [];

  const [selectedCompany, setSelectedCompany] = useState(null);

  const handleOpenDialog = (companyName) => {
    const selected = allVehicles.find(
      (data) => data.vehicleFeesResponse.companyName === companyName
    );
    setSelectedCompany(selected || null);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedCompany(null);
  };

  const clientName = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfo
  );
  const jsonvalue = useSelector(
    (state) => state.generalPartyInfo.allComparisionInfo
  );

  const allrates = useSelector((state) => state.user.allRates) || [];

  const normalizedRates = Array.isArray(allrates) ? allrates : [allrates];
  console.log("all raates", allrates);
  const ratesToDisplay = normalizedRates && normalizedRates.length > 0 ? normalizedRates : defaultRates;

  const getJson = jsonvalue.jsonResponse;
  // const parsedData = JSON.parse(JSON.stringify(jsonfixvalue));
  const [selectedDriver, setSelectedDriver] = useState("");

  const [rowModesModel, setRowModesModel] = React.useState({});

  const [load, setLoad] = useState(false);
  const [msgStatus, setMsgStatus] = useState("");
  const [mergeXMLjson, setMergeXMLjson] = useState([
    // JSON.stringify(StarHealth),
    // JSON.stringify(TATAHealth),
  ]);
  const [mergeXMLjson1, setMergeXMLjson1] = useState([
    // JSON.stringify(StarHealth),
    // JSON.stringify(TATAHealth),
  ]);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success",
  });
  const loading = useSelector((state) => state.loading.CREATE_USER);
  const isModalOpen = useSelector(
    (state) => state?.user?.isCreateUserModalOpen
  );
  const [checkedItemss, setCheckedItemss] = useState([]);
  const [loadings, setLoading] = useState(false);
  // const companies = useSelector(state => state.companies.companies);
  const companies = useSelector((state) => state.companies?.companies || []);
  const options = useSelector((state) => state.companies?.optionList || []);

  const companyList = useSelector(
    (state) => state.companies?.companiesList || []
  );

  const gpId = useSelector((state) => state.companies?.gpId);
  const term = useSelector((state) => state.companies?.term);
  const planOptions = useSelector((state) => state.companies?.planOptions);
  console.log("companyList", companyList);
  console.log("companyList details", gpId, term);

  useEffect(() => {
    dispatch(getcompanyselectionAction());
  }, [dispatch]);

  useEffect(() => {
    if (quoteId) {
      dispatch(getcompanyselectOptionAction(quoteId));
    }
  }, [dispatch]);

  useEffect(() => {
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getcompanyDetialsAction(quoteId));
    } else {
      // console.log("id in driver useeffect",id);
      dispatch(getcompanyDetialsAction(id));
    }
    // }
  }, [quoteId, id]);

  useEffect(() => {
    // Ensure all required variables have valid values before dispatching
    const isValidDispatchCondition =
      companies.length > 0 && gpId && planOptions && term;

    if (isValidDispatchCondition) {
      companies.forEach((company) => {
        const companyName = company.companyName.toLowerCase();

        // Only dispatch if companyName is also valid
        if (companyName) {
          dispatch(
            getAllVehicleDetailsAction(gpId, planOptions, term, companyName)
          );
          dispatch(getAllRatesAction(gpId, planOptions, term, companyName));
        }
      });
    }
  }, [gpId]);

  useEffect(() => {
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getAllDriverInfo(quoteId));
      dispatch(comparisionResponseById(quoteId));
    } else {
      // console.log("id in driver useeffect",id);
      dispatch(getAllDriverInfo(id));
      dispatch(comparisionResponseById(id));
    }
    // }
  }, [quoteId, id]);
  let initialRows = [];
  if (Array.isArray(companies)) {
    initialRows = companies.map((carrier) => ({
      company: carrier.companyName,
      id: carrier.id,
      code: carrier.companyCode,
    }));
  }
  // console.log("initialrows", initialRows);
  const [rows, setRows] = React.useState(initialRows);
  const handleCheckChanges = (event) => {
    const value = event.target.value;
    setCheckedItemss((prev) => {
      const newChecked = prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value];

      // Refresh the page if a checkbox is unchecked
      // if (!newChecked.includes(value)) {
      //   window.location.reload();
      // }

      return newChecked;
    });
  };

  const [opens, setOpens] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);

  React.useEffect(() => {
    if (!Object.keys(user).length) {
      dispatch(getUserAction());
    }
  }, []);
  // const getJson = useSelector((state) => state.user.xmlJson);
  // console.log("getJson", getJson);

  // const getJsons = useSelector((state) => state.user.xmlJson);

  React.useEffect(() => {
    let MsgStatus = "";

    if (getJson) {
      try {
        // Assuming getJson is a JSON object; if it's a string, you should parse it first
        const parsedJson =
          typeof getJson === "string" ? JSON.parse(getJson) : getJson;

        // Retrieve MsgStatus from parsed JSON
        MsgStatus = findValueByKey(parsedJson, "MsgStatus");

        setMsgStatus(MsgStatus);

        // Check the status and update the mergeXMLjson state
        if (
          MsgStatus &&
          !["Error", "REJECTED"].includes(MsgStatus.MsgStatusCd)
        ) {
          // setMergeXMLjson(prev => [...prev, JSON.stringify(getJson)]);
          setMergeXMLjson([...mergeXMLjson, JSON.stringify(parsedJson)]);
        }
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
  }, [jsonvalue.jsonResponse]);

  const allVehicleInfo = useSelector(
    (state) => state.vehicleInfo.allVehicleInfo
  );

  useEffect(() => {
    // if (!Object.keys(user).length) {
    // dispatch(getUserAction());
    if (quoteId) {
      // console.log("quoteId", quoteId)
      dispatch(getAllVehicleInfo(quoteId));
    } else {
      dispatch(getAllVehicleInfo(id));
    }
    // }
  }, []);
  const user = useSelector((state) => state.user);
  const selectedUserId = useSelector((state) => state.user.selectedUserId);
  const allUser = useSelector((state) => state?.user?.allUser);
  const customerDataForUpdate =
    allUser &&
    allUser.length &&
    allUser.filter((user) => user.id === selectedUserId);
  const customerForUpdate = customerDataForUpdate && customerDataForUpdate[0];

  const getSelectedRates = useSelector(
    (state) => state.carrierSelectionsInfo.selectedRateList
  );
  const [checkedItems, setCheckedItems] = useState(getSelectedRates || []);
  // const fullName = `${clientName.firstName} ${clientName.lastName}`;

  const simplifiedDriverDetails = driverDetails.map((driver) => ({
    fullName: `${driver.firstName} ${driver.lastName}`,
  }));

  const firstDriverFullName =
    simplifiedDriverDetails.length > 0
      ? simplifiedDriverDetails[0].fullName
      : "";
  // console.log("firstDriverFullname",firstDriverFullName);
  const [formState, setFormState] = useState({
    payPlanOptions: "INS",
    term: "6",
    vehicle: [],
    // gpId: parseInt(id),
    gpId: id ? parseInt(id) : parseInt(quoteId),
    changeNameInsuredTo: "",
    liabbi: "",
    liabpd: "",
    pipDed: "",
    pipDedOption: "",
    umbi: "",
    umStacked: "",
    medPay: "",
  });

  useEffect(() => {
    if (clientName.state === "TX") {
      const fullName = `${clientName.firstName} ${clientName.lastName}`;
      setSelectedDriver(fullName);

      // Use `options` data if available; fallback to defaults otherwise
      setFormState((prevState) => ({
        ...prevState,
        changeNameInsuredTo: options.changeNameInsuredTo || firstDriverFullName, // Prefer `options` data
        liabbi: options.liabbi || liabbi[4]?.value || "", // Prefer `options` data
        liabpd: options.liabpd || liabpd[1]?.value || "",
        pipDed: options.pipDed || pipDed[3]?.label || "",
        pipDedOption: options.pipDedOption || pipDedOption[0]?.label || "",
        umbi: options.umbi || umbi[3]?.value || "",
        umStacked: options.umStacked || CommonYesNo[1]?.value || "",
        medPay: options.medPay || umbi[1]?.value || "",
      }));
    } else {
      const fullName = `${clientName.firstName} ${clientName.lastName}`;
      setSelectedDriver(fullName);

      // Use `options` data if available; fallback to defaults otherwise
      setFormState((prevState) => ({
        ...prevState,
        changeNameInsuredTo: options.changeNameInsuredTo || firstDriverFullName, // Prefer `options` data
        liabbi: options.liabbi || liabbi[2]?.value || "", // Prefer `options` data
        liabpd: options.liabpd || liabpd[0]?.value || "",
        pipDed: options.pipDed || pipDed[3]?.label || "",
        pipDedOption: options.pipDedOption || pipDedOption[0]?.label || "",
        umbi: options.umbi || umbi[1]?.value || "",
        umStacked: options.umStacked || CommonYesNo[1]?.value || "",
        medPay: options.medPay || umbi[1]?.value || "",
      }));
    }
  }, [clientName, firstDriverFullName, options]);

  const [vehicleState, setVehicleState] = useState([]);

  React.useEffect(() => {
    let newVehicleObj = [];
    allVehicleInfo.map((row) => {
      let obj = {
        id: row.id,
        //cid
        comp: row.comp || comp[5]?.value,
        coll: row.coll || coll[11]?.value,
        roadside: Roadside[1]?.value || "",
        rental: Rentals[1]?.value || "",
        safety: CommonYesNo[1]?.value || "",
        manufacturer: row.manufacturer,
        model: row.model,
      };

      newVehicleObj.push(obj);
      // newVehicleObj[row.cid] = obj;
    });

    setVehicleState(newVehicleObj);
    setFormState((prevState) => ({
      ...prevState,
      vehicle: newVehicleObj,
    }));
  }, [allVehicleInfo]);
  const handleSnackbarClose = () => {
    setSnackbar((prevSnackbar) => ({
      ...prevSnackbar,
      open: false,
    }));
  };

  // const handleInputChange = (e) => {
  //   e.preventDefault();

  //   const { name, value } = e.target;

  //   setFormState((prevState) => ({
  //     ...prevState,
  //     [name]: value,
  //   }));
  //   setSelectedDriver(value);
  // };

  const handleInputChange = (e) => {
    e.preventDefault();

    const { name, value } = e.target;

    // Update form state and ensure the updated state is used
    setFormState((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      };

      // Trigger side effects after updating the state
      if (
        (name === "payPlanOptions" || name === "term") &&
        updatedState.gpId &&
        checkedItemss.length > 0
      ) {
        // Reset existing rates
        dispatch({ type: "RESET_ALL_RATES" });

        const fetchRatesForSelectedCompanies = async () => {
          try {
            setLoad(true);

            const validCheckedItems = checkedItemss.filter(
              (item) => item != null
            );

            const promises = validCheckedItems
              .map((item) => {
                const company = initialRows.find(
                  (row) => row.id === parseInt(item)
                );
                if (company) {
                  const payload = {
                    ...updatedState,
                    companyCode: company.code,
                  };

                  // Dispatch action to fetch rates
                  return dispatch(
                    getAllRatesAction(
                      payload.gpId,
                      name === "payPlanOptions"
                        ? value
                        : updatedState.payPlanOptions, // Pay Plan Options
                      name === "term" ? value : updatedState.term, // Term
                      company.company.toLowerCase()
                    )
                  )
                    .then((result) => ({
                      company: company.company,
                      result,
                      success: true,
                    }))
                    .catch((error) => ({
                      company: company.company,
                      error,
                      success: false,
                    }));
                }
                return null;
              })
              .filter((promise) => promise != null);

            const results = await Promise.all(promises);

            results.forEach(({ company, result, error, success }) => {
              if (!success) {
                console.error(`Error fetching rates for ${company}:`, error);
              } else {
                console.log(`Rates for ${company}:`, result);
              }
            });

            setLoad(false);
          } catch (error) {
            console.error("Error getting rates:", error);
            setLoad(false);
          }
        };

        fetchRatesForSelectedCompanies();
      } else if (name === "payPlanOptions" || name === "term") {
        console.warn(
          "Cannot fetch rates: Missing GP ID or no companies selected"
        );
      }

      return updatedState;
    });

    // Set selected driver if applicable
    setSelectedDriver(value);
  };

  const handleInputChangeVehicle = (e, index) => {
    e.preventDefault();
    const { name, value } = e.target;

    // Create a new array instead of mutating the existing one
    const vehicleStateNew = [...vehicleState];
    vehicleStateNew[index] = {
      ...vehicleStateNew[index],
      // [name]: value.toString(),
      [name]: value,
    };

    // Update the state with the new array
    setVehicleState(vehicleStateNew);
    setFormState((prevState) => ({
      ...prevState,
      vehicle: vehicleStateNew,
    }));
  };

  const getRates = async (payload, company) => {
    // console.log("Payload:", payload);

    if (company === "Responsive") {
      return await dispatch(getJSONAction(payload, quoteId)); // Dispatch action for Pearl
    } else if (company === "United") {
      return await dispatch(getRatesUnited(payload, quoteId)); // Dispatch action for United
    }
  };

  const handleGetRates = async () => {
    try {
      setLoad(true);
      dispatch({ type: "RESET_ALL_RATES" });

      const validCheckedItems = checkedItemss.filter((item) => item != null);

      const promises = validCheckedItems
        .map((item) => {
          const company = initialRows.find((row) => row.id === parseInt(item));
          if (company) {
            const payload = {
              ...formState,
              companyCode: company.code, // Add the company code to the payload
            };

            console.log("payload", payload);

            // Step 1: Post data using `getRates`
            return getRates(payload, company.company)
              .then(() => {
                // Step 2: After posting, fetch rates using `getAllRatesAction`
                const ratePromise = dispatch(
                  getAllRatesAction(
                    payload.gpId,
                    payload.payPlanOptions,
                    formState.term,
                    company.company.toLowerCase()
                  )
                );

                // Step 3: Fetch vehicle details using `getAllVehicleDetailsAction`
                const vehicleDetailsPromise = dispatch(
                  getAllVehicleDetailsAction(
                    payload.gpId,
                    payload.payPlanOptions,
                    formState.term,
                    company.company.toLowerCase()
                  )
                );

                // Wait for both rate and vehicle detail promises
                return Promise.all([ratePromise, vehicleDetailsPromise])
                  .then(([rateResult, vehicleResult]) => ({
                    company: company.company,
                    rateResult,
                    vehicleResult,
                    success: true,
                  }))
                  .catch((error) => ({
                    company: company.company,
                    error,
                    success: false,
                  }));
              })
              .catch((error) => ({
                company: company.company,
                error,
                success: false,
              }));
          }
          return null;
        })
        .filter((promise) => promise != null); // Filter out any null promises

      const results = await Promise.all(promises);

      const successfulResults = results.filter(({ success }) => success);

      results.forEach(
        ({ company, rateResult, vehicleResult, error, success }) => {
          if (!success) {
            console.error(`Error fetching data for ${company}:`, error);
          } else {
            console.log(`Rates for ${company}:`, rateResult);
            console.log(`Vehicle details for ${company}:`, vehicleResult);
          }
        }
      );

      setLoad(false);

      if (successfulResults.length === validCheckedItems.length) {
        setIsReRated(true);
      } else {
        console.warn(
          "Not all data were successfully fetched. isReRated not set to true."
        );
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoad(false);
    }
  };

  return (
    <Box
      maxWidth="xl"
      sx={{
        backgroundColor: "#fff",
        margin: "0 auto",
        border: "#E7E7E7 solid 1px",
        padding: "20px",
        borderRadius: "4px",
      }}
    >
      {/* {load && (
        <div style={{ textAlign: "center" }}>
          <ImageLoader
            text1="Communicating with Insurance companies"
            text2="Result will be shown momentarily "
          />
        </div>
      )} */}
      <Paper
        elevation={0}
        style={{
          borderRadius: 4,
          backgroundColor: "#fff",
          borderBottom: "1px solid #e3e3e3",
          padding: "30px 0 20px",
        }}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          spacing={2}
        >
          <Grid item>
            <Typography
              id="comparisonsection"
              variant="h5"
              fontWeight="600"
              sx={{ fontFamily: '"Poppins", sans-serif' }}
            >
              Comparison Section
            </Typography>
          </Grid>
        </Grid>
      </Paper>
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Box sx={{ mt: 2, flexGrow: 1 }}>
          <Grid
            container
            spacing={1}
            sx={{ fontFamily: '"Poppins", sans-serif' }}
          >
            <Grid container item xs={12} sm={12} spacing={1}>
              <Grid item xs={6} sm={2} lg={3} sx={{marginBottom: '10px'}}>
                <Typography
                  id="changenameinsuredto"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif', }}
                >
                  Change Name Insured To
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif', }}
                    fullWidth
                    placeholder="Change Name Insured To"
                    // label={"Change Name Insured To"}
                    name={"changeNameInsuredTo"}
                    // value={formState["changeNameInsuredTo"] }
                    value={formState["changeNameInsuredTo"]}
                    // value={"Smith"}
                    // sx={{marginTop:"5px"}}
                    size="small"
                    onChange={(e) => handleInputChange(e)}

                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {simplifiedDriverDetails.map((driver, i) => (
                      <MenuItem
                        key={i}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={driver.fullName}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {driver.fullName}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2} lg={3} sx={{marginBottom: '10px'}}>
                <Typography
                  id="LiabBI"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Liab BI
                  <TextField
                    select={"select"}
                    fullWidth
                    placeholder="Liab BI"
                    // label={"Liab BI"}
                    name={"liabbi"}
                    value={formState["liabbi"]}
                    // || Liab[2].value
                    // value={}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                    sx={{ fontFamily: '"Poppins", sans-serif', minHeight: '55px' }}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {liabbi.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="LiabPD"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Liab PD
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    fullWidth
                    placeholder="Liab PD"
                    // label={"Liab PD"}
                    name={"liabpd"}
                    // value={Liab1[0].value}
                    value={formState["liabpd"]}
                    size="small"
                    //  || Liab1[0].value
                    // value={formData[fieldName]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {liabpd.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="PIPDED"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  PIP DED
                  <TextField
                    select={"select"}
                    sx={{ fontFamily: '"Poppins", sans-serif' }}
                    fullWidth
                    placeholder="PIP DED"
                    // label={"PIP DED"}
                    name={"pipDed"}
                    // value={formData[fieldName]}
                    value={formState["pipDed"]}
                    size="small"
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {pipDed.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} spacing={1}>
              <Grid item xs={6} sm={2} lg={3} sx={{marginBottom: '10px'}}>
                <Typography
                  id="PIPDEDoption"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  PIP DED Option
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="PIP DED Option"
                    // label={"PIP DED Option"}
                    name={"pipDedOption"}
                    size="small"
                    value={formState["pipDedOption"]}
                    // value={formData[fieldName]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {pipDedOption.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={6} sm={2} lg={3} sx={{marginBottom: '10px'}}>
                <Typography
                  id="UMBI"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  UMBI
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="UMBI"
                    // label={"UMBI"}
                    name={"umbi"}
                    size="small"
                    // disabled={formState.Liabbi ? false : true}
                    // value={formData[fieldName]}
                    value={formState["umbi"]}
                    onChange={(e) => handleInputChange(e)} // onBlur={() => handleBlur(fieldName)}
                  >
                    {umbi.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="UMstacked"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  UM Stacked
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="UM Stacked"
                    // label={"UM Stacked"}
                    name={"umStacked"}
                    size="small"
                    // value={formData[fieldName]}
                    value={formState["umStacked"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {CommonYesNo.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {" "}
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="medpay"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Med Pay
                  <TextField
                    // select={"select"}
                    select
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Med Pay"
                    // label={"Med Pay"}
                    name={"medPay"}
                    size="small"
                    // value={formState["medPay"]}
                    // name="medPay"
                    value={formState.medPay}
                    // value={formData[fieldName]}
                    // onChange={(e) => handleInputChange(e)}
                    onChange={handleInputChange}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {medPay.map((option) => (
                      <MenuItem
                        key={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                        value={option.value}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            <hr
              style={{
                borderTop: "1px solid #ccc",
                width: "100%",
                marginTop: "30px",
              }}
            />
            {/* Toyota */}
            <Grid item xs={12} sm={12}>
              {vehicleState.length > 0 &&
                vehicleState.map((vehicle, index) => (
                  <Grid container item xs={12} sm={12} spacing={1}>
                    <Grid item xs={12} sm={12}>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                          fontWeight: "bold",
                        }}
                      >
                        {`#${index + 1} ${vehicle.manufacturer} ${
                          vehicle.model
                        }`}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="comp"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Comp
                        <TextField
                          // select={"select"}
                          size="small"
                          select
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          fullWidth
                          placeholder="Comp"
                          name={"comp"}
                          value={vehicle.comp}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {comp.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="coll"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Coll
                        <TextField
                          // select={"select"}
                          select
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          size="small"
                          // label={"Coll"}
                          fullWidth
                          placeholder="Coll"
                          name={"coll"}
                          value={vehicle.coll}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {coll.map((option) => (
                            <MenuItem
                              key={option.value}
                              value={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="roadside"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Roadside
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          size="small"
                          // label={"Roadside"}
                          fullWidth
                          placeholder="Roadside"
                          name={"roadside"}
                          value={vehicle.roadside}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {Roadside.map((option) => (
                            <MenuItem
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              key={option.value}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="rental"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Rental
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          size="small"
                          // label={"Rental"}
                          fullWidth
                          placeholder="Rental"
                          name={"rental"}
                          value={vehicle.rental}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {Rentals.map((option) => (
                            <MenuItem
                              key={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Typography
                        id="safety"
                        variant="body1"
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        Safety
                        <TextField
                          select={"select"}
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                          size="small"
                          // label={"Safety"}
                          fullWidth
                          placeholder="safety"
                          name={"safety"}
                          value={vehicle.safety}
                          onChange={(e) => handleInputChangeVehicle(e, index)}
                        >
                          {CommonYesNo.map((option) => (
                            <MenuItem
                              key={option.value}
                              sx={{ fontFamily: '"Poppins", sans-serif' }}
                              value={option.value}
                            >
                              <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                                {" "}
                                {option.label}
                              </Box>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
            </Grid>

            <Grid
              container
              item
              xs={12}
              sm={12}
              spacing={1}
              sx={{ marginTop: "10px" }}
            >
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  // backgroundColor: theme?.palette?.primary?.main,
                  color: "black",
                  padding: 5,
                  marginLeft: 5,
                  fontFamily: '"Poppins", sans-serif',
                }}
                sx={{ position: "sticky" }}
              >
                <Typography
                  id="companyselection"
                  variant="h5"
                  fontWeight="600"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Company Selection
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                md={12}
                style={{
                  padding: 5,
                  marginLeft: 5,
                  width: "100%",
                  overflowX: "hidden",
                  fontFamily: '"Poppins", sans-serif',
                }}
              >
                <table className={classes.table}>
                  <thead className={classes.thead}>
                    <tr>
                      <th className="styled-table-heading" style={{
                        padding:12,
                      }}>#</th>
                      <th style={{
                        padding:12,
                      }}className="styled-table-heading">Company</th>
                      <th  style={{
                        padding:12,
                      }}className="styled-table-heading">Rates</th>
                    </tr>
                  </thead>
                  <tbody className={classes.tbody}>
                    {initialRows.length === 0 ? (
                      <tr>
                        <td
                          id="nocompanyfound"
                          className={classes.td}
                          colSpan="3"
                          style={{
                            textAlign: "center",
                            padding: "20px",
                            fontSize: "16px",
                            fontWeight: "bold",
                            color: "#888",
                          }}
                        >
                          No Company found
                        </td>
                      </tr>
                    ) : (
                      initialRows.map((row, index) => (
                        <tr className="styled-table-row" key={row.id}>
                          <td className={classes.td}>{index + 1}</td>
                          <td className={classes.td}>{row.company}</td>
                          <td className={classes.td}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  checked={checkedItemss.includes(
                                    row.id.toString()
                                  )}
                                  onChange={handleCheckChanges}
                                  name={`rates-${row.id}`}
                                  value={row.id}
                                  color="primary"
                                />
                              }
                            />
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
                <style>{`
        .Mui-even {
          background-color: #f4f5f9;
        }

        .Mui-odd {
          background-color: transparent;
        }
      `}</style>
              </Grid>
              <Grid item xs={12} md={12} sx={{ position: "sticky" }}>
                <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    onClick={handleGetRates}
                    variant="contained"
                    sx={{ mt: 3, mb: 2, fontFamily: '"Poppins", sans-serif' }}
                    disabled={loading} // Optionally disable the button while loading
                    className="btn-secondary"
                  >
                    {loading ? (
                      <CircleOutlined size="24" color="secondary" />
                    ) : isReRated ? (
                      "Re-rate"
                    ) : (
                      "Get Rates"
                    )}
                  </Button>
                </Box>
              </Grid>
            </Grid>
            <Grid container item xs={12} sm={12} spacing={1}>
              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="term"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Term
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Term"
                    // label={"Term"}
                    name={"term"}
                    value={formState["term"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {TermTenture.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>

              <Grid item xs={12} sm={2} lg={3}>
                <Typography
                  id="payplanoptions"
                  variant="body1"
                  sx={{ fontFamily: '"Poppins", sans-serif' }}
                >
                  Pay Plan Options
                  <TextField
                    select={"select"}
                    sx={{
                      fontFamily: '"Poppins", sans-serif',
                      marginTop: "5px",
                    }}
                    fullWidth
                    placeholder="Pay Plan Options"
                    // label={"Pay Plan Options"}
                    name={"payPlanOptions"}
                    value={formState["payPlanOptions"]}
                    onChange={(e) => handleInputChange(e)}
                    // onBlur={() => handleBlur(fieldName)}
                  >
                    {payPlanOptionIns.map((option) => (
                      <MenuItem
                        key={option.value}
                        value={option.value}
                        sx={{ fontFamily: '"Poppins", sans-serif' }}
                      >
                        <Box sx={{ fontFamily: '"Poppins", sans-serif' }}>
                          {option.label}
                        </Box>
                      </MenuItem>
                    ))}
                  </TextField>
                </Typography>
              </Grid>
            </Grid>
            {/* table */}
            <Grid item xs={12} sm={12}>
              <Box
                sx={{
                  border: "#E7E7E7 solid 1px",
                  marginTop: "1rem",
                  borderRadius: "4px",
                }}
              >
                <Table
                  aria-label="collapsible table"
                  size="small"
                  style={{
                    borderCollapse: "separate",
                    borderSpacing: "0 0.5rem",
                    border: "none",
                  }}
                >
                  <TableHead>
                    <TableRow
                      sx={{
                        "& th": {
                          backgroundColor: "transparent",
                          color: "#242929",
                          fontSize: "1rem",
                          fontWeight: "bold",
                          fontFamily: '"Poppins", sans-serif',
                          border: "none",
                        },
                      }}
                    >
                       <TableCell
                        className="styled-table-heading"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      ></TableCell>
                      <TableCell
                      className="styled-table-heading"
                        id="quote"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Quote
                      </TableCell>
                      {/* <TableCell
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      ></TableCell> */}
                      <TableCell
                        id="companyname"
                        className="styled-table-heading"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                      >
                        Company Name
                      </TableCell>
                      <TableCell
                        id="totalpremium"
                        className="styled-table-heading"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Total Premium
                      </TableCell>
                      <TableCell
                      className="styled-table-heading"
                        id="downpayment"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Down Payment
                      </TableCell>
                      <TableCell
                      className="styled-table-heading"
                        id="schedules"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Schedules
                      </TableCell>
                      {/* <TableCell
                        id="paymentamount"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Payment Amount
                      </TableCell> */}
                      <TableCell
                        id="term"
                        className="styled-table-heading"
                        sx={{
                          fontFamily: '"Poppins", sans-serif',
                          color: "#242929",
                        }}
                        align="left"
                      >
                        Term
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {ratesToDisplay.map((rate, index) => (
                      <TableRow
                        sx={{
                          backgroundColor: "#F8F8F8",
                          "& td": {
                            borderBottom: "#E7E7E7 solid 1px",
                            fontSize: "14px",
                            fontFamily: '"Poppins", sans-serif',
                          },
                        }}
                        className="styled-table-row"
                      >
                        <TableCell>
            {load ? (
                <CircularProgress size={20} />
              ) :(
                <GridCheckCircleIcon
                sx={{
                  color: "green", // Change this to your desired tick color
                  fontSize: 20,   // Matches the size of the loader
                }}
              />
              )}
            </TableCell>
                        <TableCell align="left">
                          <a
                            href={rate.bridgingLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{
                              fontFamily: '"Poppins", sans-serif',
                              color: "#1976d2",
                              textDecoration: "none",
                            }}
                          >
                            Bridge
                          </a>
                          <Button
                            onClick={() =>
                              handleOpenDialog(rate.companyName || "")
                            }
                            id="details"
                            // sx={{
                            //   fontFamily: '"Poppins", sans-serif',
                            //   textTransform: "none",
                            // }}
                          >
                            Details
                          </Button>
                          <Dialog
                            open={open}
                            onClose={handleClose}
                            maxWidth="sm"
                            fullWidth
                          >
                            <DialogTitle>
                              <Typography fontWeight="600" fontSize="18px">
                                BreakDown Details
                              </Typography>
                              <IconButton
                                aria-label="close"
                                onClick={handleClose}
                                sx={{
                                  position: "absolute",
                                  right: 8,
                                  top: 8,
                                  color: (theme) => theme.palette.grey[500],
                                }}
                              >
                                <CloseIcon />
                              </IconButton>
                            </DialogTitle>
                            <DialogContent>
                              <Box sx={{ padding: "20px" }}>
                                {selectedCompany?.vehicleDetailsResponseList?.map(
                                  (vehicle, index) => (
                                    <Box
                                      key={vehicle.id}
                                      sx={{ marginBottom: "20px" }}
                                    >
                                      <Typography
                                        fontWeight="bold"
                                        fontSize="16px"
                                        gutterBottom
                                        component="div"
                                      >
                                        {/* ({vehicle.vehType}) */}
                                        Vehicle {index + 1}
                                      </Typography>
                                      <TableContainer>
                                        <Table size="small">
                                          <TableBody>
                                            <TableRow>
                                              <TableCell>BI</TableCell>
                                              <TableCell>
                                                ${vehicle.bi || "N/A"}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>COLL</TableCell>
                                              <TableCell>
                                                ${vehicle.coll || "N/A"}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>COMP</TableCell>
                                              <TableCell>
                                                ${vehicle.comp || "N/A"}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>PD</TableCell>
                                              <TableCell>
                                                ${vehicle.pd || "N/A"}
                                              </TableCell>
                                            </TableRow>
                                            <TableRow>
                                              <TableCell>UM</TableCell>
                                              <TableCell>
                                                ${vehicle.um || "N/A"}
                                              </TableCell>
                                            </TableRow>
                                          </TableBody>
                                        </Table>
                                      </TableContainer>
                                    </Box>
                                  )
                                )}

                                {/* Policy Fees */}
                                {selectedCompany?.vehicleFeesResponse && (
                                  <Box sx={{ marginTop: "20px" }}>
                                    <Typography
                                      fontWeight="bold"
                                      fontSize="16px"
                                      gutterBottom
                                      component="div"
                                    >
                                      Policy Fees
                                    </Typography>
                                    <TableContainer>
                                      <Table size="small">
                                        <TableBody>
                                          <TableRow>
                                            <TableCell>Policy Fee</TableCell>
                                            <TableCell>
                                              $
                                              {selectedCompany
                                                .vehicleFeesResponse
                                                .policyFee || "0.00"}
                                            </TableCell>
                                          </TableRow>
                                          <TableRow>
                                            <TableCell>
                                              Underwriting Fee
                                            </TableCell>
                                            <TableCell>
                                              $
                                              {selectedCompany
                                                .vehicleFeesResponse
                                                .underwritingFee || "0.00"}
                                            </TableCell>
                                            {/* <TableCell>
                                              Total Premium
                                            </TableCell>
                                            <TableCell>
                                              $
                                              { rate.totalPremium|| "0.00"}
                                            </TableCell> */}
                                          </TableRow>
                                        </TableBody>
                                      </Table>
                                    </TableContainer>
                                  </Box>
                                )}
                              </Box>
                            </DialogContent>
                          </Dialog>
                        </TableCell>
                        <TableCell>{rate.companyName}</TableCell>
                        <TableCell>
                          {formattedCurrency(rate.totalPremium)}
                        </TableCell>
                        <TableCell>
                          {formattedCurrency(rate.depositAmt)}
                        </TableCell>
                        <TableCell
                          align="left"
                          sx={{ fontFamily: '"Poppins", sans-serif' }}
                        >
                          {/* {paymentOption?.NumPayments || ''} */}
                          <Button
                            id="detailsbutton"
                            // onClick={handleClickOpenInvoice}
                            sx={{ fontFamily: '"Poppins", sans-serif' }}
                          >
                            Details
                          </Button>
                        </TableCell>
                        <TableCell>{rate.terms}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Snackbar
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity={snackbar.severity}
          sx={{ fontFamily: '"Poppins", sans-serif' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default CompareQuotesTab;
