// import React, { useEffect } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { useLocation, useNavigate } from 'react-router-dom';
// import { postEmailTokenAction } from '../../redux/actions/gailActions';

// function VerifyEmail() {
//     const navigate = useNavigate();
//   const location = useLocation();
//   const dispatch = useDispatch(); // Hook to access the dispatch function

//   const emailData = useSelector((state) => state.gail.emailResponse);
//   console.log("emailData",emailData)

//   // Function to extract query parameters from the URL
//   const getQueryParams = (param) => {
//     const params = new URLSearchParams(location.search);
//     return params.get(param);
//   };

//   const token = getQueryParams('token'); // Extract the token from the query parameters

//   console.log("token", token);



//   useEffect(() => {
//     const postToken = async () => {
//       try {
//         // Dispatch the postTokenAction with the token
//         const formData={"token":token};
//         await dispatch(postEmailTokenAction(formData));
//       } catch (error) {
//         console.error("Error posting token:", error);
//       }
//     };

//     // Only call the function if a token is available
//     if (token) {
//       postToken();
//     }
//   }, [token, dispatch]);
//   return (
//     <div>
//       verify email
//     </div>
//   )
// }

// export default VerifyEmail





import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { postEmailTokenAction } from '../../redux/actions/gailActions';

function VerifyEmail() {
  
  const location = useLocation();
  const dispatch = useDispatch();

  const emailData = useSelector((state) => state.gail.emailResponse);
  console.log("emailData", emailData);

  const getQueryParams = (param) => {
    const params = new URLSearchParams(location.search);
    return params.get(param);
  };

  const token = getQueryParams('token');

  useEffect(() => {
    const postToken = async () => {
      try {
        const formData = { token: token };
        await dispatch(postEmailTokenAction(formData));
      } catch (error) {
        console.error("Error posting token:", error);
      }
    };

    if (token) {
      postToken();
    }
  }, [token, dispatch]);

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh', textAlign: 'center' }}>
      {emailData ? (
        <div>
          <h2>{emailData.message}</h2>
          <p>Account Created: {emailData.isAccountCreated ? "Yes" : "No"}</p>
          <p>Verified: {emailData.isVerified ? "Yes" : "No"}</p>
        </div>
      ) : (
        <h2>Verifying email, please wait...</h2>
      )}
    </div>
  );
}

export default VerifyEmail;







  // useEffect(() => {
  //   const postToken = async () => {
  //     try {
  //       // Create a FormData object to hold the token
  //       const formData = new FormData();
  //       formData.append('token', token);

  //       // Dispatch the postTokenAction with formData
  //       const response = await dispatch(postEmailTokenAction(token));
  //       console.log("response in verify email",response);

  //       // Check if the response is successful
  //       // if (gaildata) {
  //       //   const id = 11;
  //       //   navigate(`/quote/${id}`);
  //       // }
  //       // else
  //       // {
  //       //     console.error('Error posting token:');
  //       // }
  //     } catch (error) {
  //       console.error('Error posting token:', error);
  //     }
  //   };

  //   // Only call the function if a token is available
  //   if (token) {
  //     postToken();
  //   }
  // }, [token, navigate, dispatch]);
