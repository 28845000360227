import * as React from 'react';
import PropTypes from 'prop-types';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Notes from '../notes/Notes';
import PoliciesList from '../managePolicy/PoliceseList';
import TaskList from '../task/TaskList';
import PayHistory from '../payhistory/PayHistory'; // Import PayHistory component

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `fullwidthtab${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const ManagePolicy = () => {
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(true);
  
  // Add data loading state
  const [policyData, setPolicyData] = React.useState(null);

  const [counts, setCounts] = React.useState({
    policies: 10,
    notes: 5,
    tasks: 8,
    payHistory: 2,
    attachments: 7,
    quotes: 4,
    claims: 6,
    sweep: 3,
  });

  // Add data fetching effect
  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        // Fetch your policy data here
        // const data = await yourFetchCall();
        // setPolicyData(data);
      } catch (error) {
        console.log('Data fetch error:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // Only render content when data is loaded
  const renderTabPanel = (key, index) => {
    if (isLoading) return null;

    return (
      <TabPanel key={key} value={value} index={index} dir={theme.direction} id={`tabpanel-${key}`}>
        {key === 'policies' ? (
          <PoliciesList policyData={policyData} />
        ) : key === 'notes' ? (
          <Notes />
        ) : key === 'tasks' ? (
          <TaskList />
        ) : key === 'payHistory' ? (
          <PayHistory />
        ) : (
          `Item ${index + 1}`
        )}
      </TabPanel>
    );
  };

  return (
    <Box sx={{ bgcolor: 'background.paper' }} id="managepolicycontainer">
      <AppBar position="static" sx={{ boxShadow: "none", borderBottom: '#C1C1C1 solid 4px' }}>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          id="managepolicytabs"
        >
          {Object.keys(counts).map((key, index) => (
            <Tab
              key={key}
              id={`tab-${key}`}
              sx={{
                textTransform: 'capitalize',
                color: value === index ? '#0173BC' : '#C1C1C1',
                borderBottom: value === index ? '2px solid #0173BC' : 'none',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
              label={
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  <Typography component="span">
                    {key.charAt(0).toUpperCase() + key.slice(1)}
                  </Typography>
                  <Box
                    component="span"
                    sx={{
                      marginLeft: 1,
                      padding: '2px 8px',
                      backgroundColor: value === index ? '#0173BC' : '#C1C1C1',
                      borderRadius: '4px',
                      color: '#fff',
                    }}
                  >
                    {counts[key]}
                  </Box>
                </Box>
              }
              {...a11yProps(index)}
            />
          ))}
        </Tabs>
      </AppBar>
      {Object.keys(counts).map((key, index) => (
        <TabPanel key={key} value={value} index={index} dir={theme.direction} id={`tabpanel-${key}`}>
          {key === 'policies' ? (
            <PoliciesList />
          ) : key === 'notes' ? (
            <Notes />
          ) : key === 'tasks' ? (
            <TaskList />
          ) : key === 'payHistory' ? (
            <PayHistory />
          ) : (
            `Item ${index + 1}`
          )}
        </TabPanel>
      ))}
    </Box>
  );
};

export default ManagePolicy;
