import React, { useState } from 'react';
import UserList from './UserList';
import UserProfileForm from './UserProfileForm';
import { Box } from '@mui/material';

function UserManager() {
  const [locations, setLocations] = useState([]);
  const [showForm, setShowForm] = useState(false);

  const addLocation = (location) => {
    // Validation: Check if location is valid before adding
    if (location && location.name) {
      setLocations((prevLocations) => [...prevLocations, location]);
      setShowForm(false);
    } else {
      // Handle validation error (e.g., show a message)
      console.error('Invalid location');
    }
  };

  const toggleForm = () => {
    setShowForm((prevShowForm) => !prevShowForm);
  };

  return (
    <Box>
      {showForm ? (
        <UserProfileForm
          addLocation={addLocation}
          toggleForm={toggleForm}
          id="userprofileform" 
          name="userProfileForm" 
        />
      ) : (
        <UserList
          locations={locations}
          toggleForm={toggleForm}
          id="userlist" 
          name="userList" 
        />
      )}

    </Box>
  );
}

export default UserManager;



