import React, { useState, useEffect } from "react";
import {
  Paper,
  Box,
  TablePagination,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useDispatch, useSelector } from "react-redux";

import Loader from "../../loader/loader";
import PolicyDetails from "../PolicyDetails/PolicyDetails";
import {managepolicyallAction} from '../../../redux/actions/dashboardAction';


const PolicyListing = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  // Load initial data when the component mounts
  useEffect(() => {
    dispatch(managepolicyallAction(page,rowsPerPage));
  }, [dispatch,page,rowsPerPage]);
  
  // Load initial data into filteredData
  const policyListingData =
    useSelector((state) => state.dashboardData.manage_policy_all.content) || [];
    const manage_policy_pagination =
    useSelector((state) => state.dashboardData.manage_policy_all) || [];
    const loading = useSelector((state) => state.dashboardData.loading);

    
  useEffect(() => {
    setFilteredData(policyListingData); // Load all data by default
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = policyListingData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  const handleRowClick = (id) => {
    const selectedRowData = policyListingData.filter((row) => row.id === id);
    setSelectedId(selectedRowData);
  };

    return (
        <>
            <TableContainer component={Paper} sx={{ mt: 3, marginLeft:'20px', marginRight:'20px',width: 'calc(100% - 40px)', }}>
                <Table sx={{ minWidth: 300 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell className="styled-table-heading" id="nameheader">Name</TableCell>
                            <TableCell className="styled-table-heading" id="policynumberheader" align="left">Policy Number</TableCell>
                            <TableCell className="styled-table-heading" id="phonenumberheader" align="left">Phone Number</TableCell>
                            <TableCell className="styled-table-heading" id="policytypeheader" align="left">Policy Type</TableCell>
                            <TableCell className="styled-table-heading" id="companyheader" align="left">Company</TableCell>
                            <TableCell className="styled-table-heading" id="lineofbusinessheader" align="left">Line of Business</TableCell>
                            <TableCell className="styled-table-heading" id="effdateheader" align="left">Eff Date</TableCell>
                            <TableCell className="styled-table-heading" id="expdateheader" align="left">Exp Date</TableCell>
                            <TableCell className="styled-table-heading" id="statusheader" align="left">Status</TableCell>
                            <TableCell className="styled-table-heading" id="premiumheader" align="left">Premium</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {loading ? (
                                <TableRow>
                                    <TableCell colSpan={8}>
                                        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                                        <Typography variant="subtitle1" color="textSecondary">
                                            <Loader />
                                        </Typography>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            ) : paginatedRows.length === 0 ? (
                            <TableRow>
                                <TableCell colSpan={10}>
                                    <Box
                                        sx={{
                                        display: "flex",
                                        justifyContent: "center",
                                        padding: 2,
                                        }}
                                    >
                                        <Typography variant="subtitle1" color="textSecondary">
                                            No Data found.
                                        </Typography>
                                    </Box>
                                </TableCell>
                            </TableRow>
                            ) : (
                            paginatedRows.map((row) => (
                            <TableRow
                                onClick={() => handleRowClick(row.id)}
                                key={row.id}
                                id={`row-${row.id}`}
                                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row?.name}
                                </TableCell>
                                <TableCell align="left">{row?.policyNumber}</TableCell>
                                <TableCell align="left">
                                    {row?.phoneNumber}
                                </TableCell>
                                <TableCell align="left">{row?.policyType}</TableCell>
                                <TableCell align="left">{row?.company}</TableCell>
                                <TableCell align="left">{row?.lineOfBusiness}</TableCell>
                                <TableCell align="left">{row?.effectiveDate}</TableCell>
                                <TableCell align="left">{row?.expiryDate}</TableCell>
                                <TableCell align="left">{row?.status || "active"}</TableCell>
                                <TableCell align="left">{row?.premium}</TableCell>
                            </TableRow>
                            ))
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {/* <TablePagination
                rowsPerPageOptions={[5, 10, 25,50]}
                component="div"
                count={policyListingData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                sx={{ marginRight: '20px' }}
            /> */}
            <TablePagination
                rowsPerPageOptions={manage_policy_pagination.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
                component="div"
                count={manage_policy_pagination.totalElements}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />

            <PolicyDetails selectedPolicyDetail={selectedId} />
        </>
    )
}
export default PolicyListing;