import * as themeServices from '../../services/themeServices';
import {
    START_LOADING,
    STOP_LOADING
} from "./actionTypes"
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const getThemeAction = (customerId, type) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await themeServices.getTheme(customerId, type, token);
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    dispatch({ type: 'GET_THEME_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      dispatch({ type: 'GET_THEME_FAILURE', payload: typeof(error)==="object" ? error.message : error || 'Get theme service failed. Please try again.' });
    }
  }
};

export const saveThemeAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await themeServices.saveTheme(formData, token);
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    console.log(response, 'responseresponseresponse')
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'SAVE_THEME_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'SAVE_THEME' });
      dispatch({ type: 'SAVE_THEME_FAILUTE', payload: typeof(error)==="object" ? error.message : error || 'Save theme service failed. Please try again.' });
    }
  }
};

export const updateThemeAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_THEME' });
    const token = getState().auth.token || sessionStorage.getItem("token");;
    const response = await themeServices.updateTheme(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_THEME' });
    if(response) {
      callbackfn();
    }
    dispatch({ type: 'UPDATE_THEME_SUCCESS', payload: response });
  } catch (error) {
    if((typeof(error) !== "object" && error?.includes("403")) || (error && error.message?.includes("403"))) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'UPDATE_THEME' });
      dispatch({ type: 'UPDATE_THEME_FAILUTE', payload: typeof(error)==="object" ? error.message : error || 'Update theme service failed. Please try again.' });
    }
  }
};
