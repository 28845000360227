import React, { useEffect, useState } from "react";
import { Route, Routes, Navigate, useLocation } from "react-router-dom";

import { makeStyles } from "@mui/styles";
import { Box } from '@mui/material';
import { ThemeProvider, createTheme } from "@mui/material/styles";

import { useForm,FormProvider } from 'react-hook-form';
import SignIn from "./pages/Login";
import theme from "./common/theme";
import SignUp from "./pages/Signup";
import { useSelector, useDispatch } from "react-redux";
import SessionTimeoutModal from "./common/SessionTimeOutModal";
import { closeSessionTimeoutModal } from "./redux/actions/sessionTimeOutActions";
import { signOut } from "./redux/actions/authActions";
import { accessRules } from "./common/helpers/utils";
import { getThemeAction } from "./redux/actions/themeActions";
import { getUser } from "./redux/actions/userActions";
import ResetPasswordForm from "./pages/ResetPassword";
import ForgotPasswordForm from "./pages/ForgotPassword";

import MainView from "./pages/MainView";
import RaterHeader from "./common/RaterHeader";
import GeneralTab from './components/general/clientOther';
import GeneralQuote from './components/general/generalQuote';
import DriverSave from './components/drivers/driverSave1';
import DriverInformationList from './components/drivers/driverInformationList';
import VehicleSave from './components/cars/vehicleSave1';
import VehicleAttribute from './components/cars/VehicleAttribute';
import VehicleAdditionalAttribute from './components/cars/VehicleAdditionalAttribute';
import VehicleInformationList from './components/cars/VehicleInformationList';
import ManagePolicy from "./components/managePolicy/ManagePolicy";
import SearchPolicy from "./components/managePolicy/SearchPolicy";
import PolicyDetails from "./components/SidebarLayout/PolicyDetails/PolicyDetails";
import PolicyListing from "./components/SidebarLayout/PolicyListing/PolicyListing";
import SearchSavedQuote from "./components/searchQuotes/SearchSavedQuote";
import Settings from './pages/user/Settings';
import QuoteListing from "./components/quoteListing/Listing";
import NoteSearch from "./components/notes/NoteSearch";
import MenuDropDown from "./pages/Layout/SidebarLayout/dropdown/MenuDropDown";
import CustomerListing from "./components/searchCustomer/customerListing";
import Dashboard from './components/SidebarLayout/PolicyDetails/Dashboard';
import CustomThemeSettings from './pages/user/CustomThemeSettings';
import UserProfileForm from "./components/admin/User/UserProfileForm";
import AgencyList from "./components/admin/AgencyList";
import AddLocationForm from "./components/admin/AddLocationForm";
import LocationGroup from "./components/admin/LocationGroup";
import LocationManager from "./components/admin/LocationManager";
import Location from "./components/admin/Location";
import UserManager from "./components/admin/User/UserManager";
import CarrierGroup from "./components/admin/CarrierGroup";
import ManageCarrier from "./components/admin/Carrier/ManageCarrierListing";
import CarrierManager from "./components/admin/Carrier/CarrierManager";
import CreateCarrier from "./components/admin/CreateCarrier";
import AddInsuranceCarrier from "./components/admin/Carrier/AddInsuranceCarrier";
import Gail from "./common/Gail";
import Signupbot from "./components/bot/Signupbot";
import Botlogin from "./components/bot/Botlogin";
import BotReport from "./components/bot/BotReport";
import BotAllReport from "./components/bot/BotAllReport";
import VerifyEmail from "./common/links/VerifyEmail";
import GailLogin from "./common/GailLogin";
import EditUserProfile from "./components/admin/EditUserProfile/EditUserProfile";

const useStyles = makeStyles({
  mainContainer: {
    minHeight: "90vh",
  },
});
const RaterRoute = () => {
  const [view, setView] = useState(false);
  const methods = useForm();
  const classes = useStyles();
  const token = useSelector(
    (state) => state.auth?.token || sessionStorage.getItem("token")
  );
  const user = useSelector((state) => state?.user?.user);
  const isOpen = useSelector((state) => state.sessionTimeoutModal?.isOpen);
  const dispatch = useDispatch();
  const permissions = user?.roleInfo?.permissions || [];
  const rules = accessRules(permissions);

  const dynamicTheme = useSelector((state) => state?.theme?.setDefaultTheme);

  const location = useLocation();

  // Function to extract token from query parameters


  // useEffect(() => {
  //   if (user?.customer?.id) {
  //     dispatch(getThemeAction(user?.customer?.id));
  //   }
  // }, [user]);

  const customTheme = createTheme({
    ...theme,
    palette: {
      primary: {
        main: dynamicTheme?.primaryColor || "#077BFD",
      },
      secondary: {
        main: dynamicTheme?.secondaryColor || "#000",
      },
      iconColors: {
        customIconColor1: dynamicTheme?.primaryColor || "#077BFD",
      },
    },
  });

  useEffect(() => {
    if (!user && token) {
      dispatch(getUser());
    } else if (token === null) {
      // console.log(token, "token");
      dispatch(signOut());
    }
  }, [token]);

  const handleCloseSessionTimeoutModal = async () => {
    await dispatch(closeSessionTimeoutModal());
    await dispatch(signOut());
  };
  return (
    <>
      <ThemeProvider theme={theme}>
        {token && <RaterHeader />}
        <Box sx={{padding: 0, margin: 0}} className={classes.mainContainer}>
          {/* {token && <SideBar />} */}
          <Routes>
            {!token && <Route path="/*" element={<Navigate to="/login" />} />}
            {/* {token && <Route path="/dashboard" element={<Navigate to="/Dashboard" />} />} */}
            {token && <Route path="/login" element={<Navigate to="/home" />} />}
            {token && <Route path="/gaillogin" element={<Gail />} />}
            <Route path="/Gail" element={<Gail />} />
            {token && <Route path="/signup" element={<Navigate to="/home" />} />}
            {!token && <Route path="/login" element={<SignIn />} />}
            {!token && <Route path="/signup" element={<SignUp />} />}
            {!token && <Route path="/forgotPassword" element={<ForgotPasswordForm />} />}
            {!token && <Route path="/resetPassword" element={<ResetPasswordForm />} />}
            <Route path="/login" element={<SignIn />} />
            <Route path="/gaillogin" element={<GailLogin />} />

            {token && <Route path="/home" element={<Dashboard />} />}
            <Route path="/generalQuote" element={<GeneralQuote />} />
            {token && <Route path="/dashboard" element={<Dashboard />} />}
            <Route path="/managepolicy" element={<ManagePolicy />} />
            <Route path="/searchpolicy" element={<CustomerListing />} />
            {/* <Route path="/policydetails" element={<PolicyDetails />} /> */}
            {/* <Route path="/searchsavedquote" element={<QuoteListing />} /> */}
            <Route path="/driver" element={<DriverSave />} />
            <Route path="/driverInfo" element={<DriverInformationList />} />
            <Route path="/vehicle" element={<VehicleSave />} />
            <Route path="/vehicleAttribute" element={<VehicleAttribute />} />
            <Route path="/vehicleAdditionalAttribute" element={<VehicleAdditionalAttribute />} />
            <Route path="/vehiclelist" element={<VehicleInformationList />} />

            <Route path="/verifyEmail" element={<VerifyEmail />} />
            {token && <Route path="/mainview" element={<MainView />} />}
            <Route path="/client" element={<GeneralTab />} />
            {token &&  <Route path="/searchsavedquote" element={<QuoteListing />}  />}
            {token && <Route path="/managepolicylisting" element={<PolicyListing />} />}
            {token && <Route path="/signupbot" element={<Signupbot />} />}
            {token && <Route path="/botreport" element={<BotReport />} />}
            {token && <Route path="/botallreport" element={<BotAllReport />} />}
            {token && <Route path="/signupbots" element={<Botlogin />} />}
            {token && <Route path="/customthemesettings" element={<CustomThemeSettings />} />}
            {token && <Route path="/CarrierGroup" element={<CarrierGroup />} />}
            {token && <Route path="/CreateCarrier" element={<CreateCarrier />} />}
            {token && <Route path="/Addinsurancecarrier" element={<AddInsuranceCarrier/>} />}
            {token && <Route path="/managecarrier" element={<CarrierManager />} />}
            {token && <Route path="/userprofile" element={<UserProfileForm />} />}
            {token && <Route path="/usermanager" element={<UserManager />} />}
            {token && <Route path="/addlocation" element={<AddLocationForm />} />}
            {token && <Route path="/locationmanager" element={<LocationManager />} />}
            {token && <Route path="/locationgrp" element={<LocationGroup />} />}
            {token && <Route path="/locationgroup" element={<Location />} />}
            {token && <Route path="/" element={<MainView setView={!view}/>} />}
            {token && <Route path="/quote/:quoteId" element={<MainView setView={view}/>} />}
           <Route path="/quoteservice?tkn=''" element={<MainView setView={view}/>} />
            {token && <Route path="/mainview/:quoteId" element={<MainView />} />}
            {token &&<Route path="/settings" element={<Settings />} />}
            {token &&<Route path="/quote/listing" element={<QuoteListing />} />}
            {token && <Route path="/dropdown" element={<MenuDropDown />} />}
            {token && <Route path="/edituserprofile" element={<EditUserProfile />} />}
            
            <Route path="/notesearch" element={ <FormProvider {...methods}><NoteSearch /></FormProvider>} />
          </Routes>
          {token && (
            <SessionTimeoutModal
              open={isOpen}
              onClose={handleCloseSessionTimeoutModal}
            />
          )}
        </Box>
      </ThemeProvider>
    </>
  );
};

export default RaterRoute;
