// import {
//   CLOSE_CREATE_USER_MODAL,
//   OPEN_CREATE_USER_MODAL,
//   OPEN_UPDATE_USER_MODAL,
// } from "../actions/actionTypes";

// const initialState = {
//   allUser: [],
//   user: null,
//   error: null,
//   allRates: {},
//   isCreateUserModalOpen: false,
//   selectedUserId: null,
//   xmlJson: "",
//   xmlJsonUnited: "",
// };

// const userReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case "USER_INFO_SUCCESS":
//       return { ...state, user: action.payload, error: null };
//     case "USER_INFO_FAILURE":
//       return { ...state, user: null, error: action.payload };
//     case "ALL_USERS_SUCCESS":
//       return { ...state, allUser: action.payload, error: null };
//     case "CREATE_USER_SUCCESS":
//     case "UPDATE_USER_SUCCESS":
//       return {
//         ...state,
//         allUser: [...state.allUser, ...action.payload],
//         error: null,
//       };
//     case OPEN_CREATE_USER_MODAL:
//       return { ...state, isCreateUserModalOpen: true };
//     case OPEN_UPDATE_USER_MODAL:
//       return {
//         ...state,
//         isCreateUserModalOpen: true,
//         selectedUserId: action.payload,
//       };
//     case CLOSE_CREATE_USER_MODAL:
//       return { ...state, isCreateUserModalOpen: false, selectedUserId: null };
//     case "GET_RATE_XML_SUCCESS":
//       return { ...state, xmlJson: action.payload, error: null };
//     case "GET_RATE_XML_UNITED_SUCCESS":
//       return { ...state, xmlJsonUnited: action.payload, error: null };
//     case "GET_RATE_XML_ALL_SUCCESS":
//       return { ...state, allRates: action.payload, error: null };
//     case "ALL_USERS_FAILURE":
//     case "CREATE_USER_FAILURE":
//     case "GET_RATE_XML_FAILURE":
//     case "UPDATE_USER_FAILURE":
//       return { ...state, error: action.payload };
//     default:
//       return state;
//   }
// };

// export default userReducer;



import {
  CLOSE_CREATE_USER_MODAL,
  OPEN_CREATE_USER_MODAL,
  OPEN_UPDATE_USER_MODAL,
} from "../actions/actionTypes";

const initialState = {
  allUser: [],
  user: null,
  error: null,
  allRates: [],
  allVehicles: [],
  isCreateUserModalOpen: false,
  selectedUserId: null,
  xmlJson: "",
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case "USER_INFO_SUCCESS":
      return { ...state, user: action.payload, error: null };
    case "USER_INFO_FAILURE":
      return { ...state, user: null, error: action.payload };
    case "ALL_USERS_SUCCESS":
      return { ...state, allUser: action.payload, error: null };
    case "CREATE_USER_SUCCESS":
    case "UPDATE_USER_SUCCESS":
      return {
        ...state,
        allUser: [...state.allUser, ...action.payload],
        error: null,
      };
    case OPEN_CREATE_USER_MODAL:
      return { ...state, isCreateUserModalOpen: true };
    case OPEN_UPDATE_USER_MODAL:
      return {
        ...state,
        isCreateUserModalOpen: true,
        selectedUserId: action.payload,
      };
    case CLOSE_CREATE_USER_MODAL:
      return { ...state, isCreateUserModalOpen: false, selectedUserId: null };
    case "GET_RATE_XML_SUCCESS":
      return { ...state, xmlJson: action.payload, error: null };
    //  case "GET_RATE_XML_ALL_SUCCESS":
    //   console.log("Appending rates to allRates:", action.payload);
    //   return { ...state, allRates: [...state.allRates, ...action.payload], error: null };
    case "GET_RATE_XML_ALL_SUCCESS":
      console.log("Appending rates to allRates:", action.payload);
      // Use a Set to ensure unique rates, then convert back to array
      const uniqueRates = [...new Set([...state.allRates, action.payload])];
      return { ...state, allRates: uniqueRates, error: null };
    case "GET_RATE_VEHICLE_ALL_SUCCESS":
      console.log("Appending rates to allRates:", action.payload);
      // Use a Set to ensure unique rates, then convert back to array
      const uniqueDetails = [...new Set([...state.allVehicles, action.payload])];
      return { ...state, allVehicles: uniqueDetails, error: null };
    case "RESET_ALL_RATES":
      return { ...state, allRates: [] };
    case "ALL_USERS_FAILURE":
    case "CREATE_USER_FAILURE":
    case "GET_RATE_XML_FAILURE":
    case "UPDATE_USER_FAILURE":
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default userReducer;
