import React, { useState, useEffect } from "react";
import { Paper, TablePagination, Typography } from "@mui/material";
import { TextField, Button } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, Select, MenuItem, Box, InputLabel } from "@mui/material";
import {
  fetchQuoteFilteredDataAction,
  setDashboardData,
} from "../../../redux/actions/dashboardAction";
import {
 
  getGeneralPartyInfoByIdDashboard,
} from "../../../redux/actions/generalPartyInfoActions";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader/loader";
import { styled } from "@mui/material/styles";

const QuoteTableData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isFiltered, setIsFiltered] = useState(false);
  const [quoteToFetch, setQuoteToFetch] = useState(null);
  const [filteredData, setFilteredData] = useState([]);


  const generalData = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfoDash
  );

  useEffect(() => {
    if (quoteToFetch) {
      dispatch(getGeneralPartyInfoByIdDashboard(quoteToFetch));
    }
  }, [quoteToFetch, dispatch]);
  useEffect(() => {
    if (generalData && Object.keys(generalData).length > 0) {
      navigate("/mainview", { state: { generalData } });
    }
  }, [generalData, navigate]);

  useEffect(() => {
    if(!isFiltered){
      dispatch(setDashboardData(page, rowsPerPage));
    }    
  }, [dispatch, page, rowsPerPage]);


  const quoteData =
    useSelector((state) => state.dashboardData.quotes?.quotesList) || [];
    const loading = useSelector((state) => state.dashboardData.loading);

    const paginationData =
    useSelector((state) => state.dashboardData.quotes?.pageable) || [];


    useEffect(() => {
      setFilteredData(quoteData);
    }, [quoteData]);
  
  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "fromDate") setFromDate(value);
    if (name === "toDate") setToDate(value);
  };

  const handleApply = () => {
    if (fromDate && toDate) {
      const formData = {
        fromDate: fromDate,
        toDate: toDate,
        status: selectedFilter !== " " ? selectedFilter : "", // send an empty string if 'All' is selected
      };
      if ((fromDate && toDate) || selectedFilter !== "All") {
        dispatch(fetchQuoteFilteredDataAction(formData, page, rowsPerPage));
        setIsFiltered(true);
      } else {
        setFilteredData(filteredData);
        setIsFiltered(false);
      }
      setFilteredData(filteredData);

      // Fetch filtered data based on date range and selected filter
      
    }
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleRowClickQuotes = (quoteNumber) => {
    if (!quoteNumber) {
      console.error("quoteNumber is undefined or missing.");
      return;
    }
    const numericPart = quoteNumber.replace(/^Q0*/, ""); // Remove leading 'Q' and zeros

    setQuoteToFetch(numericPart);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        status: selectedFilter,
      };
      dispatch(fetchQuoteFilteredDataAction(formData, newPage, rowsPerPage));
    } else {
      dispatch(setDashboardData(newPage, rowsPerPage));
    }
  };

  const handleChangeRowsPerPage = (event) => {

    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        policyType: selectedFilter,
      };
      dispatch(fetchQuoteFilteredDataAction(formData, 0, newRowsPerPage));
    } else {
      dispatch(setDashboardData(0, newRowsPerPage));
    }
  };

  // const paginatedRows = quoteData.slice(
  //   page * rowsPerPage,
  //   page * rowsPerPage + rowsPerPage
  // );

  return (
    <>
      <Box sx={{ display: "flex", width: "100%", marginBottom: "20px" }}>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>From Date</label>
            <TextField
              type="date"
              name="fromDate"
              id="fromdate"
              value={fromDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>To Date</label>
            <TextField
              type="date"
              id="todate"
              name="toDate"
              value={toDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <FormControl sx={{ width: "250px" }}>
            <label id="selectlineofbusiness">Select Line of Business</label>
            <Select value={selectedFilter} onChange={handleFilterChange}>
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Sold">Sold</MenuItem>
              <MenuItem value="Not Sold">Not Sold</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            onClick={handleApply}
            id="apply-button"
            sx={{ ml: 2, mt:3 }}
            disabled={!fromDate || !toDate}
            className="btn-primary"
          >
            Apply
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>Quote Number</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Name</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Email</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Date of Birth</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">License Number</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Eff Date</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Exp Date</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Last Modified</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow className="styled-table-row">
              <TableCell colSpan={9}>
                <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                  <Typography variant="subtitle1" color="textSecondary">
                    <Loader />
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            ) : filteredData.length === 0 ? (
              <TableRow className="styled-table-row">
                <TableCell colSpan={9}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 2,
                    }}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      No Data found.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((row) => (
                <TableRow className="styled-table-row"
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  onClick={() => handleRowClickQuotes(row.quoteNumber)}
                >
                  <TableCell component="th" scope="row">
                    {row?.quoteNumber}
                  </TableCell>
                  <TableCell align="left">{row?.name}</TableCell>
                  <TableCell align="left">{row?.email}</TableCell>
                  <TableCell align="left">{row?.dateOfBirth}</TableCell>
                  <TableCell align="left">{row?.licenseNumber}</TableCell>
                  <TableCell align="left">{row?.effDate}</TableCell>
                  <TableCell align="left">{row?.expDate}</TableCell>
                  <TableCell align="left">{row?.lastModified}</TableCell>
                  <TableCell align="left">{row?.status}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={paginationData.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
        component="div"
        count={paginationData.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
};
export default QuoteTableData;
