// import {
//     START_LOADING,
//     STOP_LOADING
// } from "./actionTypes"
// import { savePolicy } from "../../services/policySevices";
// import { openSessionTimeoutModal } from './sessionTimeOutActions';

// export const savePolicyAction = (formData, callbackfn) => async (dispatch, getState) => {
//     try {
//       dispatch({ type: START_LOADING, service: 'SAVE_POLICY' });
//       const token = getState().auth.token || sessionStorage.getItem("token");
//       const response = await savePolicy(formData, token);
//       console.log(response)
//       dispatch({ type: STOP_LOADING, service: 'SAVE_POLICY' });
//       if(response) {
//         callbackfn(response);
//       }
//       dispatch({ type: 'SAVE_POLICY_SUCCESS', payload: response });
//     } catch (error) {
//       if(error?.includes("403")) {
//         dispatch(openSessionTimeoutModal());
//       } else {
//         dispatch({ type: STOP_LOADING, service: 'SAVE_POLICY' });
//         dispatch({ type: 'SAVE_POLICY_FAILUTE', payload: error || 'Save policy service failed. Please try again.' });
//       }
//     }
//   };


import {
  START_LOADING,
  STOP_LOADING,
  GET_POLICY_SUCCESS,
  SAVE_POLICY_SUCCESS,
  UPDATE_POLICY_SUCCESS,
  GET_POLICY_FAILURE,
  SAVE_POLICY_FAILURE,
  UPDATE_POLICY_FAILURE,
  DELETE_POLICY_SUCCESS,
  DELETE_POLICY_FAILURE
} from './actionTypes';
import { getDetailPolicy, savePolicy } from '../../services/policySevices';
import { updatePolicy } from '../../services/policySevices';
import { getAllPolicy } from '../../services/policySevices';
import { deletePolicyById } from '../../services/policySevices';
import { openSessionTimeoutModal } from './sessionTimeOutActions';

export const savePolicyAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'SAVE_POLICY' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await savePolicy(formData, token);
    // console.log(response);
    dispatch({ type: STOP_LOADING, service: 'SAVE_POLICY' });
    if (response) {
      callbackfn(response);
    }
    dispatch({ type: SAVE_POLICY_SUCCESS, payload: response }); // Dispatch the success action with response data
  } catch (error) {
    if (error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'SAVE_POLICY' });
      dispatch({ type: SAVE_POLICY_FAILURE, payload: error || 'Save policy service failed. Please try again.' });
    }
  }
};


export const updatePolicyAction = (formData) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_POLICY' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await updatePolicy(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_POLICY' });
    dispatch({ type: UPDATE_POLICY_SUCCESS, payload: response });
  } catch (error) {
    // console.log(error, 'error in action')
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'UPDATE_POLICY' });
      dispatch({ type: UPDATE_POLICY_FAILURE, payload: error || 'Update policy service failed. Please try again.' });
    }
  }
};

export const getAllPolicyAction= (page = 0, size = 5) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_POLICY' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await getAllPolicy(token,page,size);
    dispatch({ type: STOP_LOADING, service: 'ALL_POLICY' });
    dispatch({ type: GET_POLICY_SUCCESS, payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'ALL_POLICY' });
      dispatch({ type: GET_POLICY_FAILURE, payload: error || 'All policy service failed. Please try again.' });
    }
  }
};

export const deletePolicyActionById = (quoteId) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_POLICY' });
    const token = getState().auth.token || sessionStorage.getItem("token");

    const response= await deletePolicyById(quoteId,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_POLICY' });


    // dispatch({ type: 'ALL_DRIVER_INFOS_SUCCESS', payload: response });
    dispatch({ type: DELETE_POLICY_SUCCESS, payload: quoteId });
    return response;
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_POLICY' });
    dispatch({ type: DELETE_POLICY_FAILURE, payload: error.message || 'All policy service failed. Please try again.' });
  }
};



//policyDetails

export const getDetailPolicyAction= (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_POLICY' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await getDetailPolicy(token,id);
    dispatch({ type: STOP_LOADING, service: 'ALL_POLICY' });
    dispatch({ type: 'GET_DETAIL_POLICY_SUCCESS', payload: response });
  } catch (error) {
    if(error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'ALL_POLICY' });
      dispatch({ type: 'GET_DETAIL_POLICY_FAILURE', payload: error || 'All policy service failed. Please try again.' });
    }
  }
};