// import * as React from "react";
// import { styled } from "@mui/material/styles";
// import { useEffect } from "react";
// import Table from "@mui/material/Table";
// import TableBody from "@mui/material/TableBody";
// import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import TableContainer from "@mui/material/TableContainer";
// import TableHead from "@mui/material/TableHead";
// import TableRow from "@mui/material/TableRow";
// import Paper from "@mui/material/Paper";
// import Button from "@mui/material/Button";
// // import Box from '@mui/material/Box';
// import {
//   Box,
//   Grid,
//   FormControl,
//   Typography,
//   MenuItem,
//   Select,
//   FormHelperText,
// } from "@mui/material";
// import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
// import { DatePicker } from "@mui/x-date-pickers/DatePicker";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// import AddIcon from "@mui/icons-material/Add";
// import EditIcon from "@mui/icons-material/Edit";
// import IconButton from "@mui/material/IconButton";
// import FilterListIcon from "@mui/icons-material/FilterList";
// import dayjs from "dayjs";
// import {
//   Controller,
//   useForm,
//   FormProvider,
//   useFormContext,
// } from "react-hook-form";
// import AddNotes from "./AddNotes";
// import { TextField } from "@mui/material";
// import {
//   getAllNoteAction,
//   searchNoteAction,
// } from "../../redux/actions/noteActions";
// import { useDispatch, useSelector } from "react-redux";

// const notesearchData = [
//   {
//     fieldName: "from",
//     label: "From:",
//     type: "date",
//   },
//   {
//     fieldName: "to",
//     label: "To:",
//     type: "date",
//   },
//   {
//     fieldName: "userId",
//     label: "User Id *",
//     type: "text",
//   },
//   {
//     fieldName: "notes",
//     label: "Notes",
//     type: "text",
//   },
//   {
//     fieldName: "searchPolicy",
//     label: "Search Policy",
//     type: "dropdown",
//     options: ["All", "first"],
//   },
// ];

// const tableData = {
//   headers: ["Policy Number", "Date", "User Id", "Notes", "Action"],
// };

// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: "#0173BC",
//     color: theme.palette.common.white,
//     fontWeight: "bold",
//     fontSize: "1.2rem",
//     textAlign: "center",
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//     border: "none",
//     textAlign: "center",
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   "&:nth-of-type(odd)": {
//     backgroundColor: "#F8F8F8",
//   },
//   "&:nth-of-type(even)": {
//     backgroundColor: theme.palette.common.white,
//   },
//   "& td, & th, & tr": {
//     border: "none",
//     padding: "10px",
//   },
// }));

// const StyledTableContainer = styled(TableContainer)({
//   boxShadow: "none",
//   marginTop: "20px",
// });

// const PoliciesList = ({ searchParams }) => {
//   const [showNote, setshowNote] = React.useState(false);
//   const [showButton, setShowButton] = React.useState(false);
//   const notes = useSelector((state) => state.note.allNote);
//   const searchNotes = useSelector((state) => state.note.searchNote);
//   const dispatch = useDispatch();
//   const methods = useForm();
//   const {
//     control,
//     formState: { errors },
//     reset
//   } = methods;
//   useEffect(() => {
//     console.log('PoliciesList component mounted');
//   }, []);
//   console.log("notes value", notes.content);
//   console.log("search notes value", searchNotes);
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(4);
//   const [noteData, setNoteData] = React.useState([]);
//   const [hasSearched, setHasSearched] = React.useState(false);
//   const notesearchData = [
//     {
//       fieldName: "from",
//       label: "From:",
//       type: "date",
//     },
//     {
//       fieldName: "to",
//       label: "To:",
//       type: "date",
//     },
//     {
//       fieldName: "userId",
//       label: "User Id *",
//       type: "text",
//     },
//     {
//       fieldName: "message",
//       label: "Notes",
//       type: "text",
//     },
//     {
//       fieldName: "searchPolicy",
//       label: "Search Policy",
//       type: "dropdown",
//       options: [1, 2],
//     },
//   ];

//   // const onSubmit = data => {
//   //   dispatch(searchNoteAction(data,getCreateUserSuccess));
//   // };
//   const onSubmit = (data) => {
//     // dispatch(
//     //   searchNoteAction(data, () => {
//     //     setHasSearched(true);
//     //   })
//     // );
//   };

//   useEffect(() => {
//     dispatch(getAllNoteAction(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     if (hasSearched) {
//       setNoteData(notes.content);
//     } else {
//       setNoteData(notes.content);
//     }
//   }, [notes, hasSearched]);

//   const resetSearch = () => {
//     setHasSearched(false);
//     // methods.reset();
//     reset({
//       from: null,
//       to: null,
//       userId: '',
//       message: '',
//       searchPolicy: ''
//     });
//   };

//   function handleClick() {
//     setshowNote(true);
//     setShowButton(true);
//     // setTableData({});
//   }

//   const handleEditClick = (note) => {
//     // Navigate to CreatePolicy component with pre-filled data
//     setshowNote(true);
//     setShowButton(false);

//     // methods.reset(taskData);
//     methods.reset(note);
//   };

//   // React.useEffect(() => {
//   //   dispatch(getAllNoteAction());
//   // }, []);

//   return (
//     <>
//       {showNote ? (
//         <>
//           <FormProvider {...methods}>
//             <AddNotes setshowNote={setshowNote} setShowButton={showButton} />
//           </FormProvider>
//         </>
//       ) : (
//         <>
//           <FormProvider {...methods}>
//             <form onSubmit={methods.handleSubmit(onSubmit)}>
//               <Box sx={{ padding: "0 2rem 0 5rem", margin: "4rem 0 0 0" }}>
//                 <Grid container rowSpacing={1} sx={{ margin: "0" }}>
//                   {notesearchData.map(
//                     ({
//                       fieldName,
//                       label,
//                       type,
//                       options,
//                       rules,
//                       defaultValue,
//                     }) =>
//                       fieldName === "searchPolicy" ? (
//                         <Grid
//                           container
//                           key={fieldName}
//                           item
//                           xs={12}
//                           sm={12}
//                           md={8}
//                           lg={8}
//                           sx={{
//                             margin: "0",
//                             padding: "0 25px !important",
//                             alignItems: "center",
//                           }}
//                         >
//                           <Grid item xs={12} sm={6} md={6} lg={6}>
//                             <FormControl
//                               fullWidth
//                               margin="normal"
//                               error={!!errors[fieldName]}
//                             >
//                               <Typography>{label}</Typography>
//                               <Controller
//                                 name={fieldName}
//                                 control={control}
//                                 rules={rules}
//                                 defaultValue={defaultValue}
//                                 render={({ field }) => (
//                                   <Select
//                                     {...field}
//                                     error={!!errors[fieldName]}
//                                   >
//                                     {options.map((option, index) => (
//                                       <MenuItem key={index} value={option}>
//                                         {option}
//                                       </MenuItem>
//                                     ))}
//                                   </Select>
//                                 )}
//                               />
//                               {errors[fieldName] && (
//                                 <FormHelperText>
//                                   {errors[fieldName].message}
//                                 </FormHelperText>
//                               )}
//                             </FormControl>
//                           </Grid>
//                           {/* <Grid item xs={12} sm={6} md={6} lg={6} sx={{ marginTop: '24px',padding:"20px" }}>
//                     <Button variant="contained" color="primary" type="submit" >
//                       Search
//                     </Button>
//                   </Grid> */}
//                           <Grid
//                             item
//                             xs={12}
//                             sm={6}
//                             md={6}
//                             lg={6}
//                             sx={{ marginTop: "24px", padding: "20px" }}
//                           >
//                             <Button
//                               variant="contained"
//                               color="primary"
//                               type="submit"
//                               sx={{ marginRight: "10px" }}
//                             >
//                               Search
//                             </Button>
//                             <Button
//                               variant="outlined"
//                               color="secondary"
//                               onClick={resetSearch}
//                             >
//                               Reset
//                             </Button>
//                           </Grid>
//                         </Grid>
//                       ) : (
//                         <Grid
//                           key={fieldName}
//                           item
//                           xs={12}
//                           sm={6}
//                           md={4}
//                           lg={4}
//                           sx={{ margin: "0", padding: "0 25px !important" }}
//                         >
//                           <FormControl
//                             fullWidth
//                             margin="normal"
//                             error={!!errors[fieldName]}
//                           >
//                             <Typography>{label}</Typography>
//                             <Controller
//                               name={fieldName}
//                               control={control}
//                               rules={rules}
//                               defaultValue={defaultValue}
//                               render={({ field }) => {
//                                 if (type === "text" || type === "number") {
//                                   return (
//                                     <TextField
//                                       {...field}
//                                       inputProps={
//                                         type === "number"
//                                           ? {
//                                               inputMode: "numeric",
//                                               pattern: "[0-9]*",
//                                               maxLength: 10,
//                                             }
//                                           : {}
//                                       }
//                                       id={fieldName}
//                                       type={type}
//                                       sx={{ width: "100%" }}
//                                       error={!!errors[fieldName]}
//                                     />
//                                   );
//                                 } else if (
//                                   type === "dropdown" ||
//                                   type === "select"
//                                 ) {
//                                   return (
//                                     <Select
//                                       {...field}
//                                       error={!!errors[fieldName]}
//                                     >
//                                       {options.map((option, index) => (
//                                         <MenuItem key={index} value={option}>
//                                           {option}
//                                         </MenuItem>
//                                       ))}
//                                     </Select>
//                                   );
//                                 } else if (type === "date") {
//                                   return (
//                                     <LocalizationProvider
//                                       dateAdapter={AdapterDayjs}
//                                     >
//                                       <DatePicker
//                                         format="MM-DD-YYYY"
//                                         {...field}
//                                         value={
//                                           field.value
//                                             ? dayjs(field.value, "YYYY-MM-DD")
//                                             : null
//                                         }
//                                         onChange={(date) =>
//                                           field.onChange(
//                                             dayjs(date).format("YYYY-MM-DD")
//                                           )
//                                         }
//                                         slotProps={{
//                                           textField: {
//                                             fullWidth: true,
//                                             error: !!errors[fieldName],
//                                           },
//                                         }}
//                                       />
//                                     </LocalizationProvider>
//                                   );
//                                 }
//                               }}
//                             />
//                             {errors[fieldName] && (
//                               <FormHelperText>
//                                 {errors[fieldName].message}
//                               </FormHelperText>
//                             )}
//                           </FormControl>
//                         </Grid>
//                       )
//                   )}
//                 </Grid>
//               </Box>
//             </form>
//           </FormProvider>
//           <Box
//             sx={{
//               display: "flex",
//               flexDirection: "column",
//               alignItems: "center",
//               minHeight: "100vh",
//             }}
//           >
//             <Box
//               sx={{
//                 display: "flex",
//                 justifyContent: "flex-end",
//                 width: "100%",
//                 padding: "16px",
//               }}
//             >
//               <Button
//                 onClick={handleClick}
//                 variant="contained"
//                 color="primary"
//                 startIcon={<AddIcon />}
//                 sx={{ marginRight: "10px" }}
//               >
//                 Add Note
//               </Button>
//               <Button
//                 variant="contained"
//                 color="primary"
//                 startIcon={<FilterListIcon />}
//                 sx={{ marginRight: "10px" }}
//               >
//                 Filter
//               </Button>
//             </Box>
//             <StyledTableContainer component={Paper}>
//               <Table sx={{ minWidth: 650 }} aria-label="simple table">
//                 <TableHead>
//                   <TableRow>
//                     {tableData.headers.map((header, index) => (
//                       <StyledTableCell key={index}>{header}</StyledTableCell>
//                     ))}
//                   </TableRow>
//                 </TableHead>
//                 <TableBody>
//                   {(hasSearched ? searchNotes : notes.content).map((row, index) => (
//                     <StyledTableRow key={index}>
//                       <StyledTableCell>{row.policyId}</StyledTableCell>
//                       <StyledTableCell>{row.timestamp}</StyledTableCell>
//                       <StyledTableCell>{row.notesSource}</StyledTableCell>
//                       <StyledTableCell>{row.message}</StyledTableCell>
//                       <StyledTableCell>
//                         <IconButton
//                           onClick={() => handleEditClick(row)}
//                           color="primary"
//                         >
//                           <EditIcon />
//                         </IconButton>
//                       </StyledTableCell>
//                     </StyledTableRow>
//                   ))}
//                 </TableBody>
//               </Table>
//             </StyledTableContainer>
//           </Box>
//         </>
//       )}
//     </>
//   );
// };

// export default PoliciesList;

import * as React from "react";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
  Box,
  Grid,
  FormControl,
  Typography,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  TablePagination,
} from "@mui/material";
import { format } from "date-fns";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import IconButton from "@mui/material/IconButton";
import FilterListIcon from "@mui/icons-material/FilterList";
import dayjs from "dayjs";
import { Controller, useForm, FormProvider } from "react-hook-form";
import AddNotes from "./AddNotes";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllNoteAction,
  updateNoteAction,
  searchNoteAction,
} from "../../redux/actions/noteActions";
const notesearchData = [
  {
    fieldName: "fromDate",
    label: "From:",
    type: "date",
    id:"fromdate"
  },
  {
    fieldName: "toDate",
    label: "To:",
    type: "date",
    id:"todate"
  },
  // {
  //   fieldName: "userId",
  //   label: "User Id *",
  //   type: "text",
  // },
  {
    fieldName: "message",
    label: "Notes",
    type: "text",
    id:"message"
  },
  // {
  //   fieldName: "searchPolicy",
  //   label: "Search Policy",
  //   type: "dropdown",
  //   options: [1, 2],
  // },
];

const tableData = {
  headers: ["Policy Number", "Date", "User Id", "Notes", "Action"],
};



const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  margin: '16px auto',
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  maxWidth: '90vw',
  marginTop: '45px',
  border: `2px solid ${theme.palette.divider}`,
}));

const PoliciesList = ({ searchParams }) => {
  const [showNote, setshowNote] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const notes = useSelector((state) => state.note.allNote);
  const searchNotes = useSelector((state) => state.note.searchNote);
  const [editingNote, setEditingNote] = useState(null);
  const dispatch = useDispatch();
  const methods = useForm();
  
  const {
    control,
    formState: { errors },
    reset,
  } = methods;

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [noteData, setNoteData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    console.log("PoliciesList component mounted");
  }, []);

  // useEffect(() => {
  //   dispatch(getAllNoteAction(page, rowsPerPage));
  // }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    dispatch(getAllNoteAction(page,rowsPerPage));
  }, [dispatch,page,rowsPerPage]);

  useEffect(() => {
    if (hasSearched) {
      setNoteData(searchNotes || []);
    } else {
      setNoteData(notes || []);
    }
  }, [notes, searchNotes, hasSearched]);

  // const onSubmit = (data) => {
  //   dispatch(
  //     searchNoteAction(data, () => {
  //       setHasSearched(true);
  //     })
  //   );
  // };

  const onSubmit = (data) => {
    // Format the dates in the required format
    const formattedData = {
      ...data,
      fromDate: data.fromDate ? dayjs(data.fromDate).format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
      toDate: data.toDate ? dayjs(data.toDate).format("YYYY-MM-DDTHH:mm:ss[Z]") : null,
    };

    dispatch(
      searchNoteAction(formattedData, () => {
        setHasSearched(true);
      })
    );
  };

  const resetSearch = () => {
    setHasSearched(false);
    reset({
      fromDate: null,
      toDate: null,
      userId: "",
      message: "",
      searchPolicy: "",
    });
  };

  function handleClick() {
    setshowNote(true);
    setShowButton(true);
    methods.reset({
      message: "",

    });
  }

  const handleEditClick = (note) => {
    setshowNote(true);
    setShowButton(false);
    console.log("note in edit",note);
    methods.reset();
    methods.setValue("pubId", note.pubId);
    methods.setValue("timestamp", note.timestamp);
    methods.setValue("notesSource", note.notesSource);
    methods.setValue("message", note.message);
    methods.setValue("policyId", note.policyId);
    methods.setValue("updatedAt", note.updatedAt);
    methods.setValue("createdBy", note.createdBy);
    methods.setValue("id", note.id);

  };

  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), "MM-dd-yyyy");
  };
  const dataToDisplay = hasSearched ? searchNotes : noteData;
  console.log("data to display",dataToDisplay);
  const paginatedRows = dataToDisplay.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  return (
    <>
      {showNote ? (
        <FormProvider {...methods}>
          <AddNotes setshowNote={setshowNote} setShowButton={showButton} />
        </FormProvider>
      ) : (
        <>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <Box sx={{ padding: "0 0 0 1rem", margin: "1rem 0 0 0" }}>
                <Grid container rowSpacing={1} sx={{ margin: "0" }}>
                  {notesearchData.map(
                    ({
                      fieldName,
                      label,
                      type,
                      options,
                      rules,
                      defaultValue,
                    }) =>
                        (<Grid
                          key={fieldName}
                          item
                          xs={12}
                          sm={3}
                          md={2}
                          lg={2}
                          sx={{ padding: "5px", marginTop:"-17px" }}
                        >
                          <FormControl
                            fullWidth
                            margin="0"
                            error={!!errors[fieldName]}
                          >
                            <Typography>{label}</Typography>
                            <Controller
                              name={fieldName}
                              control={control}
                              rules={rules}
                              defaultValue={defaultValue}
                              render={({ field }) => {
                                if (type === "text" || type === "number") {
                                  return (
                                    <TextField
                                      {...field}
                                      inputProps={
                                        type === "number"
                                          ? {
                                              inputMode: "numeric",
                                              pattern: "[0-9]*",
                                              maxLength: 10,
                                            }
                                          : {}
                                      }
                                      id={fieldName}
                                      type={type}
                                      sx={{ width: "225px", height: "56px" }}
                                      error={!!errors[fieldName]}
                                    />
                                  );
                                } else if (
                                  type === "dropdown" ||
                                  type === "select"
                                ) {
                                  return (
                                    <Select
                                      {...field}
                                      error={!!errors[fieldName]}
                                    >
                                      {options.map((option, index) => (
                                        <MenuItem key={index} value={option}>
                                          {option}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  );
                                } else if (type === "date") {
                                  return (
                                    <LocalizationProvider
                                      dateAdapter={AdapterDayjs}
                                    >
                                      <DatePicker
                                        format="MM-DD-YYYY"
                                        {...field}
                                        value={
                                          field.value
                                            ? dayjs(field.value, "YYYY-MM-DD")
                                            : null
                                        }
                                        onChange={(date) =>
                                          field.onChange(
                                            dayjs(date).format("YYYY-MM-DD")
                                          )
                                        }
                                        slotProps={{
                                          textField: {
                                            fullWidth: true,
                                            //sx: { width: "50%" },
                                            error: !!errors[fieldName],
                                          },
                                        }}
                                      />
                                    </LocalizationProvider>
                                  );
                                }
                              }}
                            />
                            {errors[fieldName] && (
                              <FormHelperText>
                                {errors[fieldName].message}
                              </FormHelperText>
                            )}
                          </FormControl>
                        </Grid>
                      )
                  )}
                  <Grid
  item
  xs={12}
  sm={6}
  md={3}
  lg={3}
  sx={{ padding: "5px" }}
>
  <Box
    sx={{
      display: 'flex',
      justifyContent: 'space-between', // Spread buttons to left and right
      alignItems: 'center', // Align buttons vertically
      gap: '8px', // Space between "Search" and "Reset"
    }}
  >
    <Box
      sx={{
        display: 'flex',
        gap: '8px', 
        marginTop:'5px', // Space between Search and Reset
      }}
    >
      <Button
        type="submit"
        id="Search"
        variant="contained"
        className="btn-secondary"
        sx={{ marginLeft: "2px" }}
      >
        Search
      </Button>
      <Button
        onClick={resetSearch}
        variant="outlined"
        id="reset"
        className="btn-secondary"
        sx={{ marginLeft: "3px" }}
      >
        Reset
      </Button>
    </Box>
    <Box
              sx={{
                display: "flex",
                width: "95%",
                
                
              }}
            >
    <Button
      id="addnote"
      onClick={handleClick}
      variant="contained"
      className="btn-primary"
      //startIcon={<AddIcon />}
      sx={{
        marginLeft: "330px",
width:'140px',
      }}
    >
      Add Note
    </Button>
  </Box>
  </Box>
</Grid>
</Grid>
                
              </Box>
            </form>
          </FormProvider>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
                width: "95%",
                padding: "16px",
                marginBottom:"-52px"
              }}
            >
              {/* <Button
              id="addnote"
                onClick={handleClick}
                variant="contained"
                className='btn-primary'
                startIcon={<AddIcon />}
                sx={{ marginLeft:"-20px", marginTop:"-65px" }}
              >
                Add Note
              </Button> */}
              {/* <Button
                variant="contained"
                color="primary"
                startIcon={<FilterListIcon />}
                sx={{ marginRight: "10px" }}
              >
                Filter
              </Button> */}
            </Box>
            <StyledTableContainer component={Paper}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {tableData.headers.map((header, index) => (
                      <TableCell key={index} className="styled-table-heading" sx={{fontWeight: 'bold'}}>{header}</TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {paginatedRows.map((row, index) => (
                    <TableRow key={index}  className="styled-table-row">  
                      <TableCell >{row.pubId}</TableCell>
                      <TableCell >
                        {formatDate(row.timestamp)}
                      </TableCell>
                      <TableCell >{row.notesSource}</TableCell>
                      <TableCell >{row.message}</TableCell>
                      <TableCell>
                        <IconButton
                          onClick={() => handleEditClick(row)}
                          color="primary"
                        >
                          <EditIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            
             <TablePagination
        component="div"
        count={dataToDisplay.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15,25]}
        sx={{
          alignSelf: 'flex-end',   // Align it to the bottom-right of the container
          marginTop: '16px',       // Push it to the bottom of the container (if there's extra space)
          marginBottom: 0,   // Add some padding if you want spacing around the pagination
        }}
      />
      </StyledTableContainer>
          </Box>
        </>
      )}
    </>
  );
};

export default PoliciesList;
