import React from 'react';
import { Box, Grid,  Select, MenuItem, FormControl, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import VisibilityIcon from '@mui/icons-material/Visibility';

const dripData = {
  "headers": ["Activity", "Drip Type", "Contact Type", "Request Date", "Schedule Date", "Contact Date", "Closed Date", "View"],
  "data": [
    {
      "activity": "Driver Birthday (Before)",
      "dripType": "Main",
      "contactType": "E-Mail",
      "requestDate": "08/18/2023 12:44:24 AM",
      "scheduleDate": "08/18/2023 08:44:00 AM",
      "contactDate": "08/18/2023 08:43:04 AM",
      "closedDate": "08/18/2023 08:44:05 AM"
    },
    {
      "activity": "Customer Birthday (Before)",
      "dripType": "Main",
      "contactType": "E-Mail",
      "requestDate": "08/18/2023 12:44:24 AM",
      "scheduleDate": "08/18/2023 08:44:00 AM",
      "contactDate": "08/18/2023 08:43:04 AM",
      "closedDate": "08/18/2023 08:44:05 AM"
    },
    {
      "activity": "Driver Birthday (Before)",
      "dripType": "Main",
      "contactType": "Agent Task",
      "requestDate": "08/18/2023 12:44:24 AM",
      "scheduleDate": "08/18/2023 08:44:00 AM",
      "contactDate": "08/18/2023 08:43:04 AM",
      "closedDate": "08/18/2023 08:44:05 AM"
    }
  ]
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#F8F8F8',
    padding: '0 10px',
  },
  '&:nth-of-type(even)': {
    backgroundColor: theme.palette.common.white,
    padding: '0 10px',
  },
  '& td, & th, & tr': {
    border: 'none',
    padding: '0 10px',
  },
}));

const StyledTableContainer = styled(TableContainer)({
  boxShadow: 'none',
  '& tr': {
    border: 'none',
  },
});

const ViewDrip = () => {
  const [filter, setFilter] = React.useState('Both Items');

  const handleChange = (event) => {
    setFilter(event.target.value);
  };

  const filteredData = dripData.data.filter(item => filter === 'Both Items' || item.contactType === filter);

  return (
    <Box p={2} id="viewdripcontainer">
    <Grid container justifyContent="flex-end" mb={2}>
      <FormControl variant="outlined" size="small">
        <Select value={filter} onChange={handleChange} id="dripfilterselect">
          <MenuItem value="Both Items" id="filterbothitems">Both Items</MenuItem>
          <MenuItem value="E-Mail" id="filteremail">E-Mail</MenuItem>
          <MenuItem value="Agent Task" id="filteragenttask">Agent Task</MenuItem>
        </Select>
      </FormControl>
    </Grid>
    <StyledTableContainer component={Paper}>
      <Table sx={{ border: '0', borderColor: 'transparent' }} id="dripdatatable">
        <TableHead>
          <TableRow>
            {dripData.headers.map((header, index) => (
              <TableCell sx={{ color: '#0173BC', fontWeight: 'bold' }} key={index} id={`header${header.replace(/\s+/g, '-').toLowerCase()}`}>
                {header}
              </TableCell>
            ))}
          </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.map((row, index) => (
             <StyledTableRow key={index} id={`driprow${index}`}>
             <TableCell id={`activity${index}`}>{row.activity}</TableCell>
             <TableCell id={`driptype${index}`}>{row.dripType}</TableCell>
             <TableCell id={`contacttype${index}`}>{row.contactType}</TableCell>
             <TableCell id={`requestdate${index}`}>{row.requestDate}</TableCell>
             <TableCell id={`scheduledate${index}`}>{row.scheduleDate}</TableCell>
             <TableCell id={`contactdate${index}`}>{row.contactDate}</TableCell>
             <TableCell id={`closeddate${index}`}>{row.closedDate}</TableCell>
             <TableCell>
                  <IconButton id={`viewbutton${index}`}>
                    <VisibilityIcon />
                  </IconButton>
                </TableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
};

export default ViewDrip;
