import apiService from "./apiServices";
export const getcompanyService = async (token) => {
    try {
      const response = await apiService('/company-selection/', 'GET', null, null, token);
      // console.log("response",response)
      return response;
    } catch (error) {
      throw new Error(error.message || 'Failed to fetch company selection data. Please try again.');
    }
  };

  export const getcompanyOptionService = async (gpid,token) => {
    try {
      const response = await apiService(`/comparison/rate/${gpid}`, 'GET', null, null, token);
      // console.log("response",response)
      return response;
    } catch (error) {
      throw new Error(error.message || 'Failed to fetch company selection data. Please try again.');
    }
  };

  export const getcompanyDetailsService = async (gpid,token) => {
    try {
      const response = await apiService(`/company-selection/fetch/${gpid}`, 'GET', null, null, token);
      // console.log("response",response)
      return response;
    } catch (error) {
      throw new Error(error.message || 'Failed to fetch company selection data. Please try again.');
    }
  };




