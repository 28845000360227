const initialState = {
  companies: [],
  optionList:[],
  companiesList:[],
  gpId: null,
term: null,
planOptions: null,
  error: null,
};

const companyselectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'FETCH_COMPANY_SELECTION_SUCCESS':
        // console.log("Reducer success payload:", action.payload); // Debug log
        return {
        ...state,
        companies: action.payload,
        error: null,
      };
    case 'FETCH_COMPANY_SELECTION_OPTION_SUCCESS':
        // console.log("Reducer success payload:", action.payload); // Debug log
        return {
        ...state,
        optionList: action.payload,
        error: null,
      };
    case 'FETCH_COMPANY_DETAILS_SUCCESS':
        // console.log("Reducer success payload:", action.payload); // Debug log
        return {
        ...state,
        companiesList: action.payload,
        error: null,
      };
    case 'FETCH_COMPANY_METADATA_SUCCESS': // New action type
    return {
      ...state,
      gpId: action.payload.gpId,
      term: action.payload.term,
      planOptions: action.payload.planOptions,
      error: null,
    };
    case 'RESET_COMPANY_SELECTION':
      return { ...state, companiesList: [] };
    case 'FETCH_COMPANY_SELECTION_FAILURE':
        // console.log("Reducer error payload:", action.payload); // Debug log
        return {
        ...state,
        error: action.payload,
      };
    case 'FETCH_COMPANY_SELECTION_OPTION_FAILURE':
        // console.log("Reducer error payload:", action.payload); // Debug log
        return {
        ...state,
        error: action.payload,
      };
      case 'DELETE_CARRIER_GROUP':
        return { ...state, companies: action.payload, error: null };
    default:
      return state;
  }
};

export default companyselectionReducer;


// const initialState = {
//     companies: [],
//     optionList:[],
//     error: null,
//   };

//   const companyselectionReducer = (state = initialState, action) => {
//     switch (action.type) {
//       case 'FETCH_COMPANY_SELECTION_SUCCESS':
//           // console.log("Reducer success payload:", action.payload); // Debug log
//           return {
//           ...state,
//           companies: action.payload,
//           error: null,
//         };
//       case 'FETCH_COMPANY_SELECTION_OPTION_SUCCESS':
//           // console.log("Reducer success payload:", action.payload); // Debug log
//           return {
//           ...state,
//           optionList: action.payload,
//           error: null,
//         };
//       case 'FETCH_COMPANY_SELECTION_FAILURE':
//           // console.log("Reducer error payload:", action.payload); // Debug log
//           return {
//           ...state,
//           error: action.payload,
//         };
//       case 'FETCH_COMPANY_SELECTION_OPTION_FAILURE':
//           // console.log("Reducer error payload:", action.payload); // Debug log
//           return {
//           ...state,
//           error: action.payload,
//         };
//         case 'DELETE_CARRIER_GROUP':
//           return { ...state, companies: action.payload, error: null };
//       default:
//         return state;
//     }
//   };

//   export default companyselectionReducer;