import { fetchTaskSearch, updatedDashboardData, fetchFilterPriorityTaskData, fetchData, fetchAllPolicyType, fetchFilterTaskTypeData, fetchPriorityTaskData, fetchTaskTypeData, fetchPolicyTypeFilteredData, fetchDashboardPolicyData, fetchDashboardTask, fetchPolicyFilteredData, fetchQuoteFilteredData, managePolicyAllListingServices } from '../../services/dashboardServices';
import { START_LOADING, STOP_LOADING } from './actionTypes';

export const setDashboardData = (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchData(token, page, size);
      dispatch({ type: 'SET_DASHBOARD_DATA', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      dispatch({ type: 'ALL_DRIVER_INFOS_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const setDashboardPolicyData = (page = 1, rowsPerPage = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchDashboardPolicyData(token, page, rowsPerPage);
      dispatch({ type: 'SET_DASHBOARD_POLICY_DATA', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'ALL_DRIVER_INFOS_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };


  export const fetchFilteredData = (formData, page, rowsPerPage) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchPolicyFilteredData(formData, token, page, rowsPerPage);      
      dispatch({ type: 'FETCH_FILTERED_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchQuoteFilteredDataAction = (formData, page = 1, rowsPerPage = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchQuoteFilteredData(formData, token, page, rowsPerPage);      
      dispatch({ type: 'FETCH_QUOTE_FILTERED_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchTaskDataAction = (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchDashboardTask(token, page, size);      
      dispatch({ type: 'FETCH_TASK_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchTaskFilterDataAction = (formData, page, rowsPerPage) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchTaskSearch(formData, token, page, rowsPerPage);
      dispatch({ type: 'FETCH_TASK_FILTER_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchAllPolicyTypeAction = (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchAllPolicyType(token, page, size);      
      dispatch({ type: 'FETCH_ALL_POLICY_TYPE_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchPolicyTypeFilteredDataAction = (formData, page, rowsPerPage) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchPolicyTypeFilteredData(formData, token, page, rowsPerPage);      
      dispatch({ type: 'FETCH_POLICYTYPE_FILTERED_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchTaskTypeAction = (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchTaskTypeData(token, page, size);
      dispatch({ type: 'FETCH_TASK_TYPE_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchFilterTaskTypeAction = (formData, page, rowsPerPage) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchFilterTaskTypeData(formData, token, page, rowsPerPage);
      dispatch({ type: 'FETCH_FILTER_TASK_TYPE_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchPriorityTaskAction = (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchPriorityTaskData(token, page, size);      
      dispatch({ type: 'FETCH_PRIORITY_TASK_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const fetchFilterPriorityTaskAction = (formData, page, rowsPerPage) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token ||sessionStorage.getItem("token");
      const response = await fetchFilterPriorityTaskData(formData, token, page, rowsPerPage);
      dispatch({ type: 'FETCH_FILTER_PRIORITY_TASK_DATA_SUCCESS', payload: response });
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
    }
  };

  export const updatedTaskData = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'FETCH_TASK_DATA_SUCCESS' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await updatedDashboardData(formData, token);
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'UPDATE_TASK_TABLE_DATA', payload: response });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });     
    }
  };

  export const updatedPriorityTaskData = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'FETCH_PRIORITY_TASK_DATA_SUCCESS' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await updatedDashboardData(formData, token);
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'SAVE_PRIORITY_TASK_UPDATED_DATA', payload: response });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });     
    }
  };
 
  export const updatedOpenTaskData = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'FETCH_TASK_TYPE_DATA_SUCCESS' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await updatedDashboardData(formData, token);
      dispatch({ type: STOP_LOADING, service: 'FETCH_TASK_TYPE_DATA_SUCCESS' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'SAVE_OPEN_TASK_UPDATED_DATA', payload: response });
    } catch (error) {
      dispatch({ type: 'FETCH_FILTERED_DATA_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });     
    }
  };

  export const managepolicyallAction = (page = 1, rowsPerPage = 5) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'START_LOADING' });
        const token = getState().auth.token ||sessionStorage.getItem("token");
        console.log(token, 'tokentokentoken')
        const response = await managePolicyAllListingServices(token, page, rowsPerPage);
        console.log(response, 'responseresponse')
        dispatch({ type: 'MANAGE_POLICY_ALL_DATA_SUCCESSS', payload: response });
        dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      } catch (error) {
        dispatch({ type: 'ALL_DRIVER_INFOS_FAILURE', payload: error.message || 'All DriverInfos service failed. Please try again.' });
      }
    };

