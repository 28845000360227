
const initialState = {
    agencies: JSON.parse(localStorage.getItem("agencies")) || {},
    profiles: JSON.parse(localStorage.getItem("profiles")) || {},
    profilesSearch: JSON.parse(localStorage.getItem("profiles")) || {},
    carrierGroup: JSON.parse(localStorage.getItem("carriergroups")) || {},
    allCarrier: [],
    allGroupCarrier:[],
    allProfile:[],
    allSearch:[],
    allError:null,
    error: null,
    loggedInUser: []
  };

  const agencyReducer = (state = initialState, action) => {
    switch (action.type) {
      case "ALL_CARRIER_INFOS_SUCCESS":
        return { ...state, allCarrier: action.payload, error: null };

        case "UPDATE_INSURANCE_CARRIER":
        return { ...state, allCarrier: action.payload, error: null };

        case "DELETE_CARRIER_LISTING_ITEM_SUCCESS":
        return { ...state, allCarrier: action.payload, error: null };

        case "LOGGEDIN_USER_PROFILE_SUCCESS":
        return { ...state, loggedInUser: action.payload, error: null };

      case "ALL_CARRIER_GROUP_INFOS_SUCCESS":
        // console.log("ALL_GROUP_INFOS_SUCCESS", action.payload);
        return { ...state, allGroupCarrier: action.payload, error: null };
      case "ALL_PROFILE_INFOS_SUCCESS":
        return { ...state, allProfile: action.payload, error: null };
        case "DELETE_USER_PROFILE":
        return { ...state, allProfile: action.payload, error: null };
      case "SEARCH_AGENCY_SUCCESS":
        return { ...state, allSearch: action.payload, error: null };
       case "SAVE_CARRIER_GROUP_SUCCESS":
        //console.log("SAVE_CARRIER_GROUP_SUCCESS", action.payload);
        localStorage.setItem("carriergroups", JSON.stringify(action.payload));
        return { ...state, carrierGroup: action.payload, error: null };
      case "SAVE_AGENCY_SUCCESS":
        localStorage.setItem("agencies", JSON.stringify(action.payload));
        return { ...state, agencies: action.payload, error: null };
       case "SAVE_PROFILE_SUCCESS":
        localStorage.setItem("profiles", JSON.stringify(action.payload));
        return { ...state, profiles: action.payload, error: null };
       case "SAVE_PROFILE_SEARCH_SUCCESS":
        localStorage.setItem("profilesSearch", JSON.stringify(action.payload));
        return { ...state, profilesSearch: action.payload, error: null };
      case "UPDATE_CARRIER_SUCCESS":
        localStorage.setItem("agencies", JSON.stringify(action.payload));
        return { ...state, agencies: action.payload, error: null };
      case "UPDATE_PROFILE_SUCCESS":
        localStorage.setItem("profiles", JSON.stringify(action.payload));
        return { ...state, profiles: action.payload, error: null };
     case "DELETE_AGENCY_SUCCESS":
    const updatedNote = state.allNote.filter(
    (policy) => policy.id !== action.payload
     );
     return { ...state, allNote: updatedNote, error: null };
      case "ALL_AGENCY_INFOS_FAILURE":
      case "ALL_PROFILE_INFOS_FAILURE":
      case "ALL_CARRIER_INFOS_FAILURE":
      case "ALL_CARRIER_GROUP_INFOS_FAILURE":
      case "SAVE_AGENCY_FAILURE":
      case "SAVE_PROFILE_FAILURE":
        return { ...state, allError: action.payload };
      case "SAVE_PROFILE_SEARCH_FAILURE":
      case "SEARCH_AGENCY_FAILURE":
      case "SAVE_CARRIER_GROUP_FAILURE":
      case "DELETE_AGENCY_FAILURE":
      case "UPDATE_PROFILE_FAILURE":
      case "UPDATE_CARRIER_FAILURE":
        return { ...state, error: action.payload };
      default:
        return state;
    }
  };

  export default agencyReducer;
