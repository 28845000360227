import apiService from "./apiServices";

export const saveTheme = async (formData, token) => {
    try {      
      const response = await apiService('/theme/save-theme', 'POST', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Saving theme is failed. Please try again.');
      }
}

export const updateTheme = async (formData, token) => {
  try {
    
    const response = await apiService('/theme/update-theme', 'POST', formData, null, token);
    return response;
    } catch (error) {
      throw (error?.message || 'Updating theme is failed. Please try again.');
    }
}

export const getTheme = async (customerId, type, token) => {
    try {
        const response = await apiService(`/theme/find-all/customerId/${customerId}`, 'GET', null, null, token);
        return response;
      } catch (error) {
        throw (error?.message || 'service is failed. Please try again.');
      }
  }

  export const getDefaultThemeService = async (themeid, token) => {
    
    try {
    
      const response = await apiService(`/theme/get-theme/themeId/${themeid}`, 'GET',null, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating theme is failed. Please try again.');
      }
  }
  