import React, { useState } from 'react';
import { Box, StepConnector, stepConnectorClasses, StepLabel } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Check from '@mui/icons-material/Check';
import { styled } from '@mui/material/styles';

const steps = ['New', 'In progress', 'Completed'];

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    '& .${stepConnectorClasses.line}': {
      borderColor: '#4caf50',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    '& .${stepConnectorClasses.line}': {
      borderColor: '#4caf50',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 10, // Adjust height
    border: 0,
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
    borderRadius: 0,
    position: 'relative',
    top: -15
  },
}));

const StepperComponent = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [completedSteps, setCompletedSteps] = React.useState(new Set());

  const handleNext = () => {
    if (activeStep < steps.length - 1) {
      setCompletedSteps(prev => new Set(prev).add(activeStep));
      setActiveStep(prev => prev + 1);
    } else {
      setCompletedSteps(prev => new Set(prev).add(activeStep));
    }
  };

 

  const CustomStepIcon = (props) => {
    const { active, completed, icon } = props;

    return (
      <div
        style={{
          backgroundColor: completed ? '#4caf50' : active ? '#0173BC' : '#0173BC',
          zIndex: 1,
          color: '#fff',
          width: 50,
          height: 50,
          borderRadius: '50%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginBottom: '10px'
        }}
      >
        {completed ? <Check /> : icon}
      </div>
    );
  };

  const CustomStepLabel = (props) => {
    const { children, icon, active, completed } = props;

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', flexFlow: 'column', position: 'relative', marginBottom: '1rem' }}>
        <CustomStepIcon active={active} completed={completed} icon={icon} />
        <Box sx={{ fontWeight: 'bold', position: 'absolute', bottom: '-20px', minWidth: '100px', textAlign:'center' }}>
          {children}
        </Box>
      </Box>
    );
  };

  return (
    <Box sx={{ margin: '40px 0 0 0' }}>
      <Stepper activeStep={activeStep} connector={<CustomStepConnector />}>
        {steps.map((label, index) => (
          <Step key={label} completed={completedSteps.has(index)}>
            <StepLabel StepIconComponent={() => null}>
              <CustomStepLabel icon={index + 1} active={activeStep === index} completed={completedSteps.has(index)}>
                {label}
              </CustomStepLabel>
            </StepLabel>
          </Step>
        ))}
      </Stepper>      
    </Box>
  );
};

const primaryButton = {
  background: '#0173BC', 
  color: '#FFFFFF', 
  minWidth: '84px',
  '&:hover' : {
    background: '#0173BC', 
    color: '#FFFFFF', 
  }
};

export default StepperComponent;
