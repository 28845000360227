import { SAVE_EMAIL_FAILURE,SAVE_EMAIL_SUCCESS,STOP_LOADING,START_LOADING,UPDATE_EMAIL_FAILURE,UPDATE_EMAIL_SUCCESS,DELETE_EMAIL_FAILURE,DELETE_EMAIL_SUCCESS,
    GET_EMAIL_FAILURE,GET_EMAIL_SUCCESS,SAVE_SMS_SUCCESS,SAVE_SMS_FAILURE,GET_SMS_SUCCESS,GET_SMS_FAILURE,DELETE_SMS_SUCCESS,DELETE_SMS_FAILURE,SEARCH_EMAIL_SUCCESS,SEARCH_EMAIL_FAILURE,SEARCH_SMS_SUCCESS,SEARCH_SMS_FAILURE,
UPDATE_SMS_SUCCESS,UPDATE_SMS_FAILURE,GET_PHONE_FAILURE,GET_PHONE_SUCCESS,SEARCH_PHONE_SUCCESS,SEARCH_PHONE_FAILURE} from "./actionTypes";
  import { saveEmail,updateEmail,getAllEmail,deleteEmailById, saveSMS,updateSMS,
    getAllSMS,deleteSmsById, searchSMS,getAllPhones,searchPhone,searchCommunication } from "../../services/communicationServices";
  import { openSessionTimeoutModal } from './sessionTimeOutActions';


  export const saveEmailAction = (formData, callbackfn) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'SAVE_EMAIL' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await saveEmail(formData, token);
        // console.log(response);
        dispatch({ type: STOP_LOADING, service: 'SAVE_EMAIL' });
        if (response) {
          callbackfn(response);
        }
        dispatch({ type: SAVE_EMAIL_SUCCESS, payload: response }); // Dispatch the success action with response data
      } catch (error) {
        if (error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'SAVE_EMAIL' });
          dispatch({ type: SAVE_EMAIL_FAILURE, payload: error || 'Save email service failed. Please try again.' });
        }
      }
    };

    export const updateEmailAction = (formData,callbackfn) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'UPDATE_EMAIL' });
        const token = getState().auth.token || sessionStorage.getItem("token");;
        const response = await updateEmail(formData, token);
        dispatch({ type: STOP_LOADING, service: 'UPDATE_EMAIL' });
        if (response) {
          callbackfn(response);
        }
        dispatch({ type: UPDATE_EMAIL_SUCCESS, payload: response });
      } catch (error) {
        // console.log(error, 'error in action')
        if(error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'UPDATE_EMAIL' });
          dispatch({ type: UPDATE_EMAIL_FAILURE, payload: error || 'Update email service failed. Please try again.' });
        }
      }
    };

    // export const getAllEmailAction= () => async (dispatch, getState) => {
    //   try {
    //     dispatch({ type: START_LOADING, service: 'ALL_EMAIL' });
    //     const token = getState().auth.token || sessionStorage.getItem("token");
    //     const response = await getAllEmail(token);
    //     dispatch({ type: STOP_LOADING, service: 'ALL_EMAIL' });
    //     dispatch({ type: GET_EMAIL_SUCCESS, payload: response });
    //   } catch (error) {
    //     if(error?.includes("403")) {
    //       dispatch(openSessionTimeoutModal());
    //     } else {
    //       dispatch({ type: STOP_LOADING, service: 'ALL_EMAIL' });
    //       dispatch({ type: GET_EMAIL_FAILURE, payload: error || 'All task service failed. Please try again.' });
    //     }
    //   }
    // };

    export const getAllEmailAction= (page = 0, size = 5) => async (dispatch, getState) => {
      // console.log("in note action");
      try {
        dispatch({ type: START_LOADING, service: 'ALL_EMAIL' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await getAllEmail(token, page, size);
      
        console.log("response value of notes",response);
        dispatch({ type: STOP_LOADING, service: 'ALL_EMAIL' });
        dispatch({ type: GET_EMAIL_SUCCESS, payload: response });
      } catch (error) {
        if(error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'ALL_EMAIL' });
          dispatch({ type: GET_EMAIL_FAILURE, payload: error || 'All task service failed. Please try again.' });
        }
      }
    };

    export const deleteEmailActionById = (quoteId) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'ALL_EMAIL' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        // console.log("quoteid",quoteId);
        const response= await deleteEmailById(quoteId,token);
        dispatch({ type: STOP_LOADING, service: 'ALL_EMAIL' });
        // console.log("Token:", token);
        // console.log("quoteid",quoteId);

        // dispatch({ type: 'ALL_DRIVER_INFOS_SUCCESS', payload: response });
        dispatch({ type: DELETE_EMAIL_SUCCESS, payload: quoteId });
        return response;
      } catch (error) {
        dispatch({ type: STOP_LOADING, service: 'ALL_EMAIL' });
        dispatch({ type: DELETE_EMAIL_FAILURE, payload: error.message || 'All task service failed. Please try again.' });
      }
    };

    // export const searchEmailAction = (formData, callbackfn) => async (dispatch, getState) => {
    //   try {
    //     dispatch({ type: START_LOADING, service: 'SEARCH_EMAIL' });
    //     const token = getState().auth.token || sessionStorage.getItem("token");
    //     const response = await searchEmail(formData, token);
    //     // console.log(response);
    //     dispatch({ type: STOP_LOADING, service: 'SEARCH_EMAIL' });
    //     if (response) {
    //       callbackfn(response);
    //     }
    //     dispatch({ type: SEARCH_EMAIL_SUCCESS, payload: response }); // Dispatch the success action with response data
    //   } catch (error) {
    //     if (error?.includes("403")) {
    //       dispatch(openSessionTimeoutModal());
    //     } else {
    //       dispatch({ type: STOP_LOADING, service: 'SEARCH_EMAIL' });
    //       dispatch({ type: SEARCH_EMAIL_FAILURE, payload: error || 'Search note  failed. Please try again.' });
    //     }
    //   }
    // };

    export const searchAction = (formData, callbackfn) => async (dispatch, getState) => {
      try {
        dispatch({ type: START_LOADING, service: 'SEARCH_EMAIL' });
        const token = getState().auth.token || sessionStorage.getItem("token");
        const response = await searchCommunication(formData, token);
        // console.log(response);
        dispatch({ type: STOP_LOADING, service: 'SEARCH_EMAIL' });
        if (response) {
          callbackfn(response);
        }
        dispatch({ type: SEARCH_EMAIL_SUCCESS, payload: response }); // Dispatch the success action with response data
      } catch (error) {
        if (error?.includes("403")) {
          dispatch(openSessionTimeoutModal());
        } else {
          dispatch({ type: STOP_LOADING, service: 'SEARCH_EMAIL' });
          dispatch({ type: SEARCH_EMAIL_FAILURE, payload: error || 'Search note  failed. Please try again.' });
        }
      }
    };

    //SMS

    export const saveSmsAction = (formData, callbackfn) => async (dispatch, getState) => {
        try {
          dispatch({ type: START_LOADING, service: 'SAVE_SMS' });
          const token = getState().auth.token || sessionStorage.getItem("token");
          const response = await saveSMS(formData, token);
          // console.log("alert",response);
          dispatch({ type: STOP_LOADING, service: 'SAVE_SMS' });
          if (response) {
            callbackfn(response);
          }
          dispatch({ type: SAVE_SMS_SUCCESS, payload: response }); // Dispatch the success action with response data
        } catch (error) {
          if (error?.includes("403")) {
            dispatch(openSessionTimeoutModal());
          } else {
            dispatch({ type: STOP_LOADING, service: 'SAVE_SMS' });
            dispatch({ type: SAVE_SMS_FAILURE, payload: error || 'Save alert service failed. Please try again.' });
          }
        }
      };

      export const updateSmsAction = (formData,callbackfn) => async (dispatch, getState) => {
        try {
          dispatch({ type: START_LOADING, service: 'UPDATE_SMS' });
          const token = getState().auth.token || sessionStorage.getItem("token");;
          const response = await updateSMS(formData, token);
          dispatch({ type: STOP_LOADING, service: 'UPDATE_SMS' });
          if (response) {
            callbackfn(response);
          }
          dispatch({ type: UPDATE_SMS_SUCCESS, payload: response });
        } catch (error) {
          // console.log(error, 'error in action')
          if(error?.includes("403")) {
            dispatch(openSessionTimeoutModal());
          } else {
            dispatch({ type: STOP_LOADING, service: 'UPDATE_SMS' });
            dispatch({ type: UPDATE_SMS_FAILURE, payload: error || 'Update email service failed. Please try again.' });
          }
        }
      };

      // export const getAllSmsAction= () => async (dispatch, getState) => {
      //   try {
      //     dispatch({ type: START_LOADING, service: 'ALL_SMS' });
      //     const token = getState().auth.token || sessionStorage.getItem("token");
      //     const response = await getAllSMS(token);
      //     dispatch({ type: STOP_LOADING, service: 'ALL_SMS' });
      //     dispatch({ type: GET_SMS_SUCCESS, payload: response });
      //   } catch (error) {
      //     if(error?.includes("403")) {
      //       dispatch(openSessionTimeoutModal());
      //     } else {
      //       dispatch({ type: STOP_LOADING, service: 'ALL_SMS' });
      //       dispatch({ type: GET_SMS_FAILURE, payload: error || 'All task service failed. Please try again.' });
      //     }
      //   }
      // };

      export const getAllSmsAction= (page = 0, size = 5) => async (dispatch, getState) => {
        // console.log("in note action");
        try {
          dispatch({ type: START_LOADING, service: 'ALL_SMS' });
          const token = getState().auth.token || sessionStorage.getItem("token");
          const response = await getAllSMS(token, page, size);
          // console.log("response value of sms",response);
          dispatch({ type: STOP_LOADING, service: 'ALL_SMS' });
          dispatch({ type: GET_SMS_SUCCESS, payload: response });
        } catch (error) {
          if(error?.includes("403")) {
            dispatch(openSessionTimeoutModal());
          } else {
            dispatch({ type: STOP_LOADING, service: 'ALL_SMS' });
            dispatch({ type: GET_SMS_FAILURE, payload: error || 'All task service failed. Please try again.' });
          }
        }
      };

      export const deleteSmsActionById = (quoteId) => async (dispatch, getState) => {
        try {
          dispatch({ type: START_LOADING, service: 'ALL_SMS' });
          const token = getState().auth.token || sessionStorage.getItem("token");
          // console.log("quoteid",quoteId);
          const response= await deleteSmsById(quoteId,token);
          dispatch({ type: STOP_LOADING, service: 'ALL_SMS' });
          // console.log("Token:", token);
          // console.log("quoteid",quoteId);

          // dispatch({ type: 'ALL_DRIVER_INFOS_SUCCESS', payload: response });
          dispatch({ type: DELETE_SMS_SUCCESS, payload: quoteId });
          return response;
        } catch (error) {
          dispatch({ type: STOP_LOADING, service: 'ALL_SMS' });
          dispatch({ type: DELETE_SMS_FAILURE, payload: error.message || 'All task service failed. Please try again.' });
        }
      };

      export const searchSmsAction = (formData, callbackfn) => async (dispatch, getState) => {
        try {
          dispatch({ type: START_LOADING, service: 'SEARCH_SMS' });
          const token = getState().auth.token || sessionStorage.getItem("token");
          const response = await searchSMS(formData, token);
          // console.log(response);
          dispatch({ type: STOP_LOADING, service: 'SEARCH_SMS' });
          if (response) {
            callbackfn(response);
          }
          dispatch({ type: SEARCH_SMS_SUCCESS, payload: response }); // Dispatch the success action with response data
        } catch (error) {
          if (error?.includes("403")) {
            dispatch(openSessionTimeoutModal());
          } else {
            dispatch({ type: STOP_LOADING, service: 'SEARCH_SMS' });
            dispatch({ type: SEARCH_SMS_FAILURE, payload: error || 'Search note  failed. Please try again.' });
          }
        }
      };
//Phone

export const getAllPhoneAction= (page = 0, size = 5) => async (dispatch, getState) => {
    // console.log("in note action");
    try {
      dispatch({ type: START_LOADING, service: 'ALL_PHONE' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await getAllPhones(token, page, size);
      // console.log("response value of phones in action",response);
      dispatch({ type: STOP_LOADING, service: 'ALL_PHONE' });
      dispatch({ type: GET_PHONE_SUCCESS, payload: response });
    } catch (error) {
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'ALL_PHONE' });
        dispatch({ type: GET_PHONE_FAILURE, payload: error || 'All task service failed. Please try again.' });
      }
    }
  };

export const searchPhoneAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SEARCH_PHONE' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await searchPhone(formData, token);
      // console.log(response);
      dispatch({ type: STOP_LOADING, service: 'SEARCH_PHONE' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: SEARCH_PHONE_SUCCESS, payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SEARCH_PHONE' });
        dispatch({ type: SEARCH_PHONE_FAILURE, payload: error || 'Search note  failed. Please try again.' });
      }
    }
  };
