import React,{useState, useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import ToggleButton from  './../../assets/toggle-btn-icon.svg';
import ExpandIcon from  './../../assets/expand-icon.svg';
import { getThemeAction } from '../../redux/actions/themeActions';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import AttributionIcon from '@mui/icons-material/Attribution';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import Diversity2Icon from '@mui/icons-material/Diversity2';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import ContactsIcon from '@mui/icons-material/Contacts';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SummarizeIcon from '@mui/icons-material/Summarize';
import SettingsIcon from '@mui/icons-material/Settings';
import { openCreateOrganizationModal as openCreateOrganizationModalAction } from '../../redux/actions/organizationActions';
import {  openCreateUserModal as openCreateUserModalAction } from '../../redux/actions/userActions';
import { openCreateCustomerModal as openCreateCustomerModalAction } from '../../redux/actions/customerActions';
import { Link } from '@mui/material';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});



const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function MiniDrawer() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.user);
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState(null); 

  const handleDrawerOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if(user?.customer?.id) {
        dispatch(getThemeAction(user?.customer?.id))
    }
}, [user])

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (index) => {
    if (selectedMenu === index) {
      setSelectedMenu(null); // Close the submenu if already open
    } else {
      setSelectedMenu(index); // Open the clicked submenu
    }
  };

  const sideBarMenuData = [
    {
      main: 'Lead',
      subMenu: [{
        subMenuItem: 'Create Lead',
        subMenuUrl: () => navigate('/createLead'),
      },
      {
        subMenuItem: 'All Leads',
        subMenuUrl: () => navigate('/allLeads'),
      },
      {
        subMenuItem: 'My Leads',
        subMenuUrl:  () => navigate('/myLeads'),
      }],
      icon: <AttributionIcon />
    },
    {
      main: 'Organizations',
      subMenu: [{
        subMenuItem: 'Create Organization',
        subMenuUrl: () => dispatch(openCreateOrganizationModalAction()),
      },
      {
        subMenuItem: 'All Organization',
        subMenuUrl: () => navigate('/organizations'),
      }],
      icon: <Diversity3Icon />
    },
    {
      main: 'Customers',
      subMenu: [{
        subMenuItem: 'Create Customer',
        subMenuUrl: () => dispatch(openCreateCustomerModalAction()),
      },
      {
        subMenuItem: 'All Customers',
        subMenuUrl: () => navigate('/customers'),
      }],
      icon: <SupportAgentIcon />
    },
    {
      main: 'Teams',
      subMenu: [{
        subMenuItem: 'Create Team',
        subMenuUrl: () => dispatch(openCreateUserModalAction()),
      },
      {
        subMenuItem: 'All Team',
        subMenuUrl: () => navigate('/teams'),
      }],
      icon: <Diversity2Icon />
    },
    {
      main: 'Accounts',
      subMenu: [{
        subMenuItem: 'All Accounts',
        subMenuUrl: () => navigate('/allaccount'),
      },
      {
        subMenuItem: 'My Accounts',
        subMenuUrl: () => navigate('/myAccounts'),
      }],
      icon: <AccountBalanceIcon />
    },
    {
      main: 'Contacts',
      subMenu: [{
        subMenuItem: 'Contact 1',
        subMenuUrl: () => {},
      },
      {
        subMenuItem: 'Contact 2',
        subMenuUrl: () => {},
      }],
      icon: <ContactsIcon />
    },
    {
      main: 'Calendar',
      subMenu: [{
        subMenuItem: 'Calendar',
        subMenuUrl: () => {},
      },
      {
        subMenuItem: 'Calendar 1',
        subMenuUrl: () => {},
      }],
      icon: <CalendarMonthIcon />
    },
    {
      main: 'Dashboards',
      subMenu: [{
        subMenuItem: 'Dashboard 1',
        subMenuUrl: () => {},
      },
      {
        subMenuItem: 'Dashboards 2',
        subMenuUrl: () => {},
      }],
      icon: <DashboardIcon />
    },
    {
      main: 'Reports',
      subMenu: [{
        subMenuItem: 'Report 1',
        subMenuUrl: () => {},
      },
      {
        subMenuItem: 'Report 2',
        subMenuUrl: () => {},
      }],
      icon: <SummarizeIcon />
    },
    {
      main: 'Settings',
      subMenu: [{
        subMenuItem: 'Create Team',
        subMenuUrl: () => dispatch(openCreateUserModalAction()),
      },
      {
        subMenuItem: 'All Team',
        subMenuUrl: () => navigate('/settings'),
      }],
      // subMenu: [{
      //   subMenuItem: 'Settings',
      //   subMenuUrl: () => navigate('/settings'),
      // },{
      //   subMenuItem: 'Custom Theme',
      //   subMenuUrl: () => navigate('/customizeThemeSettings')
      // },
      // {
      //   subMenuItem: 'Roles',
      //   subMenuUrl: () => navigate('/roles'),
      // },
      // {
      //   subMenuItem: 'Permissions',
      //   subMenuUrl: () => navigate('/permissions')
      // },
      // {
      //   subMenuItem: 'Create Dropdown',
      //   subMenuUrl: () => navigate('/createDropdown')
      // }],
      icon: <SettingsIcon />
    }
  ];

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>            
    <Box sx={{
          left: open ? '230px' : '50px',
          zIndex: '9999',
          top: '75px',
          position: 'fixed',
          transition: 'left 0.2s ease-in-out',
        }} className='border-0 z-50' onClick={handleDrawerOpen}>
          <img src={open ? ToggleButton : ExpandIcon} alt='button' />
        </Box>
      <Drawer variant="permanent" sx={{overflow: 'visible'}} open={open}>                
        <List>
          {sideBarMenuData.map(({ main, subMenu, icon }, index) => (
            <div key={main}>
              <ListItem disablePadding onClick={() => handleMenuClick(index)}>
                {<ListItemButton                
                  sx={{
                    color: 'white',
                    minHeight: 48,
                    justifyContent: open ? 'initial' : 'center',
                    px: 2.5,
                  }}
                >{icon && (
                  <ListItemIcon
                    color="primary"
                    sx={{
                      color: 'white',
                      minWidth: 0,
                      mr: 1,
                      justifyContent: 'center',
                    }}
                  >
                    {icon}
                  </ListItemIcon>
                )}
                  <ListItemText primary={main} sx={{ opacity: open ? 1 : 0 }} />
                  {open ? (
                    <ListItemIcon
                      sx={{
                        color:'white',
                        minWidth: 0,
                        mr: 0,
                        justifyContent: 'center',
                      }}
                    >
                      {selectedMenu === index ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </ListItemIcon>
                  ) : ''}
                </ListItemButton>}
              </ListItem>
              {open && selectedMenu === index && (
                <List>
                {subMenu.map(({ subMenuItem, subMenuUrl }, subIndex) => (
                  <ListItem key={subIndex} disablePadding sx={{ display: 'block', pl: 4 }}>
                    <ListItemButton>
                      <ListItemText>
                        <Link
                          sx={{
                            justifyContent: 'initial',
                            color: '#CCCCCC',
                            fontSize: '14px'
                          }}
                          onClick={subMenuUrl}
                          href={() => {}}
                        >
                          {subMenuItem}
                        </Link>
                      </ListItemText>
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              )}
            </div>
          ))}
        </List>
      </Drawer>
    </Box>
  );
}
