import React from 'react';
import {  useForm, FormProvider } from 'react-hook-form';
import { Grid, Typography, AppBar, Tabs, Tab, Box } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import PolicyInfo from './PolicyInfo';
import Task from './Task';
import Communication from './Communication';
import Activity from './Activity';
import PoliciesList from '../../notes/PoliciesList';
import Attachments from '../../attachments/Attachments';
import { styled } from "@mui/material/styles";

const poppinsFont = {
  fontFamily: '"Poppins", sans-serif',
};

const PolicyDetails = ({ policyId, isRowClicked, selectedPolicyDetail }) => {
  
  const [value, setValue] = React.useState(0);
  const methods = useForm();
  const selectedRowId = selectedPolicyDetail ? selectedPolicyDetail[0]?.id : policyId;  
  const jsonData = {
    policyNumber: selectedPolicyDetail ? selectedPolicyDetail[0].policyNumber : "UAD381140",
    policyType: selectedPolicyDetail ? selectedPolicyDetail[0].policyType : "New Business",
    name: selectedPolicyDetail ? selectedPolicyDetail[0].name :"Jennifer Bravo",
    companyName: selectedPolicyDetail ? selectedPolicyDetail[0].company : "PROGRESSIVE FL",
    email: selectedPolicyDetail ? selectedPolicyDetail[0].email : "jennifer@biziatek.com",
    phone: selectedPolicyDetail ? selectedPolicyDetail[0].phone: "124241122",
    effDate: selectedPolicyDetail ? selectedPolicyDetail[0].effDate : "06-27-2023",
    expDate: selectedPolicyDetail ? selectedPolicyDetail[0].expDate : "01-27-2024",
    status: selectedPolicyDetail ? selectedPolicyDetail[0].status : "Active",
    nextActivity: selectedPolicyDetail ? selectedPolicyDetail[0].nextActivity : "Policy Cancellation",
    policyPremium: selectedPolicyDetail ? selectedPolicyDetail[0].premium : "$1765"
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper elevation={3} style={{ padding: 20, ...poppinsFont }} id="policydetailspaper" sx={{ boxShadow: 'none', backgroundColor: '#ffffff' }}>
      <Grid container spacing={2} >
        {!isRowClicked && (
          <Grid item xs={12} >
            <TableContainer component={Paper} id="policydetailstablecontainer" sx={{ poppinsFont, boxShadow: '0' }}>
              <Table id="policydetailstable" sx={{ poppinsFont, backgroundColor: '#F8F8F8', boxShadow: 'none' }}>
                <TableHead>
                  <TableRow>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Policy Number</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Policy Type</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Name</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Company Name</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Email</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Phone</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Eff Date</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Exp Date</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Status</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Next Activity</TableCell>
                    <TableCell className="styled-table-heading" sx={poppinsFont}>Policy Premium</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow className="styled-table-row">
                    <TableCell sx={poppinsFont}>{jsonData.policyNumber}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.policyType}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.name}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.companyName}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.email}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.phone}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.effDate}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.expDate}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.status}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.nextActivity}</TableCell>
                    <TableCell sx={poppinsFont}>{jsonData.policyPremium}</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              
            </TableContainer>
          </Grid>
        )}
        <Grid item xs={12}>
          <Box className="policy-detail-main-container">
        <AppBar position="static" id="policydetailsappbar" sx={{marginTop:'-15px'}}>
            <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
              <Tab label="Policy Details" sx={poppinsFont} id="tabpolicydetails" />
              <Tab label="Notes" sx={poppinsFont} id="tabnotes" />
              <Tab label="Task" sx={poppinsFont} id="tabtask" />
              <Tab label="Communication" sx={poppinsFont} id="tabcommunication" />
              <Tab label="Policy Documents" sx={poppinsFont} id="tabpolicydocuments" />
              <Tab label="Activity" sx={poppinsFont} id="tabactivity" />
            </Tabs>
          </AppBar>
          <TabPanel value={value}  index={0} id="policyinfotabpanel">
            <PolicyInfo className="policy-detail-main-container" policyId={selectedRowId}  />
          </TabPanel>
          <TabPanel value={value} index={1} id="notestabpanel">
            <FormProvider  className="policy-detail-main-container" {...methods}>
              <PoliciesList />
            </FormProvider>
          </TabPanel>
          <TabPanel value={value} index={2} id="tasktabpanel">
            <Task className="policy-detail-main-container" />
          </TabPanel>
          <TabPanel value={value} index={3} id="communicationtabpanel">
            <Communication className="policy-detail-main-container" />
          </TabPanel>
          <TabPanel value={value} index={4} id="attachmentstabpanel">
            <Attachments className="policy-detail-main-container" />
          </TabPanel>
          <TabPanel value={value} index={5} id="activitytabpanel">
            <Activity className="policy-detail-main-container" />
          </TabPanel>
          </Box>
        </Grid>
      </Grid>
    </Paper>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simpletabpanel${index}`}
      aria-labelledby={`simpletab${index}`}
      {...other}
      style={poppinsFont} 
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography sx={poppinsFont}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default PolicyDetails;
