import apiService from "./apiServices";

export const saveTask = async(formData, token) => {
    try {

        console.log(formData, 'formData, for task');
        const response = await apiService('/addtask/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving task is failed. Please try again.');
        }
  }

  export const searchTask = async(formData, token) => {
    try {

        console.log(formData, 'formData, for task');
        const response = await apiService('/addtask/search', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Search task is failed. Please try again.');
        }
  }

  export const updateTask = async (formData, token) => {
    try {

      const response = await apiService('/addtask/update', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating task is failed. Please try again.');
      }
  }

  export const getAllTask = async (token, page, size) => {
    try {

      const response = await apiService(`/addtask/all?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  export const deleteTaskById = async (id, token) => {
    try {
      // const headers = {
      //   'Access-Control-Request-Headers': '*'
      // };
      console.log("id",id);
      const response = await apiService(`/addtask/delete/${id}`, 'DELETE', null, null, token);
      console.log("response",response)
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }