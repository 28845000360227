import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Typography,
  TextField,
  Button,
  Grid,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  InputLabel,
  Box

} from "@mui/material";
import { format } from "date-fns";
import { useNavigate } from 'react-router-dom';
import {
  searchQuoteAction,
  getQuotelisting,
} from "../../redux/actions/quotelistingActions";
import { getGeneralPartyInfoByIdDashboard } from "../../redux/actions/generalPartyInfoActions";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { Label } from "@mui/icons-material";
import { padding } from "polished";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const tableHeaders = [
  { id: "quotenumber", label: "Quote Number" },
  { id: "policynumber", label: "Policy Number" },
  { id: "name", label: "Name" },
  { id: "dateofbirth", label: "Date Of Birth" },
  { id: "phonenumber", label: "Phone Number" },
  { id: "licensenumber", label: "License Number" },
  { id: "effdate", label: "Effective Date" },
  { id: "expdate", label: "Expiry Date" },
];


const QuoteListing = () => {
  const quotes = useSelector((state) => state.quotelisting.allQuotes);
  const searchQuotes = useSelector((state) => state.quotelisting.searchQuotes);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // console.log("quotes",quotes.quotesList.length);
  // console.log("quotes",quotes.totalCount);
  const TotalCount=quotes.totalCount;

  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [quoteData, setQuoteData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [quoteToFetch, setQuoteToFetch] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const generalData = useSelector(
    (state) => state.generalPartyInfo.allGeneralPartyInfoDash
  );


  useEffect(() => {
    if (quoteToFetch) {
      dispatch(getGeneralPartyInfoByIdDashboard(quoteToFetch));
    }
  }, [quoteToFetch, dispatch]);

  useEffect(() => {
    if (generalData && Object.keys(generalData).length > 0) {
      navigate("/mainview", { state: { generalData } });
    }
  }, [generalData, navigate]);


  const handleRowClickQuotes = (quoteNumber) => {
    console.log("quoteNumber",quoteNumber)
    if (!quoteNumber) {
      console.error("quoteNumber is undefined or missing.");
      return;
    }
    const numericPart = quoteNumber.replace(/^Q0*/, ""); // Remove leading 'Q' and zeros

    setQuoteToFetch(numericPart);
  };

  useEffect(() => {
    dispatch(getQuotelisting(page, rowsPerPage));
  }, [dispatch, page, rowsPerPage]);

  useEffect(() => {
    if (hasSearched) {
      setQuoteData(getQuoteData(searchQuotes));
    } else {
      setQuoteData(getQuoteData(quotes.quotesList));
    }
  }, [quotes, searchQuotes, hasSearched]);

  const handleSearch = () => {
    dispatch(searchQuoteAction({ fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
      toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search }, () => {
      setHasSearched(true);
    }));
  };

  const resetSearch = () => {
    setHasSearched(false);
    setSearch("");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // const handleChangeRowsPerPage = (event) => {
  //   setRowsPerPage(+event.target.value);
  //   setPage(0);
  // };

  const handleChangeRowsPerPage = (event) => {

    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const formatDate = (date) => {
    if (!date) return "";
    return format(new Date(date), 'MM-dd-yyyy');
  };
  return (
    <Box sx={{padding: '0 50px'}}>
    <Paper
      elevation={3}
      sx={{        
        boxShadow: 'none',
        marginTop: '25px'
      }}
    >
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <Grid
          container
          spacing={2}
          sx={{
            display: 'flex',
            alignItems: 'center'
          }}
        >
          {/* Title */}
          <Grid item xs={12}>
            <Typography
              variant="h5"
              className="page-title"
              sx={{fontWeight: '500'}}
            >
              Search Quotes
            </Typography>
          </Grid>

          <Grid item xs={12} sm={2}>
  <InputLabel
    sx={{
      color: 'black', // Set text color to black
      fontWeight: 'bold', // Optional: make it bold
    }}
  >
    From Date
  </InputLabel>
  <DatePicker
    value={fromDate ? dayjs(fromDate) : null}
    onChange={(newDate) => setFromDate(newDate ? newDate.toISOString() : null)}
    format="MM-DD-YYYY"
    placeholder="MM-DD-YYYY"
    slotProps={{
      textField: {
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        placeholder: 'MM-DD-YYYY',
        sx: {
          '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
            },
            minHeight: '55px',
            lineHeight: '45px',
          },
        },
      },
    }}
  />
</Grid>

<Grid item xs={12} sm={2}>
  <InputLabel
    sx={{
      color: 'black', // Set text color to black
      fontWeight: 'bold', // Optional: make it bold
    }}
  >
    To Date
  </InputLabel>
  <DatePicker
    value={toDate ? dayjs(toDate) : null}
    onChange={(newDate) => setToDate(newDate ? newDate.toISOString() : null)}
    format="MM-DD-YYYY"
    placeholder="MM-DD-YYYY"
    slotProps={{
      textField: {
        fullWidth: true,
        variant: 'outlined',
        size: 'small',
        placeholder: 'MM-DD-YYYY',
        sx: {
          '& .MuiOutlinedInput-root': {
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'primary.main',
            },
            minHeight: '55px',
            lineHeight: '45px',
          },
        },
      },
    }}
  />
</Grid>


          {/* Search Term */}
          <Grid item xs={12} sm={2}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder="Enter search term"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
              sx={{
                '& .MuiOutlinedInput-root': {
                  '&:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'primary.main',
                  },
                   minHeight: '55px',
                   lineHeight: '45px',
                   marginTop: '20px'
                },
              }}
            />
          </Grid>

          {/* Buttons */}
          <Grid
            item
            xs={3}
            sx={{
              display: 'flex',
              gap: 2,
              mt: 2
            }}
          >
            <Button
              variant="contained"
              color="primary"
              size="medium"
              onClick={handleSearch}
              className="btn-primary"
            >
              Search
            </Button>
            <Button
              variant="outlined"
              color="secondary"
              size="medium"
              onClick={resetSearch}
              className="btn-secondary"
            >
              Reset
            </Button>
          </Grid>
        </Grid>
      </LocalizationProvider>
    </Paper>
    <h4 sx={{ fontWeight: "bold", fontSize: '22px', mt: 5, ml: 2 }}>Quotes List</h4>
      <TableContainer component={Paper}>
        
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              {tableHeaders.map((header) => (
                <TableCell className="styled-table-heading" key={header.id} align={header.align || "left"} sx={{ fontWeight: "bold" }}>
                  {header.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {quoteData.map((row) => (
                <TableRow className="styled-table-row" key={row.quoteNumber}  onClick={() => handleRowClickQuotes(row.quoteNumber)}>
                {/* <StyledTableCell>{row.quoteNumber}</StyledTableCell> */}
                <TableCell>
                  {/* <Link to={`/quote/7`} style={{ textDecoration: 'none', color: 'inherit' }}> */}
                    {row.quoteNumber}
                  {/* </Link> */}
                </TableCell>
                <TableCell>{row.policyNumber}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{formatDate(row.dateOfBirth)}</TableCell>
                <TableCell>{row.phoneNumber}</TableCell>
                <TableCell>{row.licenseNumber}</TableCell>
                <TableCell>{formatDate(row.effDate)}</TableCell>
                <TableCell>{formatDate(row.expDate)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={TotalCount}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          // rowsPerPageOptions={[3, 6, 9]}
          // rowsPerPageOptions={[5, 10, 25]}
          rowsPerPageOptions={TotalCount === 0 ? [] : [5, 10, 15,25]}
        />
      </TableContainer>
    </Box>
  );
};

export default QuoteListing;

const getQuoteData = (quotes) => {
  if (!Array.isArray(quotes)) {
    return [];
  } else {
    return quotes;
  }
};






// const QuoteListing = () => {
//   const quotes = useSelector((state) => state.quotelisting.allQuotes);
//   const searchQuotes = useSelector((state) => state.quotelisting.searchQuotes);
//   const dispatch = useDispatch();

//   const [search, setSearch] = useState("");
//   const [quoteData, setQuoteData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);

//   useEffect(() => {
//     dispatch(getQuotelisting());
//   }, [dispatch]);

//   useEffect(() => {
//     if (hasSearched) {
//       setQuoteData(getQuoteData(searchQuotes));
//     } else {
//       setQuoteData(getQuoteData(quotes));
//     }
//   }, [quotes, searchQuotes, hasSearched]);

//   const handleSearch = () => {
//     dispatch(searchQuoteAction({ search }, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//   };

//   return (
//     <>
//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold" }}>Search Quotes</Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Enter search term"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth variant="contained" color="primary" onClick={handleSearch}>
//             Search
//           </Button>
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth variant="contained" color="secondary" onClick={resetSearch}>
//             Reset
//           </Button>
//         </Grid>
//       </Grid>
//       <TableContainer component={Paper}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold", mt: 5, ml: 2 }}>Quotes List</Typography>
//         </Grid>
//         <Table sx={{ minWidth: 700 }} aria-label="customized table">
//           <TableHead>
//             <TableRow>
//               {tableHeaders.map((header) => (
//                 <StyledTableCell key={header.id} align={header.align || "left"} sx={{ fontWeight: "bold" }}>
//                   {header.label}
//                 </StyledTableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {quoteData.map((row) => (
//               <StyledTableRow key={row.quoteNumber}>
//                 <StyledTableCell>{row.quoteNumber}</StyledTableCell>
//                 <StyledTableCell>{row.policyNumber}</StyledTableCell>
//                 <StyledTableCell>{row.name}</StyledTableCell>
//                 <StyledTableCell>{row.dateOfBirth}</StyledTableCell>
//                 <StyledTableCell>{row.phoneNumber}</StyledTableCell>
//                 <StyledTableCell>{row.licenseNumber}</StyledTableCell>
//                 <StyledTableCell>{row.effDate}</StyledTableCell>
//                 <StyledTableCell>{row.expDate}</StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>
//     </>
//   );
// };

// export default QuoteListing;

// const getQuoteData = (quotes) => {
//   if (!Array.isArray(quotes)) {
//     return [];
//   } else {
//     return quotes;
//   }
// };

// const QuoteListing = () => {
//   const quotes = useSelector((state) => state.quotelisting.allQuotes);
//   const searchQuotes = useSelector((state) => state.quotelisting.searchQuotes);
//   const dispatch = useDispatch();

//   const [search, setSearch] = useState("");
//   const [quoteData, setQuoteData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(2);

//   useEffect(() => {
//     dispatch(getQuotelisting(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     if (hasSearched) {
//       setQuoteData(getQuoteData(searchQuotes));
//     } else {
//       setQuoteData(getQuoteData(quotes));
//     }
//   }, [quotes, searchQuotes, hasSearched]);

//   const handleSearch = () => {
//     dispatch(searchQuoteAction({ search }, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   return (
//     <>
//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold" }}>Search Quotes</Typography>
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             fullWidth
//             variant="outlined"
//             placeholder="Enter search term"
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//           />
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth variant="contained" color="primary" onClick={handleSearch}>
//             Search
//           </Button>
//         </Grid>
//         <Grid item xs={3}>
//           <Button fullWidth variant="contained" color="secondary" onClick={resetSearch}>
//             Reset
//           </Button>
//         </Grid>
//       </Grid>
//       <TableContainer component={Paper}>
//         <Grid item xs={12}>
//           <Typography sx={{ fontWeight: "bold", mt: 5, ml: 2 }}>Quotes List</Typography>
//         </Grid>
//         <Table sx={{ minWidth: 700 }} aria-label="customized table">
//           <TableHead>
//             <TableRow>
//               {tableHeaders.map((header) => (
//                 <StyledTableCell key={header.id} align={header.align || "left"} sx={{ fontWeight: "bold" }}>
//                   {header.label}
//                 </StyledTableCell>
//               ))}
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {quoteData.map((row) => (
//               <StyledTableRow key={row.quoteNumber}>
//                 <StyledTableCell>{row.quoteNumber}</StyledTableCell>
//                 <StyledTableCell>{row.policyNumber}</StyledTableCell>
//                 <StyledTableCell>{row.name}</StyledTableCell>
//                 <StyledTableCell>{row.dateOfBirth}</StyledTableCell>
//                 <StyledTableCell>{row.phoneNumber}</StyledTableCell>
//                 <StyledTableCell>{row.licenseNumber}</StyledTableCell>
//                 <StyledTableCell>{row.effDate}</StyledTableCell>
//                 <StyledTableCell>{row.expDate}</StyledTableCell>
//               </StyledTableRow>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           component="div"
//           count={quoteData.length}
//           page={page}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </TableContainer>
//     </>
//   );
// };

// export default QuoteListing;

// const getQuoteData = (quotes) => {
//   if (!Array.isArray(quotes)) {
//     return [];
//   } else {
//     return quotes;
//   }
// };