import { activeProfileSearch, deleteUserService, deleteCarrierListingById, deleteCarrierById, updateInsuranceCarrier ,getCarrier, getGroupCarrier, getProfile, getProfileUser, saveCarrier, saveCarrierGroup, saveProfile, searchAgency, updateCarrier, updateProfile } from "../../services/agencyServices";
import { updateUser } from "../../services/userServices";
import { STOP_LOADING,START_LOADING } from "./actionTypes";
import { openSessionTimeoutModal } from './sessionTimeOutActions';
export const saveCarrierAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SAVE_AGENCY' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await saveCarrier(formData, token);
      // console.log(response);
      dispatch({ type: STOP_LOADING, service: 'SAVE_AGENCY' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'SAVE_AGENCY_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'SAVE_AGENCY' });
        dispatch({ type: 'SAVE_AGENCY_FAILURE', payload: error || 'Save agency service failed. Please try again.' });
    }
  };

  export const saveCarrierGroupAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SAVE_CARRIERGROUP' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await saveCarrierGroup(formData, token);
      // console.log(response);
      dispatch({ type: STOP_LOADING, service: 'SAVE_CARRIERGROUP' });
      // if (response) {
      //   callbackfn(response);
      // }
      dispatch({ type: 'SAVE_CARRIER_GROUP_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SAVE_CARRIERGROUP' });
        dispatch({ type: 'SAVE_CARRIER_GROUP_FAILURE', payload: error || 'Save carrier group service failed. Please try again.' });
      }
    }
  };

  export const getCarrierAction = (page =0, rowsPerPage=5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_CARRIER_INFOS' });
      const token = getState().auth.token||sessionStorage.getItem("token");
      // console.log("token in general",token);
      const response = await getCarrier(token, page, rowsPerPage);
      // console.log("agency data in action",response);
      dispatch({ type: STOP_LOADING, service: 'ALL_CARRIER_INFOS' });
      dispatch({ type: 'ALL_CARRIER_INFOS_SUCCESS', payload: response });
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'ALL_CARRIER_INFOS' });
      dispatch({ type: 'ALL_CARRIER_INFOS_FAILURE', payload: error.message || 'All agency service failed. Please try again.' });
    }
  };

  export const getGroupCarrierAction = (page = 1, size = 5) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'ALL_CARRIER_INFOS' });
      const token = getState().auth.token||sessionStorage.getItem("token");
      // console.log("token in general",token);
      const response = await getGroupCarrier(token, page, size);
      // console.log("agency data in action",response);
      dispatch({ type: STOP_LOADING, service: 'ALL_CARRIER_INFOS' });
      dispatch({ type: 'ALL_CARRIER_GROUP_INFOS_SUCCESS', payload: response });
    } catch (error) {
      dispatch({ type: STOP_LOADING, service: 'ALL_CARRIER_INFOS' });
      dispatch({ type: 'ALL_CARRIER_GROUP_INFOS_FAILURE', payload: error.message || 'All agency service failed. Please try again.' });
    }
  };

  export const searchAgencyAction = (formData, callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'SEARCH_AGENCY' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await searchAgency(formData, token);
      // console.log(response);
      dispatch({ type: STOP_LOADING, service: 'SEARCH_AGENCY' });
      if (response) {
        callbackfn(response);
      }
      dispatch({ type: 'SEARCH_AGENCY_SUCCESS', payload: response }); // Dispatch the success action with response data
    } catch (error) {
      if (error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'SEARCH_AGENCY' });
        dispatch({ type: 'SEARCH_AGENCY_FAILURE', payload: error || 'Save agency service failed. Please try again.' });
      }
    }
  };



  export const updateCarrierAction = (formData,callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'UPDATE_CARRIER' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await updateCarrier(formData, token);
      dispatch({ type: STOP_LOADING, service: 'UPDATE_CARRIER' });
      
      dispatch({ type: 'UPDATE_CARRIER_SUCCESS', payload: response });
    } catch (error) {
      // console.log(error, 'error in action')
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'UPDATE_CARRIER' });
        dispatch({ type: 'UPDATE_CARRIER_FAILURE', payload: error || 'Update agency service failed. Please try again.' });
      }
    }
  };

  export const updateInsuranceCarrierAction = (formData,callbackfn) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      const response = await updateInsuranceCarrier(formData, token);
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      
      dispatch({ type: 'UPDATE_INSURANCE_CARRIER', payload: response });
    } catch (error) {
      // console.log(error, 'error in action')
      if(error?.includes("403")) {
        dispatch(openSessionTimeoutModal());
      } else {
        dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
        dispatch({ type: 'UPDATE_CARRIER_FAILURE', payload: error || 'Update agency service failed. Please try again.' });
      }
    }
  };


  export const deleteCarrierByIdAction = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      await deleteCarrierById(id, token); // API call to delete the group
      dispatch({ type: 'DELETE_CARRIER_GROUP', payload: id });

      dispatch({ type: 'STOP_LOADING' }); // Stop loading after fetch is done
    } catch (error) {
      dispatch({ type: 'GET_LOCATION_GROUPS_FAILURE', payload: error || 'Failed to delete group. Please try again.' });
      dispatch({ type: 'STOP_LOADING' }); // Stop loading on error as well
    }
  };

  export const deleteCarrierListingByIdAction = (id) => async (dispatch, getState) => {
    try {
      dispatch({ type: START_LOADING, service: 'START_LOADING' });
      const token = getState().auth.token || sessionStorage.getItem("token");
      await deleteCarrierListingById(id, token); // API call to delete the group
      dispatch({ type: 'DELETE_CARRIER_LISTING_ITEM_SUCCESS', payload: id });

      dispatch({ type: 'STOP_LOADING' }); // Stop loading after fetch is done
    } catch (error) {
      dispatch({ type: 'DELETE_CARRIER_LISTING_ITEM_FAILURE', payload: error || 'Failed to delete group. Please try again.' });
      dispatch({ type: 'STOP_LOADING' }); // Stop loading on error as well
    }
  };

export const saveProfileAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'SAVE_PROFILE' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await saveProfile(formData, token);
    dispatch({ type: STOP_LOADING, service: 'SAVE_PROFILE' });
    if (response) {
      callbackfn(response);  // Success callback
    }
    dispatch({ type: 'SAVE_PROFILE_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'SAVE_PROFILE' });


      // console.log("response",error)
      const errorData = error.errors ? error : {
        errors: [{
          errorMessage: 'Save profile failed. Please try again.',
          target: null
        }]
      };
      // console.log("response",errorData.errors[0])
      dispatch({
        type: 'SAVE_PROFILE_FAILURE',
        payload: errorData
      });


      callbackfn(null, error);

  }
};



export const searchActiveProfileAction = (formData, page, rowsPerPage ) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await activeProfileSearch(formData, token,page, rowsPerPage);
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    // if (response) {
    //   callbackfn(response);
    // }
    dispatch({ type: 'SAVE_PROFILE_SEARCH_SUCCESS', payload: response }); // Dispatch the success action with response data
  } catch (error) {
    if (error?.includes("403")) {
      dispatch(openSessionTimeoutModal());
    } else {
      dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
      dispatch({ type: 'SAVE_PROFILE_SEARCH_FAILURE', payload: error || 'Save profile service failed. Please try again.' });
    }
  }
};

export const getProfileAction = (page = 1, size = 5) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_PROFILE_INFOS' });
    const token = getState().auth.token||sessionStorage.getItem("token");
    // console.log("token in general",token);
    const response = await getProfile(token,page,size);
    dispatch({ type: STOP_LOADING, service: 'ALL_PROFILE_INFOS' });
    dispatch({ type: 'ALL_PROFILE_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_PROFILE_INFOS' });
    dispatch({ type: 'ALL_PROFILE_INFOS_FAILURE', payload: error.message || 'All profile service failed. Please try again.' });
  }
};

export const deleteUserAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token||sessionStorage.getItem("token");
    // console.log("token in general",token);
    const response = await deleteUserService(token, id);
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    dispatch({ type: 'DELETE_USER_PROFILE', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_PROFILE_INFOS' });
    dispatch({ type: 'ALL_PROFILE_INFOS_FAILURE', payload: error.message || 'All profile service failed. Please try again.' });
  }
};

export const getProfileUserAction = (id) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'ALL_PROFILE_INFOS' });
    const token = getState().auth.token||sessionStorage.getItem("token");
    // console.log("token in general",token);
    const response = await getProfileUser(id,token);
    dispatch({ type: STOP_LOADING, service: 'ALL_PROFILE_INFOS' });
    dispatch({ type: 'ALL_PROFILE_INFOS_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'ALL_PROFILE_INFOS' });
    dispatch({ type: 'ALL_PROFILE_INFOS_FAILURE', payload: error.message || 'All profile service failed. Please try again.' });
  }
};


export const updateProfileAction = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'UPDATE_PROFILE' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await updateProfile(formData, token);
    dispatch({ type: STOP_LOADING, service: 'UPDATE_PROFILE' });
    if (response) {
      callbackfn(response);  // Success callback
    }
    dispatch({ type: 'UPDATE_PROFILE_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'UPDATE_PROFILE' });

    // Extract the error message from the response if it exists
    const errorMessage = error?.response?.data?.[0]?.errorMessage || 'Email address already in use.';
    dispatch({ type: 'UPDATE_PROFILE_FAILURE', payload: errorMessage });

    // Pass the error message to the callback function
    callbackfn(null, { errorMessage });
  }
};

export const updatedLoggedInUserProfile = (formData, callbackfn) => async (dispatch, getState) => {
  try {
    dispatch({ type: START_LOADING, service: 'START_LOADING' });
    const token = getState().auth.token || sessionStorage.getItem("token");
    const response = await updateUser(formData, token);
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    if (response) {
      callbackfn(response);  // Success callback
    }
    dispatch({ type: 'LOGGEDIN_USER_PROFILE_SUCCESS', payload: response });
  } catch (error) {
    dispatch({ type: STOP_LOADING, service: 'STOP_LOADING' });
    dispatch({ type: 'LOGGEDIN_USER_PROFILE_SUCCESS', payload: error.message || 'All profile service failed. Please try again.' });
  }
};

