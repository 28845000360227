// import React, { useEffect, useState } from 'react';
// import { Container, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, IconButton, Collapse, Box, Dialog, DialogActions, DialogContent, DialogTitle, MenuItem, TablePagination } from '@mui/material';
// import EditIcon from '@mui/icons-material/Edit';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import { saveTaskAction,getAllTaskAction,searchTaskAction } from '../../../redux/actions/taskActions';
// import { useDispatch, useSelector } from 'react-redux';

// const data = [
//   {
//     policyNumber: 'UAD3B1140',
//     date: '01-27-2023',
//     dueDate: '02-27-2023',
//     status: 'Opened',
//     assignedTo: 'MICHELLE MIRABAL',
//     subject: 'Compliance Task: Verify that policy...'
//   },
//   {
//     policyNumber: 'UAD3B1141',
//     date: '01-27-2023',
//     dueDate: '03-27-2023',
//     status: 'Closed',
//     assignedTo: 'MICHELLE MIRABAL',
//     subject: 'Compliance Task: Verify that policy...'
//   },
//   {
//     policyNumber: 'UAD3B1142',
//     date: '01-27-2023',
//     dueDate: '02-27-2023',
//     status: 'Opened',
//     assignedTo: 'MICHELLE MIRABAL',
//     subject: 'Compliance Task: Verify that policy...'
//   },
//   {
//     policyNumber: 'UAD3B1143',
//     date: '01-27-2023',
//     dueDate: '02-27-2023',
//     status: 'Closed',
//     assignedTo: 'MICHELLE MIRABAL',
//     subject: 'Compliance Task: Verify that policy...'
//   }
// ];
// const poppinsFont = {
//   fontFamily: '"Poppins", sans-serif',
// };

// const TaskComponent = () => {
//   const [expandedRows, setExpandedRows] = useState([]);
//   const [open, setOpen] = useState(false);
//   const dispatch = useDispatch();
//   const taskData=useSelector((state)=>state.task.allTask);
//   const searchtaskData=useSelector((state)=>state.task.searchTask);
//   const data=taskData.addTaskList || [];
//   console.log("taskData",taskData.addTaskList);
//   const [formValues, setFormValues] = useState({
//     policyId: '',
//     taskTo: '',
//     dueDate: '',
//     status: '',
//     taskDescription: ''
//   });

//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(5);

//   useEffect(() => {

//         dispatch(getAllTaskAction());

//   }, [dispatch]);

//   const handleExpandClick = (index) => {
//     setExpandedRows(prevExpandedRows =>
//       prevExpandedRows.includes(index)
//         ? prevExpandedRows.filter(row => row !== index)
//         : [...prevExpandedRows, index]
//     );
//   };

//   const handleClickOpen = () => {
//     setOpen(true);
//   };

//   const handleClose = () => {
//     setOpen(false);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormValues(prevValues => ({
//       ...prevValues,
//       [name]: value
//     }));
//   };

//   const handleSubmit = () => {
//     // Dispatch the saveTaskAction with formValues
//     dispatch(saveTaskAction(formValues, (response) => {
//       console.log('Task saved successfully:', response);
//       // Optionally handle the response here
//       setOpen(false); // Close the dialog after submission
//     }));
//   };

//   const paginatedRows = data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };


//   return (
//     <Box
//     sx={{
//       display: 'flex',
//       flexWrap: 'wrap',
//       gap: 2,
//       padding: 2,
//       backgroundColor: '#f9f9f9',
//       borderRadius: '8px',
//       boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
//     }}
//     >
//       <TextField
//         label="From"
//         type="date"
//         InputLabelProps={{ shrink: true }}
//         sx={{fontFamily: '"Poppins", sans-serif', }}
//         style={{ marginRight: '10px',}}
//       />
//       <TextField
//         label="To"
//         type="date"
//         InputLabelProps={{ shrink: true }}
//         sx={{fontFamily: '"Poppins", sans-serif', }}
//         style={{ marginRight: '10px',}}
//       />
//       {/* <TextField
//         label="Subject"
//         sx={{fontFamily: '"Poppins", sans-serif', }}
//         style={{ marginRight: '10px',}}
//       /> */}
//       <TextField
//         // label="Status"
//         placeholder='Status'
//         select
//         SelectProps={{ native: true }}
//         sx={{fontFamily: '"Poppins", sans-serif', }}
//         style={{ marginRight: '10px',}}
//       >
//         <option value="">Select Status</option>
//         <option value="Opened">Opened</option>
//         <option value="Closed">Closed</option>
//       </TextField>
//       {/* <TextField
//         label="Select Policy"
//         select
//         SelectProps={{ native: true }}
//         style={{ marginRight: '10px',  fontFamily: '"Poppins", sans-serif',}}
//       >
//         <option value="All">All</option>
//       </TextField> */}
//       <Button variant="contained" sx={{fontFamily: '"Poppins", sans-serif',}} style={{ marginRight: '10px' }}>Search</Button>
//       <Button variant="contained" sx={{fontFamily: '"Poppins", sans-serif',}} onClick={handleClickOpen}>Add Task</Button>

//       <Dialog open={open} onClose={handleClose}>
//         <DialogTitle sx={{fontFamily: '"Poppins", sans-serif',}}>Add Task</DialogTitle>
//         <DialogContent>
//           <TextField
//             // label="Select Policy"
//             placeholder='Select Policy'
//             name="policyId"
//             select
//             fullWidth
//             value={formValues.policyId}
//             onChange={handleChange}
//             margin="normal"
//             SelectProps={{ native: true }}
//           >
//             <option value="" disabled>
//     Select Policy
//   </option>
//             <option value="1">1</option>
//             <option value="2">2</option>
//             <option value="3">3</option>
//             <option value="4">4</option>
//           </TextField>
//           <TextField
//             // label="Assigned To"
//             placeholder='Assigned To'
//             name="taskTo"
//             fullWidth
//             value={formValues.taskTo}
//             onChange={handleChange}
//             margin="normal"
//             select
//             SelectProps={{ native: true }}
//           >
//             <option value="" disabled>
//     Select Assign To
//   </option>
//             <option value="MICHELLE MIRABAL">MICHELLE MIRABAL</option>
//           </TextField>
//           <TextField
//             label="Select Due Date"
//             name="dueDate"
//             type="date"
//             fullWidth
//             value={formValues.dueDate}
//             onChange={handleChange}
//             margin="normal"
//             InputLabelProps={{ shrink: true }}
//           />
//           <TextField
//             // label="Select Status"
//             placeholder='Select Status'
//             name="status"
//             fullWidth
//             value={formValues.status}
//             onChange={handleChange}
//             margin="normal"
//             select
//             SelectProps={{ native: true }}
//           >
//             <option value="" disabled>
//     Select Policy
//   </option>
//             <option value="Opened">Opened</option>
//             <option value="Closed">Closed</option>
//           </TextField>
//           <TextField
//             label="Task"
//             name="taskDescription"
//             fullWidth
//             value={formValues.taskDescription}
//             onChange={handleChange}
//             margin="normal"
//           />
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose}>Cancel</Button>
//           <Button onClick={handleSubmit}>Submit</Button>
//         </DialogActions>
//       </Dialog>

//       <TableContainer style={{ marginTop: '20px' }}>
//         <Table>
//           <TableHead>
//             <TableRow>
//               <TableCell sx={poppinsFont}>Id</TableCell>
//               <TableCell sx={poppinsFont}>Date</TableCell>
//               <TableCell sx={poppinsFont}>Due Date</TableCell>
//               <TableCell sx={poppinsFont}>Status</TableCell>
//               <TableCell sx={poppinsFont}>Assigned To</TableCell>
//               <TableCell sx={poppinsFont}>Subject</TableCell>
//               <TableCell sx={poppinsFont}>Action</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {paginatedRows.map((row, index) => (
//               <React.Fragment key={index}>
//                 <TableRow>
//                   <TableCell sx={poppinsFont}>{row.id}</TableCell>
//                   <TableCell sx={poppinsFont}>{row.date}</TableCell>
//                   <TableCell sx={poppinsFont}>{row.dueDate}</TableCell>
//                   <TableCell sx={poppinsFont}>{row.status}</TableCell>
//                   <TableCell sx={poppinsFont}>{row.taskTo}</TableCell>
//                   <TableCell sx={poppinsFont}>
//                     <IconButton onClick={() => handleExpandClick(index)}>
//                       {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
//                     </IconButton>
//                     {row.taskDescription}
//                   </TableCell>
//                   <TableCell sx={poppinsFont}>
//                     <IconButton>
//                       <EditIcon />
//                     </IconButton>
//                   </TableCell>
//                 </TableRow>
//                 <TableRow>
//                   <TableCell colSpan={7} style={{ paddingBottom: 0, paddingTop: 0 }}>
//                     <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
//                       <Box margin={1}>
//                         {/* Add your expanded content here */}
//                         <div>
//                           Detailed information about the subject: {row.subject}
//                         </div>
//                       </Box>
//                     </Collapse>
//                   </TableCell>
//                 </TableRow>
//               </React.Fragment>
//             ))}
//           </TableBody>
//         </Table>
//         <TablePagination
//           component="div"
//           count={data.length}
//           page={page}
//           onPageChange={handleChangePage}
//           rowsPerPage={rowsPerPage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//           rowsPerPageOptions={[5, 10, 15]}
//         />
//       </TableContainer>
//     </Box>
//   );
// };

// export default TaskComponent;



import React, { useEffect, useState } from 'react';
import { TextField, Table, TableBody,Select, MenuItem, TableCell,Paper, TableContainer, TableHead, TableRow, Button, IconButton, Collapse, Box, Dialog, DialogActions, DialogContent, DialogTitle, TablePagination, Grid , Typography} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { saveTaskAction, getAllTaskAction, searchTaskAction, updateTaskAction } from '../../../redux/actions/taskActions';
import { useDispatch, useSelector } from 'react-redux';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import { styled } from '@mui/material/styles';


const poppinsFont = {
  fontFamily: '"Poppins", sans-serif',
};


const TaskComponent = () => {
  const [expandedRows, setExpandedRows] = useState([]);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const taskData = useSelector((state) => state.task.allTask);
  const searchtaskData = useSelector((state) => state.task.searchTask);
  // console.log("search data",taskData);
  // const data = searchtaskData || taskData.addTaskList || [];
  const data = searchtaskData && searchtaskData.length > 0 ? searchtaskData : taskData.addTaskList || [];
  const finalData = data?.content || [];
  // console.log("data after searching",data);
  // const data=taskData.addTaskList || [];
  const [formValues, setFormValues] = useState({
    policyId: '',
    taskTo: '',
    dueDate: '',
    status: '',
    taskDescription: ''
  });

  const [searchValues, setSearchValues] = useState({
    fromDate: '',
    toDate: '',
    status: ''
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [editingTask, setEditingTask] = useState(null);

  const handleEditClick = (row) => {
    setEditingTask(row);
    setFormValues({
      id: row.id, // Important to include id for updating
      policyId: row.policyId,
      taskTo: row.taskTo,
      dueDate: row.dueDate,
      status: row.status,
      taskDescription: row.taskDescription
    });
    setOpen(true);
  };
  


  useEffect(() => {
    dispatch(getAllTaskAction(page,rowsPerPage));
  }, [dispatch,page,rowsPerPage]);

  const handleExpandClick = (index) => {
    setExpandedRows(prevExpandedRows =>
      prevExpandedRows.includes(index)
        ? prevExpandedRows.filter(row => row !== index)
        : [...prevExpandedRows, index]
    );
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditingTask(null);
   
    setFormValues({
      policyId: '',
      taskTo: '',
      dueDate: '',
      status: '',
      taskDescription: ''
    });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prevValues => ({
      ...prevValues,
      [name]: value
    }));
  };

  const handleSearchChange = (e) => {
    const { name, value } = e.target;
    setSearchValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleDateChange = (field, value) => {
    setSearchValues((prev) => ({
      ...prev,
      [field]: value ? value.format('MM-DD-YYYY') : ''
    }));
  };

  const handleSearch = () => {
    dispatch(searchTaskAction(searchValues, (response) => {
      console.log('Search completed:', response);
    }));
  };
  // const handleSearch = () => {
  //   console.log('Form data being sent to the API:', searchValues); // Debugging form data
  //   dispatch(searchTaskAction(searchValues, (response) => {
  //     console.log('Search completed:', response); // Check the API response here
  //   }));
  // };

  const handleSubmit = () => {
    if (editingTask) {
      const updateData = {
        id: editingTask.id,
        ...formValues,
        dueDate: dayjs(formValues.dueDate).format('YYYY-MM-DD')
      };
      
      dispatch(updateTaskAction(updateData));
      dispatch(getAllTaskAction(page, rowsPerPage));
      handleClose();
    } else {
      dispatch(saveTaskAction(formValues, () => {
        dispatch(getAllTaskAction(page, rowsPerPage));
        handleClose();
      }));
    }
  };
  
  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    margin: '16px auto',
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    maxWidth: '90vw',
    marginTop: '175px',
    border: `2px solid ${theme.palette.divider}`,
  }));
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%', gap: 2 }}>
    <Box sx={{ 
  display: 'flex', 
  flexDirection: 'column', 
  width: '100%', 
  gap: 2 
}}>
  <Box sx={{ 
    display: 'flex',
    alignItems: 'flex-end',
    gap: 2,
    width: '100%',
    height: '50px',
    mb: 4,
    '& > *:not(:last-child)': {
      marginRight: '0px'
    }
  }}>
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      width: '225px',
      height: '50px',
      marginLeft: '25px'
    }}>
      <Typography variant="body1" sx={{ mb: 0.5 }}>
          From Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={searchValues.fromDate ? dayjs(searchValues.fromDate) : null}
            onChange={(value) => handleDateChange('fromDate', value)}
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" label="MM-DD-YYYY" sx={{ width: '225px', height: '56px' }} />
            )}
          />
        </LocalizationProvider>
    </Box>

    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      width: '225px',
      height: '50px',
      marginRight: '5px'
    }}>
      <Typography variant="body1" sx={{ mb: 0.5 }}>
          To Date
        </Typography>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            value={searchValues.toDate ? dayjs(searchValues.toDate) : null}
            onChange={(value) => handleDateChange('toDate', value)}
            renderInput={(params) => (
              <TextField {...params} fullWidth size="small" label="MM-DD-YYYY" sx={{ width: '225px', height: '56px' }} />
            )}
          />
        </LocalizationProvider>
    </Box>

<Box sx={{ display: "flex", flexDirection: "column", mr: 2, mt: 5, position: "relative", top: "34px" }}>
                <Typography sx={{ p: 0, mr: 12,marginTop:'5px',marginBottom:'5px', fontSize: '16px', fontFamily: '"Poppins", sans-serif' }}>Status</Typography>
                <Select
                  id="selectstatus"
                  name="status"
                  value={searchValues.status}
    onChange={handleSearchChange}
                  sx={{ width: 200, fontFamily: '"Poppins", sans-serif' }}
                >
                  <MenuItem value="Select Status">Select Status</MenuItem>
                  <MenuItem value="Pending">Pending</MenuItem>
                  <MenuItem value="Complete">Complete</MenuItem>
                </Select>
              </Box>


<Box sx={{
  display: 'flex',
  gap: 2,
  height: '40px',
  alignItems: 'flex-end',
  paddingBottom: '2px',
  position: 'relative',
  top: '35px'  // Using position relative and top instead of margin-top
}}>
  <Button
    variant="contained"
    sx={{
      fontFamily: '"Poppins", sans-serif',
      height: '38px',
      minWidth: '100px',
      fontSize: '14px'
    }}
    id="searchbutton"
    className='btn-secondary'
    onClick={handleSearch}
  >
    Search
  </Button>
  <Button
    variant="contained"
    sx={{
      fontFamily: '"Poppins", sans-serif',
      height: '38px',
      minWidth: '100px',
      fontSize: '14px',
      marginLeft:'440px'
    }}
    id="addtaskbutton"
    className='btn-primary'
    onClick={handleClickOpen}
  >
    Add Task
  </Button>
</Box>
  </Box>
</Box> 
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
      <DialogTitle sx={{ fontFamily: '"Poppins", sans-serif' }} id="addtasktitle">
        {editingTask ? 'Edit Task' : 'Add Task'}
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2 }}>
          <Box sx={{ width: '48%' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>Policy</Typography>
            <TextField
              placeholder="Select Policy"
              name="policyId"
              id="policyid"
              select
              fullWidth
              value={formValues.policyId}
              onChange={handleChange}
              SelectProps={{ native: true }}
            >
              <option value="" disabled>Select Policy</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
            </TextField>
          </Box>

          <Box sx={{ width: '48%' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>Assigned To</Typography>
            <TextField
              placeholder="Assigned To"
              name="taskTo"
              id="assignedto"
              fullWidth
              value={formValues.taskTo}
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
            >
              <option value="" disabled>Select Assign To</option>
              <option value="MICHELLE MIRABAL">MICHELLE MIRABAL</option>
            </TextField>
          </Box>

          <Box sx={{ width: '50%' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>Select Due Date</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                placeholder="MM-DD-YYYY"
                value={formValues.dueDate ? dayjs(formValues.dueDate) : null}
                onChange={(newValue) => handleChange({ target: { name: 'dueDate', value: newValue } })}
                format="MM-DD-YYYY"
                renderInput={(params) => <TextField {...params} fullWidth />}
              />
            </LocalizationProvider>

          </Box>

          <Box sx={{ width: '48%', height :'50%' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>Select Status</Typography>
            <TextField
              placeholder="Select Status"
              name="status"
              id="selectstatus"
              fullWidth
              value={formValues.status}
              onChange={handleChange}
              select
              SelectProps={{ native: true }}
            >
              <option value="" disabled>Select Status</option>
              <option value="Opened">Opened</option>
              <option value="Closed">Closed</option>
            </TextField>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Typography variant="body1" sx={{ mb: 1 }}>Task Description</Typography>
            <TextField
              name="taskDescription"
              id="task"
              fullWidth
              multiline
              rows={4}
              value={formValues.taskDescription}
              onChange={handleChange}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSubmit}>{editingTask ? 'Update' : 'Submit'}</Button>
      </DialogActions>
    </Dialog>
  
    <StyledTableContainer component={Paper} sx={{marginTop:'35px'}}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Policy Number</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Date</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Due Date</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Status</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Assigned To</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Subject</TableCell>
              <TableCell className="styled-table-heading" sx={poppinsFont}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {finalData.map((row, index) => (
              <React.Fragment key={index}>
                <TableRow className="styled-table-row">
                  <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
                  <TableCell sx={poppinsFont}>{row.date}</TableCell>
                  <TableCell sx={poppinsFont}>{row.dueDate}</TableCell>
                  <TableCell sx={poppinsFont}>{row.status}</TableCell>
                  <TableCell sx={poppinsFont}>{row.taskTo}</TableCell>
                  <TableCell sx={poppinsFont}>
                    <IconButton onClick={() => handleExpandClick(index)}>
                      {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                    </IconButton>
                    {row.taskDescription}
                  </TableCell>
                  <TableCell sx={poppinsFont}>
                <IconButton onClick={() => handleEditClick(row)}>
                  <EditIcon />
                </IconButton>
              </TableCell>

                </TableRow>
                <TableRow>
                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                    <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
                      <Box margin={1} sx={poppinsFont}>
                        <strong>Task Description:</strong> {row.taskDescription}
                      </Box>
                    </Collapse>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
        
  
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%', mt: 2 }}>
        <TablePagination
          component="div"
          count={data?.totalElements || 0}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 15]}
        />
      </Box>
      </StyledTableContainer >
    </Box>
  );
}
  

export default TaskComponent;
