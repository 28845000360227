import React, { useEffect, useState } from 'react';
import { useDispatch} from "react-redux";
import {
    TextField,
    Box,
    Button,
    Select,
    MenuItem
} from '@mui/material';
import {
  updatedOpenTaskData
} from "../../../redux/actions/dashboardAction";
const DashboardOpenTaskEditPopup = ({closePopup, savePopup, taskFilterData}) => {
  const dispatch = useDispatch(); 
  const [formData, setFormData] = useState({ 
        id: 0,
        taskType: '',
        reviewRequired: true,
        customerNumber: 0,
        customerName: '',
        taskTo: '',
        createdBy: '',
        dueDate: '',
        closedDate: '', 
        closedBy: '',
        time: '',
        priority: '',
        status: '',
        typeOfTaskOpen: '',
        taskDescription: '',
        notes: '',
        policyId: 0,
        pubId: null,
        policyNumber: '',
        createdDate: '',
        assignedTo: '',        
        assignedBy: '',
        subject: '',  
    });

  useEffect(() => {
    if(taskFilterData){
        setFormData({
            id:taskFilterData.id,
            taskType: taskFilterData.taskType,
            reviewRequired: taskFilterData.reviewRequired,
            customerNumber: taskFilterData.customerNumber,
            customerName: taskFilterData.customerName,
            taskTo: taskFilterData.taskTo,
            createdBy: taskFilterData.createdBy,
            dueDate: taskFilterData.dueDate,
            closedDate: taskFilterData.closedDate || null, 
            closedBy: taskFilterData.closedBy || null,
            time:taskFilterData.time,
            priority: taskFilterData.priority,
            status: taskFilterData.status,
            typeOfTaskOpen: taskFilterData.typeOfTaskOpen,
            taskDescription: taskFilterData.taskDescription,
            notes: taskFilterData.notes,
            policyId: taskFilterData.policyId,
            pubId: taskFilterData.pubId,
            policyNumber: taskFilterData.policyNumber,
            createdDate: taskFilterData.createdDate,
            assignedTo: taskFilterData.assignedTo,
            assignedBy: taskFilterData.assignedBy,            
            subject: taskFilterData.subject,
        })
    }    
}, [taskFilterData])
 
  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (taskFilterData) {
        const updatedTasTablekData = {...formData };
        dispatch(
          updatedOpenTaskData(updatedTasTablekData, (success) => {
            if (success) {
              closePopup(true)
              savePopup()
            }
          })
        );
      }
  }
  const handleCancel = () => {
     closePopup(true)
  }
  

  return (
    <div className="popup">
      <div className="popup-content">
        <form onSubmit={handleSubmit}>
        <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
          <Box sx={{ display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%' }}>
            <label htmlFor="policy-number-input">
              Policy Number:
            </label>
            <TextField
              type="text"
              name="id"
              value={formData.policyNumber}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
              id="policynumberinput" 
            />
          </Box>
  
          <Box sx={{ display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%' }}>
            <label htmlFor="due-date-input">
              Due Date:
            </label>
            <TextField
              type="date"
              name="duedate"
              value={formData.dueDate}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
              id="duedateinput"  
            />
          </Box>
  
          <Box sx={{ display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '33%' }}>
            <label htmlFor="task-open-select">
              Type Of Task Open:
            </label>
            <Select
              name="typeOfTaskOpen"
              value={formData.typeOfTaskOpen}
              onChange={handleChange}
              sx={{ mr: 2 }}
              id="taskopenselect"  
            >
              <MenuItem value="Pending Cancellation">Pending Cancellation</MenuItem>
              <MenuItem value="Cancellation">Cancellation</MenuItem>
              <MenuItem value="UW">UW</MenuItem>
              <MenuItem value="Renewal">Renewal</MenuItem>
            </Select>
          </Box>
  
          <Box sx={{ display: 'flex', flexFlow: 'column', marginBottom: '20px', width: '100%' }}>
            <label htmlFor="subject-input">
              Subject:
            </label>
            <TextField
              multiline
              rows={4}  // Adjust the number of rows as needed
              name="subject"
              value={formData.subject}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
              id="subjectinput"  
            />
          </Box>
        </Box>
        
        <Box sx={{ display: 'flex', flexFlow: 'row', justifyContent: 'end' }}>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleCancel}
            sx={{ margin: '0 10px' }}
            id="cancelbutton"  
          >
            Cancel
          </Button>
  
          <Button
            variant="contained"
            color="primary"
            type='submit'
            id="savebutton"  
          >
            Save
          </Button>
        </Box>
        </form>
      </div>
    </div>
  );  
};

export default DashboardOpenTaskEditPopup;
