import React from 'react'
import { Controller, useFormContext } from 'react-hook-form';
import { Box, Grid, FormControl, Typography, MenuItem, TextField, Select,Checkbox,  FormControlLabel,  FormHelperText, Button } from '@mui/material';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { format } from 'date-fns';
import Autocomplete from '@mui/material/Autocomplete';
import { saveTaskAction,updateTaskAction } from '../../../redux/actions/taskActions';
import { useDispatch } from 'react-redux';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
const validationRules = [
    {
        fieldName: 'taskType',
        label: 'Type of Task:',
        type: 'dropdown',
        options: ['New Business', 'Rewrite']
      },
      { fieldName: "reviewRequired", label: "Review Required", type: "checkbox" },
      {
        fieldName: 'customerNumber',
        label: 'Customer Number',
        type: 'number',
      },
      { fieldName: "customerName", label: "Customer Name", type: "text" },
      {
        fieldName: 'taskTo',
        label: 'To',
        type: 'text',
        default:"Task"
      },
      { fieldName: "createdBy", label: "Created By", type: "text" },
    { fieldName: "dueDate", label: "Due Date:", type: "date" },
    { fieldName: "time", label: "Task Time:", type: "time" },
    { fieldName: "priority", label: "Priority", type: "dropdown", options: ['High', 'Low'] },
    { fieldName: "status", label: "Status", type: "dropdown",options: ['Opened', 'Low'] },
  ]

  const validationRules1=[
    { fieldName: "taskDescription", label: "Task Desciption", type: "text" },
    { fieldName: "notes", label: "Note Description", type: "text" },
  ]
function AddTask1({setShowHideTaskForm,setShowButton}) {
    const { control,handleSubmit, formState: { errors } } = useFormContext();
    const dispatch = useDispatch();
    const getCreateUserSuccess = async () => {
        // navigate('/allaccount')
      }
  //   const onSubmit = (data) => {

  //     dispatch(saveTaskAction(data, getCreateUserSuccess));

  // };
  const onSubmit = (data) => {
    if (data.dueDate) {
      data.dueDate = format(new Date(data.dueDate), 'MM/dd/yyyy');
    }
    if (setShowButton) {
      // Create new policy
      dispatch(saveTaskAction(data, getCreateUserSuccess));
    } else {
      // Update existing policy
      dispatch(updateTaskAction(data));
      setShowHideTaskForm(false)
    }
  };
  return (
    <>
    <Box
        sx={{
          alignSelf: "stretch",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          gap: "36px",
          maxWidth: "100%",
        }}
      >
        <section
          sx={{
            alignSelf: "stretch",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "center",
            padding: "0px 23px 0px 20px",
            boxSizing: "border-box",
            maxWidth: "100%",
            textAlign: "left",
            fontSize: "14px",
            color: "#242929",
            fontFamily: "Poppins",
          }}
        >
            <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            sx={{
              width: "853px",
              borderRadius: "4px",
              backgroundColor: "#fff",
              border: "1px solid #e3e3e3",
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
              justifyContent: "flex-start",
              padding: "30px 0px 24px",
              gap: "23px",
              maxWidth: "100%",
              marginLeft: "170px",
            }}
          >
     {/* <Box sx={{ padding: '0 2rem 0 5rem', margin: '4rem 0 0 0'}}> */}
            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
            <Box
              sx={{
                alignSelf: "stretch",
                height: "798px",
                position: "relative",
                borderRadius: "4px",
                backgroundColor: "#fff",
                border: "1px solid #e3e3e3",
                boxSizing: "border-box",
                display: "none",
              }}
            />
            <Typography
              variant="h1" // Adjust the variant as needed, e.g., h1, h2, h3, etc.
              component="div"
              sx={{
                border: "none",
                backgroundColor: "transparent",
                alignSelf: "stretch",
                height: "51px",
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "25px",
                color: "#242929",
                paddingLeft: "50px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Add Task
            </Typography>
            <Box
              sx={{
                alignSelf: "stretch",
                display: "flex",
                flexDirection: "row",
                alignItems: "flex-start",
                justifyContent: "flex-end",
                padding: "0px 44px 0px 52px",
                boxSizing: "border-box",
                maxWidth: "100%",
              }}
            >
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  alignItems: "flex-start",
                  justifyContent: "flex-start",
                  gap: "16px",
                  maxWidth: "100%",
                }}
              >
                <Box
                  sx={{
                    flex: "1",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "4px",
                    minWidth: "240px",
                    maxWidth: "100%",
                  }}
                >
                  <Box
                    sx={{
                      alignSelf: "stretch",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                      padding: "0px 0px 8px",
                      gap: "4px",
                    }}
                  >
            <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ margin: '0' }}>
            {validationRules.map(({ fieldName, label, type, options, rules, defaultValue }) => (
                <Grid key={fieldName} item xs={12} sm={6} md={6} sx={{ margin: '0', padding: '0 25px !important' }}>
                <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                    <Typography>{label}</Typography>
                    <Controller
                    name={fieldName}
                    control={control}
                    rules={rules}
                    defaultValue={defaultValue}
                    render={({ field }) => {
                        if (type === 'text' || type === 'number' || type==='time' ) {
                            return (
                                <TextField
                                    {...field}
                                    inputProps={type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 } : {}}
                                    id={fieldName}
                                    type={type}
                                    sx={{ width: '100%' }}
                                    error={!!errors[fieldName]}
                                />
                            );
                        } else if (type === 'dropdown' || type==="select") {
                        return (
                            <Select
                            {...field}
                            error={!!errors[fieldName]}
                            >
                            {options.map((option, index) => (
                                <MenuItem key={index} value={option}>
                                {option}
                                </MenuItem>
                            ))}
                            </Select>
                        );
                        } else if (type === 'autocomplete') {
                        return (
                            <Autocomplete
                            {...field}
                            options={options}
                            getOptionLabel={(option) => option.label}
                            onChange={(_, data) => field.onChange(data)}
                            defaultValue={defaultValue}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                error={!!errors[fieldName]}
                                />
                            )}
                            />
                        );
                        }
                        else if(type === 'date'){
                          return (
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DatePicker
                                format="MM-DD-YYYY"
                                {...field}
                                value={field.value ? dayjs(field.value, 'YYYY-MM-DD') : null}
                                // onChange={(date) => field.onChange(date ? date.format('MM-DD-YYYY') : null)}
                                // value={formData[fieldName]}
                                // onChange={(date) => handleDateChange(date, fieldName)}
                                slotProps={{
                                  textField: {
                                    size: "medium",
                                    sx: {
                                      width: 357,
                                    //   "& fieldset": { borderColor: "#077BFD" },
                                      "& .MuiInputBase-input": { color: "#000000" },
                                    },
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} />}
                              />
                            </LocalizationProvider>
                          )
                        }
                        else if (type === 'checkbox') {
                            return (
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    {...field}
                                    checked={field.value}
                                    onChange={(e) => field.onChange(e.target.checked)}
                                  />
                                }
                                label={label}
                              />
                            );
                          }
                        return null;
                    }}
                    />
                    <FormHelperText>{errors[fieldName]?.message}</FormHelperText>
                </FormControl>
                </Grid>
            ))}
            </Grid>
            <Grid container rowSpacing={5} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '0',marginLeft:'50',padding:'40' }}>
            {validationRules1.map(({ fieldName, label, type, options, rules, defaultValue }) => (
                <Grid key={fieldName} item xs={12} sm={6} md={12} sx={{ margin: '0', padding: '0 25px !important' }}>
                 <FormControl fullWidth margin="normal" error={!!errors[fieldName]}>
                    <Typography>{label}</Typography>
                    <Controller
                    name={fieldName}
                    control={control}
                    rules={rules}
                    defaultValue={defaultValue}
                    render={({ field }) => {
                        if (type === 'text' || type === 'number' || type==='time' ) {
                            return (
                                <TextField
                          {...field}
                          inputProps={type === 'number' ? { inputMode: 'numeric', pattern: '[0-9]*', maxLength: 10 } : {}}
                          id={fieldName}
                          type={type}
                          multiline
                          minRows={6}
                          sx={{ width: '100%' }}
                          error={!!errors[fieldName]}
                        />
                            );
                        }
                        return null;
                    }}
                        />
                        </FormControl>
                        </Grid>

                    ))}
                    </Grid>
            {/* </Grid> */}
            {/* </form> */}
            </Box>
            </Box>
            </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            {!setShowButton ? (
        <>
          <Button type="submit"   variant="contained" color="primary" sx={{ marginRight: 2 }}>
          Update
          </Button>
          <Button onClick={() => setShowHideTaskForm(false)}  variant="outlined" color="primary">
            Cancel
          </Button>
        </>
      ) : (
        <>
        <Button type="submit"  variant="contained" color="primary">
          Save
        </Button>
        <Button onClick={() => setShowHideTaskForm(false)}  variant="outlined" color="primary">
            Cancel
          </Button>
        </>

      )}
            </Box>
            </Box>
            </section>
            </Box>
    </>
  )
}

export default AddTask1
