import React, { useEffect, useState } from 'react';
import { Button, Typography, Grid, CircularProgress, FormControl, Select, MenuItem } from '@mui/material';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDispatch, useSelector } from 'react-redux';
import { getThemeAction, saveThemeAction, updateThemeAction } from '../../redux/actions/themeActions';
import { getThemeDefaultAction } from '../../redux/actions/themeAction';
import { styled } from '@mui/material/styles';

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const CustomThemeSettings = () => {
  const [selectedTheme, setSelectedTheme] = useState('');
  const [customTheme, setCustomTheme] = useState({
    primaryColor: "",
    secondaryColor: "",
    themeFont: "",
    fileName: "",
    fileType: "",
    fileSize: 0,
    fileData: null, // Store the File object
    fileUrl: "", // For image preview
  });
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user);
  const theme = useSelector((state) => state?.theme?.theme);
  const getDefaultTheme = useSelector((state) => state?.theme);  
  const loading = useSelector((state) => state.loading?.GET_THEME || state.loading?.SAVE_THEME || state.loading?.UPDATE_THEME );
    
  useEffect(() => {
    if(user?.customer?.id) {
      dispatch(getThemeDefaultAction(user?.customer?.id));     
    }
    if(theme?.id){
      dispatch(getThemeDefaultAction(theme?.id)); 
    }
    dispatch(saveThemeAction())
  }, [user, theme?.id]);

  useEffect(() => {
    if(theme){
      setCustomTheme(prevTheme => ({
        ...prevTheme,
        primaryColor: theme?.primaryColor,
        secondaryColor: theme?.secondaryColor,
        fileName: theme?.fileName,
        fileType: theme?.fileType,
        fileSize: theme?.fileSize,
        fileData: null, // Reset fileData if theme changes
        fileUrl: theme?.fileData !== "default" ? theme.fileUrl : "path_to_default_image", // Adjust as necessary
      }));
    }
  }, [theme])

  const handleColorChange = (color, type) => {
    setCustomTheme(prevTheme => ({
      ...prevTheme,
      [type]: color,
    }));
  };

  console.log(getDefaultTheme, 'getDefaultTheme')

  console.log(theme, 'themethemetheme themetheme')

  const handleLogoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setCustomTheme(prevTheme => ({
        ...prevTheme,
        fileName: file.name,
        fileType: file.type,
        fileSize: file.size,
        fileData: file, // Store the actual File object
      }));

      // Optionally, generate a preview URL
      const reader = new FileReader();
      reader.onload = (event) => {
        setCustomTheme(prevTheme => ({
          ...prevTheme,
          fileUrl: event.target.result, // For image preview
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();
    
    // Append metadata
    formData.append('primaryColor', customTheme.primaryColor);
    formData.append('secondaryColor', customTheme.secondaryColor);
    formData.append('themeFont', customTheme.themeFont);
    formData.append('themeName', selectedTheme);
    formData.append('customerId', user?.customer?.id);
    formData.append('userId', user?.id);
    
    // Append the file if it exists
    if (customTheme.fileData) {
      formData.append('file', customTheme.fileData);
    }
  
    try {
      // Dispatch the appropriate action
      if (theme?.id) {
        formData.append('id', theme.id); // Include theme ID for updates
        await dispatch(updateThemeAction(formData));
      } else {
        await dispatch(saveThemeAction(formData));
      }
  
      // Refresh the theme list and update the state
      await dispatch(getThemeDefaultAction(user?.customer?.id));
      
      // Instead of calling handleThemeChange, reset or update state directly
      // Example: Reset selected theme if needed
      // setSelectedTheme(''); // or any other logic as per requirements
    } catch (error) {
      console.error('Error submitting theme:', error);
      // Optionally, handle error state here
    }
  };
  

  const resetTheme = async () => {
    const defaultValues = {
      primaryColor: "#077BFD",
      secondaryColor: "#000",
      themeFont: "",
      fileName: "default.png",
      fileType: "image/png",
      fileSize: 0,
      fileData: null, // Reset the file object
      fileUrl: "path_to_default_image", // Set to default image URL if applicable
    };

    const themeUpdated = {
      ...customTheme,
      ...defaultValues,
      customerId: user?.customer?.id,
      userId: user?.id,
      id: theme?.id, // Include ID if updating
    };

    const formData = new FormData();
    formData.append('primaryColor', themeUpdated.primaryColor);
    formData.append('secondaryColor', themeUpdated.secondaryColor);
    formData.append('themeFont', themeUpdated.themeFont);
    formData.append('themeName', selectedTheme);
    formData.append('customerId', themeUpdated.customerId);
    formData.append('userId', themeUpdated.userId);
    
    if (theme?.id) {
      formData.append('id', themeUpdated.id);
      // Optionally, append a default file or handle accordingly
      // formData.append('file', ...);
      await dispatch(updateThemeAction(formData));
    } else {
      // Handle saving a new default theme if necessary
      // formData.append('file', ...);
      await dispatch(saveThemeAction(formData));
    }

    await dispatch(getThemeDefaultAction(user?.customer?.id));
  }
  
  const handleThemeChange = (event) => {
    const value = event.target.value;    
    setSelectedTheme(value)
  };

  return (
    <div style={{ padding: '20px' }}>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>Theme Customization</Typography>
      <Grid container spacing={2}>
        <Grid sm={12} container ml={3} mt={4}>
          <Grid item xs={12} sm={2} sx={{marginRight: '15px'}}>
            <FormControl fullWidth style={{ marginRight: '20px' }}>            
              <Typography>Select Theme</Typography>
              <Select
                labelId="theme-select-label"
                id="theme-select"
                value={selectedTheme}
                onChange={handleThemeChange} // Correct usage
              >
                <MenuItem value="defaultTheme">Default Theme</MenuItem>
                <MenuItem value="deltoroTheme">Deltoro Theme</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography id="primarycolorlabel">Primary Color:</Typography>
            <input
              type="color"
              id="primarycolorinput"
              value={customTheme.primaryColor}
              onChange={(e) => handleColorChange(e.target.value, 'primaryColor')}
            />
          </Grid>
          <Grid item xs={12} sm={2}>
            <Typography id="secondarycolorlabel">Secondary Color:</Typography>
            <input
              type="color"
              id="secondarycolorinput"
              value={customTheme.secondaryColor}
              onChange={(e) => handleColorChange(e.target.value, 'secondaryColor')}
            />
          </Grid>
        </Grid>
        
        <Grid item xs={12}>
          <Typography id="logolabel">Logo:</Typography>
          <img src={customTheme.fileUrl || "path_to_default_image"} width="25%" id="logo-preview" alt="Logo Preview" />
        </Grid>
        <Grid sx={{marginTop: '10px', marginLeft: '12px'}}>
          <Button
            component="label"
            role={undefined}
            variant="contained"
            tabIndex={-1}
            startIcon={<CloudUploadIcon />}
            className='upload-img-container'
          >
            Upload logo file here
            <VisuallyHiddenInput
              type="file"
              id="logoupload"
              accept="image/*"
              onChange={handleLogoChange}
            />
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ mr: '1rem' }}
            id="savethemebutton"
            className='btn-primary'
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="secondary" /> : "Save Theme"}
          </Button>
          <Button
            variant="contained"
            onClick={resetTheme}
            sx={{ mr: '1rem' }}
            id="resetthemebutton"
            className='btn-secondary'
            disabled={loading}
          >
            {loading ? <CircularProgress size={24} color="secondary" /> : "Reset To Default"}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};

export default CustomThemeSettings;
