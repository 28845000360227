
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from '@mui/material/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  tableCellClasses,
  Paper,
  Button,
  TablePagination,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
} from '@mui/material';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PreviewIcon from '@mui/icons-material/Preview';
import { getAllUploadAttachmentsAction, previewFileAction } from '../../redux/actions/uploadAttachmentActions';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    fontWeight: 'bold',
    padding: '12px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: '10px',
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({  
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  maxWidth: '90vw',
  border: `2px solid ${theme.palette.divider}`,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  borderColor: theme.palette.primary.main,
  color: theme.palette.primary.main,
  textTransform: 'none',
  '&:hover': {
    borderColor: theme.palette.primary.dark,
    backgroundColor: theme.palette.action.hover,
  },
}));

const UploadList = () => {
  const dispatch = useDispatch();
  const userId = useSelector(state => state.user?.user?.id);
  const uploads = useSelector(state => state.uploadAttachment.allAttachments);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [previewData, setPreviewData] = useState(null);
  const [openPreview, setOpenPreview] = useState(false);

  useEffect(() => {
    dispatch(getAllUploadAttachmentsAction(userId,page,rowsPerPage));
  }, [dispatch, userId,page,rowsPerPage]);

  const handleDownload = async (userId, filename, fileExtension) => {
    try {
      const result = await dispatch(previewFileAction(userId, filename));
      if (result?.blob) {
        const mimeTypeMap = {
          pdf: 'application/pdf',
          png: 'image/png',
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };
        const mimeType = mimeTypeMap[fileExtension] || 'application/octet-stream';
        const blob = new Blob([result.blob], { type: mimeType });
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setTimeout(() => URL.revokeObjectURL(link.href), 1000);
      }
    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const handlePreview = async (userId, filename, fileExtension) => {
    try {
      const result = await dispatch(previewFileAction(userId, filename));
      if (result?.blob) {
        const mimeTypeMap = {
          pdf: 'application/pdf',
          png: 'image/png',
          jpg: 'image/jpeg',
          jpeg: 'image/jpeg',
          docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        };
        const mimeType = mimeTypeMap[fileExtension] || 'application/octet-stream';
        const blob = new Blob([result.blob], { type: mimeType });
        const blobUrl = URL.createObjectURL(blob);
        setPreviewData({ url: blobUrl, type: mimeType });
        setOpenPreview(true);
      }
    } catch (error) {
      console.error('Preview error:', error);
    }
  };

  const handleClosePreview = () => {
    if (previewData?.url) {
      URL.revokeObjectURL(previewData.url);
    }
    setOpenPreview(false);
    setPreviewData(null);
  };

  const paginatedRows = uploads.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  const handleChangePage = (event, newPage) => setPage(newPage);
  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 650, marginTop:'-1px' }} aria-label="upload list table">
        <TableHead>
          <TableRow className="styled-table-row">
            <TableCell className="styled-table-heading">Policy Number</TableCell>
            <TableCell className="styled-table-heading">Type</TableCell>
            <TableCell className="styled-table-heading">Date Uploaded</TableCell>
            <TableCell className="styled-table-heading">Document Name</TableCell>
            <TableCell className="styled-table-heading">Download</TableCell>
            <TableCell className="styled-table-heading">Preview</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {paginatedRows.map((upload, index) => (
            <TableRow key={index} className="styled-table-row">
              <StyledTableCell>{upload.policy}</StyledTableCell>
              <StyledTableCell>{upload.type}</StyledTableCell>
              <StyledTableCell>{upload.date}</StyledTableCell>
              <StyledTableCell>{upload.name}</StyledTableCell>
              <StyledTableCell>
                <StyledButton
                  id={`downloadbutton${index}`} 
                  name="downloadbutton" 
                  variant="outlined"
                  startIcon={<NoteAddIcon />}
                  className='btn-primary-md'
                  onClick={() => handleDownload(userId, upload.name, upload.fileType)}
                >
                  Download
                </StyledButton>
              </StyledTableCell>
              <StyledTableCell>
                <IconButton
                  id={`previewbutton${index}`} 
                  name="previewbutton" 
                  onClick={() => handlePreview(userId, upload.name, upload.fileType)}
                  sx={{ color: "#000000"}}
                >
                  <PreviewIcon />
                </IconButton>
              </StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        component="div"
        count={uploads.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[5, 10, 15]}
      />

      <Dialog open={openPreview} onClose={handleClosePreview} maxWidth="md" fullWidth>
        <DialogTitle>File Preview</DialogTitle>
        <DialogContent>
          {previewData && previewData.type.startsWith('image/') && (
            <img
              id="previewimage"
              src={previewData.url}
              alt="File preview"
              style={{ width: '100%', height: 'auto' }}
            />
          )}
          {previewData && previewData.type === 'application/pdf' && (
            <iframe
              id="previewpdf" 
              src={previewData.url}
              title="PDF preview"
              style={{ width: '100%', height: '500px' }}
            />
          )}
          {/* Handle other file types accordingly */}
        </DialogContent>
      </Dialog>
    </StyledTableContainer>
  );
};

export default UploadList;

