import apiService from "./apiServices";

export const saveNote = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/notes/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving note is failed. Please try again.');
        }
  }

  export const updateNote = async (formData, token) => {
    try {

      const response = await apiService('/notes/', 'PUT', formData, null, token);
      return response;
      } catch (error) {
        throw (error?.message || 'Updating note is failed. Please try again.');
      }
  }

  export const getAllNote = async (token, page, size) => {
    try {

      const response = await apiService(`/notes/user?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
      return response;
    } catch (error) {
      throw (error?.message || 'service is failed. Please try again.');
    }
  }

  // export const getAllNote = async (token, page, size) => {
  //   try {

  //     const response = await apiService(`/notes/user?page=${page}&size=${size}`, 'GET', null, null, token);
  //     console.log("all response",response)
  //     return response;
  //   } catch (error) {
  //     throw (error?.message || 'service is failed. Please try again.');
  //   }
  // }

  export const deleteNoteById = async (id, token) => {
    try {
      // const headers = {
      //   'Access-Control-Request-Headers': '*'
      // };
    //   console.log("id",id);
      const response = await apiService(`/note/delete/${id}`, 'DELETE', null, null, token);
    //   console.log("response",response)
      return response;
    } catch (error) {
      throw new Error(error.message || 'service is failed. Please try again.');
    }
  }

  export const searchNote = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for task');
        const response = await apiService('/notes/search', 'POST', formData, null, token);
        console.log("search note",response);
        return response;
        } catch (error) {
          throw (error?.message || 'Searching note is failed. Please try again.');
        }
  }



  //alert

  export const saveAlert = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for alert');
        const response = await apiService('/alert/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving note is failed. Please try again.');
        }
  }

  //comment
  export const saveComment = async(formData, token) => {
    try {

        // console.log(formData, 'formData, for alert');
        const response = await apiService('/comment/save', 'POST', formData, null, token);
        return response;
        } catch (error) {
          throw (error?.message || 'Saving comment is failed. Please try again.');
        }
  }