import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Typography,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  TablePagination,
} from '@mui/material';
import { saveLocationGroupsAction, updateLocationGroupsAction } from '../../redux/actions/locationGroupActions';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getAllLocationAction } from '../../redux/actions/locationActions';
import { styled } from "@mui/material/styles";

const LocationList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
 
  const [selectedLocationIds, setSelectedLocationIds] = useState([]);
  const [groupName, setGroupName] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [isEdit, setIsEdit] = useState(false);
  const [groupId, setGroupId] = useState(null);
  const [error, setError] = useState('');

  const locationDetails = useSelector((state) => state.location.allLocation || []);


  useEffect(() => {
    if (location.state) {
      setGroupName(location.state.groupName || ''); // Pre-fill group name

      const locationIds = location.state.selectedLocations.map((loc) => loc.id || loc.locationId);
      setSelectedLocationIds(locationIds);

      setIsEdit(location.state.isEdit); 
      setGroupId(location.state.groupId || null); 
    }
    dispatch(getAllLocationAction());
  }, [location.state]);

  const handleCheckboxChange = (id) => {
    setSelectedLocationIds((prev) =>
      prev.includes(id) ? prev.filter((locationId) => locationId !== id) : [...prev, id]
    );
  };

  const handleCreateOrUpdateGroupClick = () => {
    if (!groupName) {
      setError('Please provide a group name.');
      return;
    }

    if (selectedLocationIds.length === 0) {
      setError('Please select at least one location.');
      return;
    }

    // Clear any previous error messages if validation passes
    setError('');

    const groupData = {
      locationGroupName: groupName,
      locationIds: selectedLocationIds,
      status: 'Active',
    };

    const updatedGroupLocation = {
      locationGroupId: groupId,
      locationGroupName: groupName,
      locationIds: selectedLocationIds,
      status: 'Active',        
    };

    if (isEdit && groupId) {
      dispatch(updateLocationGroupsAction(updatedGroupLocation)); 
    } else {
      dispatch(saveLocationGroupsAction(groupData)); 
    }

    navigate('/locationgrp');
    
    // Reset form fields
    setSelectedLocationIds([]);
    setGroupName('');
    setIsEdit(false);
    setGroupId(null);
    setError(''); 
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const paginatedRows = locationDetails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

  return (
    <Box sx={{ padding: '20px' }}>
      <Typography variant="h5" sx={{ fontWeight: 'bold', marginBottom: '20px' }}>
        All Location List
      </Typography>

      {/* Group Name Input */}
      <Box sx={{ marginBottom: '20px' }}>
      <Typography
      variant="body1"
      sx={{
        fontFamily: "Poppins",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "19.6px",
        textAlign: "left",
        marginBottom: "6px",
      }}
    >
      Location Group Name
    </Typography>
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <TextField
          variant="outlined"
          size="small"
          value={groupName}
          onChange={(e) => {
            setGroupName(e.target.value);
            setError(''); // Clear error when editing the group name
          }}
          error={!!error} // Set error if there's any error
          helperText={error} // Display error message
          sx={{
            width: "200px",
            height: "55px", // Ensure the height is explicitly set
            "& .MuiInputBase-root": {
              height: "55px", // Apply the height to the input container
            },
          }}
        />
        <Box sx={{ marginTop: "0px" , marginLeft: "10px"}}>
        <Button
          variant="contained"
          className='btn-primary'
          color="primary"
          onClick={handleCreateOrUpdateGroupClick}
        >
          {isEdit ? 'Update Group' : 'Create Group'}
        </Button>
        </Box>
      </Box>
      </Box>

      {/* Location List */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading"  padding="checkbox"></TableCell>
              <TableCell className="styled-table-heading">Description</TableCell>
              <TableCell className="styled-table-heading">Address</TableCell>
              <TableCell className="styled-table-heading">City</TableCell>
              <TableCell className="styled-table-heading">State</TableCell>
              <TableCell className="styled-table-heading">Zip Code</TableCell>
              <TableCell className="styled-table-heading">Phone</TableCell>
              <TableCell className="styled-table-heading">Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedRows.map((location, index) => (
              <TableRow key={location.id} className="styled-table-row">
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={selectedLocationIds.includes(location.id)}
                    onChange={() => handleCheckboxChange(location.id)}
                    id={`active-${index}`}
                  />
                </TableCell>
                <TableCell>{location.locationDescription}</TableCell>
                <TableCell>{`${location.address1} ${location.address2}`}</TableCell>
                <TableCell>{location.city}</TableCell>
                <TableCell>{location.state}</TableCell>
                <TableCell>{location.zipCode}</TableCell>
                <TableCell>{location.phone}</TableCell>
                <TableCell>{location.isActive ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <TablePagination
          component="div"
          count={locationDetails.length}
          page={page}
          rowsPerPage={rowsPerPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={[5, 10, 25]}
        />
      </TableContainer>
    </Box>
  );
};

export default LocationList;
