
import React, { useEffect, useState, useRef } from "react";
import {
  Container,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TablePagination,
  Button,
  Grid,
  TextField,
} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { makeStyles } from "@mui/styles";
import { useDispatch, useSelector } from "react-redux";
import { getBotAllReportAction, searchBotAllReportAction ,searchBotDownloadAllReportAction} from "../../redux/actions/botAction";
import html2canvas from 'html2canvas';
import jsPDF from "jspdf";
import Loader from "../loader/loader";



const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: 20,
    padding: 16,
    backgroundColor: "#f5f5f5",
    borderRadius: 8,
    boxShadow: "0px 2px 10px rgba(0, 0, 0, 0.1)",
  },
  table: {
    minWidth: 650,
  },
  tableCell: {
    fontWeight: "bold",
    backgroundColor: "#b0b0b0",
    color: "white",
    textAlign: "center",
  },
  tableRow: {
    "&:hover": {
      backgroundColor: "#f1f1f1",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 20,
  },
  tableContainer: {
    borderRadius: 8,
    boxShadow: "0px 4px 15px rgba(0, 0, 0, 0.1)",
  },
  iconButton: {
    marginRight: 8,
    "&:hover": {
      backgroundColor: "#e0e0e0",
    },
  },
  downloadButton: {
    backgroundColor: "#4CAF50",
    color: "white",
    "&:hover": {
      backgroundColor: "#45a049",
    },
  },
  tableCell: {
    fontWeight: '700 !important',
    backgroundColor: '#1565c0 !important',
    color: '#ffffff !important',
    fontFamily: '"Poppins", sans-serif !important',
    textAlign: "center !important",
  },

tableRow: {
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected,
  },
  '& td, & th': {
    padding: theme.spacing(1.5),
    fontFamily: '"Poppins", sans-serif',
  }
},
}));

function BotAllReport() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const botDetails = useSelector((state) => state.bot.getBotReport);
  const searchBot = useSelector((state) => state.bot.searchBot);
  const searchBotDownload = useSelector((state) => state.bot.searchBotDownload);
  // const reports = botDetails.content || [];
  const [search, setSearch] = useState("");
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [botData, setBotData] = useState([]);
  const [hasSearched, setHasSearched] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const tableRef = useRef(null);
  const searchreport = searchBot.content || [];
  const searchreportdownload = searchBotDownload.content || [];
  const count = hasSearched ? searchBot.totalElements : 0;
  const searchReportDownload = searchBotDownload.content || [];
  const [showTable, setShowTable] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  // Fetch all search results for download
  // const fetchAllSearchResults = async () => {
  //   try {
  //     const response = await dispatch(searchBotDownloadAllReportAction({ search }, 0, count));
  //     console.log("response of all data",response);
  //     return response.content || [];
  //   } catch (error) {
  //     console.error('Error fetching all search results:', error);
  //     return [];
  //   }
  // };

  useEffect(() => {
    if(count>0)
    {
      dispatch(searchBotDownloadAllReportAction({fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
        toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search }, 0, count));
    }
    }, [dispatch,page, rowsPerPage,count]);


    useEffect(() => {
      if (hasSearched) {
        dispatch(
           searchBotAllReportAction(
            {fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
              toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null, search },
            page,
            rowsPerPage,
            // `${sortField},${sortOrder}`
          )
        );
      }
    }, [dispatch, page, rowsPerPage, hasSearched, search]);

  useEffect(() => {
    if (hasSearched) {
      setBotData(getBotData(searchreport));
    }
  }, [searchBot, hasSearched]);

  const handleSearch = async () => {
    setIsLoading(true);
    setHasSearched(false);

    try {
      await dispatch( searchBotAllReportAction({
        fromDate: fromDate ? dayjs(fromDate).format("MM-DD-YYYY") : null,
        toDate: toDate ? dayjs(toDate).format("MM-DD-YYYY") : null,
        search,
      }));

      setHasSearched(true);
      setShowTable(true);
    } finally {
      setIsLoading(false);
    }
  };


  const resetSearch = () => {
    setHasSearched(false);
    setSearch("");
    setBotData([]);
    setShowTable(false); // Hide table when reset

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const createTableHeader = () => `
  <tr>
    <th style="padding: 12px; text-align: center; background-color: #EAC86B; color: #000; font-weight: 700; font-family: Poppins, sans-serif;">Carrier Name</th>
    <th style="padding: 12px; text-align: center; background-color: #EAC86B; color: #000; font-weight: 700; font-family: Poppins, sans-serif;">Start Date</th>
    <th style="padding: 12px; text-align: center; background-color: #EAC86B; color: #000; font-weight: 700; font-family: Poppins, sans-serif;">Status</th>
    <th style="padding: 12px; text-align: center; background-color: #EAC86B; color: #000; font-weight: 700; font-family: Poppins, sans-serif;">Purchased By</th>
    <th style="padding: 12px; text-align: center; background-color: #EAC86B; color: #000; font-weight: 700; font-family: Poppins, sans-serif;">Agency Code</th>
    <th style="padding: 12px; text-align: center; background-color: #EAC86B; color: #000; font-weight: 700; font-family: Poppins, sans-serif;">Recent Updates</th>
    <th style="padding: 12px; text-align: center; background-color: #EAC86B; color: #000; font-weight: 700; font-family: Poppins, sans-serif;">Create Time</th>
  </tr>
`;

const createTableRow = (row, index) => `
  <tr style="background-color: ${index % 2 === 0 ? '#FFF2CF' : '#ffffff'}; font-family: Poppins, sans-serif;">
    <td style="padding: 10px; text-align: center; color: #000; border-bottom: 1px solid #CECECE;">${row.carrierName}</td>
    <td style="padding: 10px; text-align: center; color: #000; border-bottom: 1px solid #CECECE;">${row.startDate}</td>
    <td style="padding: 10px; text-align: center; color: #000; border-bottom: 1px solid #CECECE;">${row.status}</td>
    <td style="padding: 10px; text-align: center; color: #000; border-bottom: 1px solid #CECECE;">${row.purchasedByUserEmail}</td>
    <td style="padding: 10px; text-align: center; color: #000; border-bottom: 1px solid #CECECE;">${row.carrierAgencyCode}</td>
    <td style="padding: 10px; text-align: center; color: #000; border-bottom: 1px solid #CECECE;">${row.changes}</td>
    <td style="padding: 10px; text-align: center; color: #000; border-bottom: 1px solid #CECECE;">${dayjs(row.insertedOn).format("MM-DD-YYYY, hh:mm:ss A")}</td>
  </tr>
`;

const downloadPDF = async () => {
  try {
    setIsDownloading(true);
    const allData = searchReportDownload;
    const pdf = new jsPDF('l', 'mm', 'a4');
    const pageWidth = pdf.internal.pageSize.getWidth();
    const pageHeight = pdf.internal.pageSize.getHeight();
    const margins = 15;

    const hiddenDiv = document.createElement('div');
    hiddenDiv.style.position = 'absolute';
    hiddenDiv.style.left = '-9999px';
    hiddenDiv.style.top = '-9999px';

    pdf.setFontSize(16);
    pdf.text('Bot Report', margins, margins);
    pdf.setFontSize(12);
    // pdf.text(`Search Term: "${search}"`, margins, margins + 10);

    const rowHeight = 12;
    const headerHeight = 25;
    const footerHeight = 10;
    const availableHeight = pageHeight - margins * 2 - headerHeight - footerHeight;
    const rowsPerPdfPage = Math.floor(availableHeight / rowHeight);

    for (let i = 0; i < allData.length; i += rowsPerPdfPage) {
      if (i > 0) pdf.addPage();

      const pageData = allData.slice(i, i + rowsPerPdfPage);
      const tableHtml = `
        <table style="width: 100%; border-collapse: collapse; font-size: 12px;">
          ${createTableHeader()}
          ${pageData.map((row, index) => createTableRow(row, index)).join('')}
        </table>
      `;
      
      hiddenDiv.innerHTML = tableHtml;
      document.body.appendChild(hiddenDiv);

      const canvas = await html2canvas(hiddenDiv, {
        scale: 2,
        useCORS: true,
        logging: false,
        backgroundColor: '#ffffff'
      });

      document.body.removeChild(hiddenDiv);

      const imgData = canvas.toDataURL('image/png');
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pageWidth - (margins * 2);
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, 'PNG', margins, i === 0 ? margins + 15 : margins, pdfWidth, pdfHeight);

      const pageNumber = `Page ${pdf.internal.getNumberOfPages()}`;
      pdf.setFontSize(10);
      pdf.text(pageNumber, pageWidth - margins - 20, pageHeight - margins);
    }

    const date = new Date().toLocaleDateString();
    pdf.text(`Generated on: ${date}`, margins, pageHeight - margins);
    pdf.text(`Total Records: ${allData.length}`, margins, pageHeight - margins - 5);

    pdf.save(`bot-report-${date}.pdf`);
  } catch (error) {
    console.error('Error generating PDF:', error);
  } finally {
    setIsDownloading(false);
  }
};


  return (
    <Box sx={{padding: '0 50px'}}>
       <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Grid container spacing={2} sx={{ mb: 3, marginTop: '50px' }}>
      <Grid item xs={12}>
          <Typography sx={{ fontSize: "16px", fontWeight: "bold",marginBottom:"20px" }}>Search</Typography>
        </Grid>
        <Grid item xs={4} sx={{display:'flex'}}>
        <Grid item xs={6}>
        <Typography sx={{marginTop: "-25px", marginBottom:"5px", fontFamily: '"Poppins", sans-serif' }}> From Date </Typography>

        <DatePicker
          
          value={fromDate}
          onChange={(newDate) => setFromDate(newDate)}
        
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>
      <Grid item xs={6}>
      <Typography sx={{marginTop: "-25px", marginBottom:"5px", marginLeft: "16px", fontFamily: '"Poppins", sans-serif' }}> To Date </Typography>

        <DatePicker
         sx={{marginLeft:'15px'}}

         
          value={toDate}
          onChange={(newDate) => setToDate(newDate)}
          
          renderInput={(params) => <TextField {...params} fullWidth />}
        />
      </Grid>
        </Grid>
        
        <Grid item xs={2} sx={{margin: 0}}>
        <Typography sx={{marginTop: "-25px", marginBottom:"1px", marginLeft: "0px", fontFamily: '"Poppins", sans-serif' }}>Enter search term  </Typography>

          <TextField
            fullWidth
            sx={{ width: '100%', margin: 0, marginTop:"4px"}}
            variant="outlined"
            placeholder="Enter search term"
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
        <Grid item xs={2} sx={{display: 'flex'}}>
          <Button
            fullWidth
            id="search"
            variant="contained"
            className="btn-primary"
            color="primary"
            onClick={handleSearch}
            // disabled={!search.trim()}
            disabled={ !fromDate || !toDate}
          >
            Search
          </Button>
          <Button
            fullWidth
            id="reset"
            variant="contained"
            className="btn-secondary"
            color="secondary"
            onClick={resetSearch}
            disabled={!hasSearched}
            sx={{marginLeft:'15px'}}
          >
            Reset
          </Button>
        </Grid>
      </Grid>
      </LocalizationProvider>

      {hasSearched && (
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: '10px 0 20px 0',
        }}
      >
            <Typography variant="h5" gutterBottom>
              Bot Report
            </Typography>
            <Button
              variant="contained"
              className="btn-secondary"
              onClick={downloadPDF}
              disabled={isDownloading || count === 0}
              startIcon={<DownloadIcon />}
            >
              {isDownloading ? 'Downloading...' : 'Download All'}
            </Button>
          </Box>
      )}
  
    
      <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} ref={tableRef}>
        <TableHead>
          <TableRow>
            <TableCell className="styled-table-heading">Carrier Name</TableCell>
            <TableCell className="styled-table-heading">Start Date</TableCell>
            <TableCell className="styled-table-heading">Status</TableCell>
            <TableCell className="styled-table-heading">Purchased By</TableCell>
            <TableCell className="styled-table-heading">Agency Code</TableCell>
            <TableCell className="styled-table-heading">Recent Updates</TableCell>
            <TableCell className="styled-table-heading">Create Time</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
        {isLoading ? (
    // Loader while loading data
    <TableRow>
    <TableCell colSpan={8}>
        <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
        <Typography variant="subtitle1" color="textSecondary">
            <Loader />
        </Typography>
        </Box>
    </TableCell>
</TableRow>
  ) : botData.length === 0 ? (
          <TableRow>
            <TableCell colSpan={8} align="center">
              <Typography variant="body2">No data found</Typography>
            </TableCell>
          </TableRow>
        ):(
          botData.map((row) => (
            <TableRow key={row.id} className="styled-table-row">
              <TableCell>{row.carrierName}</TableCell>
              <TableCell>{row.startDate}</TableCell>
              <TableCell>{row.status}</TableCell>
              <TableCell>{row.purchasedByUserEmail}</TableCell>
              <TableCell>{row.carrierAgencyCode}</TableCell>
              <TableCell>{row.changes}</TableCell>
              <TableCell>
                {dayjs(row.insertedOn).format("MM-DD-YYYY, hh:mm:ss A")}
              </TableCell>
            </TableRow>
          ))
          
        
         
        )
        }
        </TableBody>
       
      </Table>
      <TablePagination
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={count === 0 ? [] : [5, 10, 15, 25, 50, 100]}
      />
    </TableContainer>
    

  
    </Box>
  );
}

export default BotAllReport;

const getBotData = (bots) => {
  if (!Array.isArray(bots)) {
    return [];
  }
  return bots;
};

