import React, { useState, useEffect } from "react";
import {
  Paper,
  TextField,
  Button,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TablePagination,
  Typography,
} from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { FormControl, Select, MenuItem } from "@mui/material";
import dayjs from "dayjs";
import { useDispatch, useSelector } from "react-redux";
import {
    fetchFilteredData,
  setDashboardPolicyData,
} from "../../../redux/actions/dashboardAction";
import PolicyDetails from "./PolicyDetails";
import Loader from "../../loader/loader";

const DashboardPolicyData = () => {
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [isRowClicked, setIsRowClicked] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filteredData, setFilteredData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  
  useEffect(() => {
    if(!isFiltered){
      dispatch(setDashboardPolicyData(page, rowsPerPage));
    }
  }, [dispatch,page, rowsPerPage]);

  // Load initial data into filteredData
  const dashboardPolicyDataList =
    useSelector((state) => state.dashboardData.policies?.policiesPage?.content) || [];
    const loading = useSelector((state) => state.dashboardData.loading);

    const paginationData =
    useSelector((state) => state.dashboardData.policies?.policiesPage) || [];

  useEffect(() => {
    setFilteredData(dashboardPolicyDataList); // Load all data by default
  }, [dashboardPolicyDataList]);

  // Update the filter but don't apply it until "Apply" is clicked
  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "fromDate") setFromDate(value);
    if (name === "toDate") setToDate(value);
  };

  const handleApply = () => {
    const formData = {
      fromDate: dayjs(fromDate).toISOString(),
      toDate: dayjs(toDate).toISOString(),
      lineOfBusiness: selectedFilter,
    };

    if ((fromDate && toDate) || selectedFilter !== "All") {
      // Fetch filtered data based on the status filter and/or date range
      dispatch(fetchFilteredData(formData, page, rowsPerPage));
      setIsFiltered(true);
    } else {
      // Show all data if no filters are applied      
      setFilteredData(dashboardPolicyDataList);
      setIsFiltered(false);
    }
    // dispatch(fetchFilteredData(formData, page, rowsPerPage)); // Fetch data based on filter

    // Filter data in the table view
    const filtered = dashboardPolicyDataList.filter((policy) => {
      const isWithinDateRange =
        dayjs(policy.effectiveDate).isBetween(fromDate, toDate, null, "[]") ||
        dayjs(policy.expiryDate).isBetween(fromDate, toDate, null, "[]");
      const matchesFilter =
        selectedFilter === "All" || policy.lineOfBusiness === selectedFilter;
      return isWithinDateRange && matchesFilter;
    });

    setFilteredData(filtered); // Update the filteredData state
  };

  const handleRowClick = (id) => {
    setSelectedId(id);
    setOpen(true);
    setIsRowClicked(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        lineOfBusiness: selectedFilter,
      };
      dispatch(fetchFilteredData(formData, newPage, rowsPerPage));
    } else {
      dispatch(setDashboardPolicyData(newPage, rowsPerPage));
    }
  };

  const handleChangeRowsPerPage = (event) => {  
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        lineOfBusiness: selectedFilter,
      };
      dispatch(fetchFilteredData(formData, 0, newRowsPerPage));
    } else {
      dispatch(setDashboardPolicyData(0, newRowsPerPage));
    }
  };


  return (
    <>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>From Date</label>
            <TextField
              type="date"
              name="fromDate"
              id="start-date"
              value={fromDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>To Date</label>
            <TextField
              type="date"
              name="toDate"
              id="End-date"
              value={toDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <FormControl sx={{ display: "flex", flexFlow: "column",  minWidth: "200px" }}>
            <label  htmlFor="line-of-business-select">Line of Business</label>
            <Select
              labelId="filter-select-label"
              id="line-of-business-select"
              value={selectedFilter}
              onChange={handleFilterChange}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Personal Auto">Personal Auto</MenuItem>
              <MenuItem value="Commercial Auto">Commercial Auto</MenuItem>
              <MenuItem value="Homeowners">Homeowners</MenuItem>
              <MenuItem value="Health">Health</MenuItem>
              <MenuItem value="General Liability">General Liability</MenuItem>
            </Select>
          </FormControl>
          <Button
          className="btn-primary"
            variant="contained"
            id="apply-filter-button"
            onClick={handleApply}
            sx={{ ml: 2, mt:3 }}
            disabled={!fromDate || !toDate}
          >
            Apply
          </Button>
        </Box>
      </Box>

      <TableContainer component={Paper} sx={{ mt: 3 }}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="nameheader">Name</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="policynumberheader" align="left">Policy Number</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="phonenumberheader" align="left">Phone Number</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="policytypeheader" align="left">Policy Type</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="companyheader" align="left">Company</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="lineofbusinessheader" align="left">Line of Business</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="effdateheader" align="left">Eff Date</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="expdateheader" align="left">Exp Date</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="statusheader" align="left">Status</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} id="premiumheader" align="left">Premium</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
              <TableCell colSpan={8}>
                <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                  <Typography variant="subtitle1" color="textSecondary">
                    <Loader />
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            ) : filteredData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={10}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 2,
                    }}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      No Data found.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              filteredData.map((row) => (
                <TableRow
                  onClick={() => handleRowClick(row.id)}
                  key={row.id}
                  id={`row-${row.id}`}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className="styled-table-row" 
                >
                  <TableCell component="th" scope="row">
                    {row?.name || "Jennifer Bravo"}
                  </TableCell>
                  <TableCell align="left">{row?.policyNumber}</TableCell>
                  <TableCell align="left">
                    {row?.phoneNumber || "1242141122"}
                  </TableCell>
                  <TableCell align="left">{row?.policyType}</TableCell>
                  <TableCell align="left">{row?.company}</TableCell>
                  <TableCell align="left">{row?.lineOfBusiness}</TableCell>
                  <TableCell align="left">{row?.effectiveDate}</TableCell>
                  <TableCell align="left">{row?.expiryDate}</TableCell>
                  <TableCell align="left">{row?.status || "active"}</TableCell>
                  <TableCell align="left">{row?.premium}</TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={paginationData.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
        component="div"
        count={paginationData.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle id="policydetailsdialogtitle">Policy Details</DialogTitle>
        <DialogContent>
          <PolicyDetails policyId={selectedId} isRowClicked={isRowClicked} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} id="closebutton">Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DashboardPolicyData;
