import React, { useEffect, useState, } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import RaterRoute from './RaterRoute';
import Crm from './Crm';
import './../src/rater.css'
import './../src/deltorotheme.css';

// const defaultTheme = createTheme({
//   typography: {
//     fontFamily: '"Poppins", sans-serif', // Set your desired font family here
//   },
//   palette: {
//     mode: 'light',
//     primary: { main: '#1976d2' },
//   },
// });

// const alternateTheme = createTheme({
//   palette: {
//     mode: 'dark',
//     primary: { main: '#ff4081' },
//   },
//   typography: {
//     fontFamily: '"Poppins", sans-serif', // Set your desired font family here
//   },
// });


function App() {
  const useAlternateTheme = useSelector((state) => state.setThemeReducer.useAlternateTheme);
  const getDefaultTheme = useSelector((state) => state?.theme?.setDefaultTheme);
  // Dynamically apply the selected theme to the body
  useEffect(() => {
    document.body.classList.remove('deltorotheme');
    document.body.classList.add(useAlternateTheme);
  }, [useAlternateTheme]);

  return (
    <>
      <RaterRoute />
    </>
  );
}

export default App;
