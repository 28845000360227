// services/uploadAttachmentServices.js

import apiService from "./apiServices";

const createFormData = (data) => {
    const formData = new FormData();
    formData.append('uploadFile', data[1]);
    formData.append('attachmentInfo', data[0]);
    return formData;
};

export const saveUploadAttachment = async (formData, token) => {
    try {
        const response = await apiService('/attachment/save', 'POST', formData, {}, token);
        return response;
    } catch (error) {
        throw (error?.message || 'Saving attachment failed. Please try again.');
    }
};

export const updateUploadAttachment = async (data, token) => {
    const formData = createFormData(data);
    try {
        const response = await apiService('/attachment/update', 'PUT', formData, null, token);
        return response;
    } catch (error) {
        throw (error?.message || 'Updating attachment failed. Please try again.');
    }
};

export const getAllUploadAttachments = async (userId, token,page,size) => {
    try {
        const response = await apiService(`/attachment/user/${userId}/all?page=${page}&size=${size}&sort=desc`, 'GET', null, null, token);
        return response;
    } catch (error) {
        throw (error?.message || 'Fetching attachments failed. Please try again.');
    }
};

export const deleteUploadAttachmentById = async (id, token) => {
    try {
        const response = await apiService(`/attachment/delete/${id}`, 'DELETE', null, null, token);
        return response;
    } catch (error) {
        throw new Error(error.message || 'Deleting attachment failed. Please try again.');
    }
};

export const previewFileByUserIdAndFileName = async (userId, filename, token) => {
    try {
        // Create the URL for the API request to get the file
        console.log('filename in services', filename);
        const url = `/attachment/getFile/${userId}/${filename}`;
        // Call the apiService to make the GET request to the backend
        const response = await apiService(url, 'GET', null, {}, token, {
            responseType: 'blob', // This ensures the response is treated as a binary file
        });
        console.log(response, 'response from service');
        console.log(response.text  , 'response data from service');
        // Return the full response
        return response;
    } catch (error) {
        throw (error?.message || 'Previewing file failed. Please try again.');
    }
};
