import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  FormControl,
  InputLabel
} from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPolicyAllActivity } from '../../../redux/actions/activityAction';

const initialActivities = [
  {
    policyNumber: "UAD381139",
    type: "Phone",
    contact: "Sanjeev Kumar",
    createdDate: "02-6-2024",
    assignedTo: "Answered",
    status: "Answered",
    dueDate: "Administrator",
    createdBy: "Administrator",
    subject: "Reminder",
    details: ""
  },
  {
    policyNumber: "UAD381140",
    type: "SMS",
    contact: "Administrator",
    createdDate: "02-6-2024",
    assignedTo: "No response",
    status: "No response",
    dueDate: "Administrator",
    createdBy: "Administrator",
    subject: "Reminder",
    details: ""
  },
  {
    policyNumber: "UAD381141",
    type: "Email",
    contact: "MK",
    createdDate: "02-6-2024",
    assignedTo: "Delivered",
    status: "Delivered",
    dueDate: "MICHELLE MIRABAL",
    createdBy: "MICHELLE MIRABAL",
    subject: "Reminder",
    details: ""
  },
  {
    policyNumber: "UAD381141",
    type: "Notes",
    contact: "MICHELLE MIRABAL",
    createdDate: "02-6-2024",
    assignedTo: "Past Due",
    status: "Answered",
    dueDate: "MICHELLE MIRABAL",
    createdBy: "MICHELLE MIRABAL",
    subject: "Past Due",
    details: "Need payment otherwise..."
  },
  {
    policyNumber: "UAD381141",
    type: "Task",
    contact: "MK",
    createdDate: "02-6-2024",
    assignedTo: "Open",
    status: "Open",
    dueDate: "MK",
    createdBy: "MICHELLE MIRABAL",
    subject: "Need POP",
    details: "Need payment otherwise..."
  }
];

const tableHeadData = [
  { id: "policynumber", label: "Policy Number" },
  { id: "type", label: "Type" },
  { id: "contact", label: "Contact" },
  { id: "createddate", label: "Created Date" },
  { id: "assignedto", label: "Assigned To" },
  { id: "status", label: "Status" },
  { id: "dueDate", label: "Due Date" },
  { id: "createdBy", label: "Created By" },
  { id: "subject", label: "Subject" },
  { id: "details", label: "Details" }
];

const poppinsFont = {
  fontFamily: '"Poppins", sans-serif',
};

const tableHeadStyles ={
  fontWeight: 'bold',
  // Set header background color to #1565c0
  color: 'black',
  fontFamily: '"Poppins", sans-serif',
};
 

const Activity = () => {

  const [activities, setActivities] = useState(initialActivities);
  const [filterType, setFilterType] = useState("All");
  
  const all_activity = useSelector((state) => state.allActivity.all_activity);

  console.log(all_activity, 'all_activity')
  const handleTypeChange = (event) => {
    setFilterType(event.target.value);
  };

  useEffect(()=> {
    fetchPolicyAllActivity();
    setActivities(all_activity)
  }, [all_activity])

  const filteredActivities = filterType === "All"
    ? activities
    : activities.filter(activity => activity.type === filterType);

  return (
    <Box p={3} sx={poppinsFont}>
      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
          <Typography sx={{marginTop: "-25px", fontFamily: '"Poppins", sans-serif' }}>Select Policy</Typography>
            <Select
              value={"All"}
              
              sx={poppinsFont}
            >
              <MenuItem id="All" value="All" sx={poppinsFont}>All</MenuItem>
              {/* Add more policy options as needed */}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2}>
          <FormControl fullWidth>
          <Typography sx={{marginTop: "-25px", fontFamily: '"Poppins", sans-serif' }}>Select Type</Typography>
            <Select
              value={filterType}
              onChange={handleTypeChange}
              
              sx={poppinsFont}
            >
              <MenuItem id="all" value="All" sx={poppinsFont}>All</MenuItem>
              <MenuItem id="SMS" value="SMS" sx={poppinsFont}>SMS</MenuItem>
              <MenuItem id="phone" value="Phone" sx={poppinsFont}>Phone</MenuItem>
              <MenuItem id="email" value="Email" sx={poppinsFont}>Email</MenuItem>
              <MenuItem id="notes" value="Notes" sx={poppinsFont}>Notes</MenuItem>
              <MenuItem id="task" value="Task" sx={poppinsFont}>Task</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <TableContainer component={Paper} sx={{
    borderRadius: "4px",
    border: "1px solid #E7E7E7",
    background: "#FFF",
  }}>
        <Table sx={poppinsFont}>
          <TableHead>
            <TableRow>
              {tableHeadData.map((headCell) => (
                <TableCell key={headCell.id} className="styled-table-heading" >
                  <Typography variant="subtitle1" color="primary" sx={{fontWeight: 'bold', fontSize:"14px"}} >{headCell.label}</Typography>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredActivities.map((activity, index) => (
              <TableRow key={index}>
                {tableHeadData.map((headCell) => (
                  <TableCell key={headCell.id} sx={poppinsFont}>{activity[headCell.id]}</TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default Activity;
