import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from '@mui/icons-material/Edit';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  FormControl,
  MenuItem,
  Box,
  Select,
  TextField,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import {
  fetchPriorityTaskAction,
  fetchFilterPriorityTaskAction,
} from "../../../redux/actions/dashboardAction";
import dayjs from "dayjs";
import Loader from "../../loader/loader";
import DashboardPriorityTaskEditPopup from "./DashboardPriorityTaskEditPopup";

import { styled } from "@mui/material/styles";


const getPriorityStyle = (priority) => {
  switch (priority) {
    case "Urgent":
      return { color: "#FF2525" }; // Urgent (Red)
    case "High":
      return { color: "#F68D2B" }; // High Priority (Orange)
    case "Medium":
      return { color: "#EBEB10" }; // Medium (Yellow)
    default:
      return {};
  }
};

const DashboardTaskPriority = () => {
  const dispatch = useDispatch();
  const [selectedFilter, setSelectedFilter] = useState("All"); // Default to 'All'
  const [fromDate, setFromDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
  const [toDate, setToDate] = useState(dayjs().format('YYYY-MM-DD'));
  const [open, setOpen] = useState(false); // To control the dialog state
  const [detailPriorityTask, setDetailPriorityTask] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterData, setFilterData] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);

  useEffect(() => {
    if(!isFiltered){
      dispatch(fetchPriorityTaskAction(page, rowsPerPage));
    }    
  }, [dispatch, page, rowsPerPage]);

  const priority_task_data =
    useSelector((state) => state.dashboardData.all_priority_task?.addTaskList.content) || [];
    const loading = useSelector((state) => state.dashboardData.loading);

    const pagination =
    useSelector((state) => state.dashboardData.all_priority_task?.addTaskList) || [];

    const filter_priority_task_data =
    useSelector((state) => state.dashboardData.all_priority_task?.addTaskList.content) || [];
  
    useEffect(()=> {
      setFilterData(priority_task_data);
      setFilterData(filter_priority_task_data);
    }, [priority_task_data, filter_priority_task_data])

    const handleApply = () => {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        priority: selectedFilter,
      };
    
      if ((fromDate && toDate) || selectedFilter !== "All") {
        // Fetch filtered data based on the status filter and/or date range
        dispatch(fetchFilterPriorityTaskAction(formData, page, rowsPerPage));
        setIsFiltered(true);
      } else {
        // Show all data if no filters are applied      
        setFilterData(priority_task_data);
        setIsFiltered(false);
      }
    };
    
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        priority: selectedFilter,
      };
      dispatch(fetchFilterPriorityTaskAction(formData, newPage, rowsPerPage));
    } else {
      dispatch(fetchPriorityTaskAction(newPage, rowsPerPage));
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    if (isFiltered) {
      const formData = {
        fromDate: fromDate || "",
        toDate: toDate || "",
        priority: selectedFilter,
      };
      dispatch(fetchFilterPriorityTaskAction(formData, 0, newRowsPerPage));
    } else {
      dispatch(fetchPriorityTaskAction(0, newRowsPerPage));
    }
  };

  const handleFilterChange = (event) => {
    setSelectedFilter(event.target.value);
  };

  const fetchUpdatedData = () => {
    dispatch(fetchPriorityTaskAction());
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === "fromDate") setFromDate(value);
    if (name === "toDate") setToDate(value);
  };

  const handleEditTask = (id) => {
    const selectedRow = filterData.find((task) => task.id === id);  
    setDetailPriorityTask(selectedRow)
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false); // Close the dialog
  };

  
  return (
    <>
      <Box sx={{ display: "flex", width: "100%", marginBottom: "15px" }}>
        <Box sx={{ width: "100%", display: "flex", alignItems: "center" }}>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>From</label>
            <TextField
              type="date"
              name="fromDate"
            id="from-date"
              value={fromDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <Box sx={{ display: "flex", flexFlow: "column" }}>
            <label>To</label>
            <TextField
              type="date"
              name="toDate"
              id="to-date"
              value={toDate}
              onChange={handleDateChange}
              InputLabelProps={{ shrink: true }}
              sx={{ mr: 2 }}
            />
          </Box>
          <FormControl
            sx={{ display: "flex", minWidth: "250px", flexFlow: "column" ,id:"Priority"}}
          >
            <label>Priority</label>
            <Select
              labelId="filter-select-label"
              id="filter-select"
              value={selectedFilter}
              onChange={handleFilterChange}
            >
              <MenuItem value="All">All</MenuItem>
              <MenuItem value="Urgent">Urgent</MenuItem>
              <MenuItem value="High">High</MenuItem>
              <MenuItem value="Medium">Medium</MenuItem>
            </Select>
          </FormControl>
          <Button
            variant="contained"
            className="btn-primary"
            onClick={handleApply}
            id="apply-button"
            sx={{ ml: 2, mt:3 }}
            disabled={!fromDate || !toDate}
          >
            Apply
          </Button>
        </Box>
      </Box>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>Policy Number</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Created Date</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Due Date</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Status</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Priority</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Assigned To</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Subject</TableCell>
              <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}} align="left">Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {loading ? (
              <TableRow>
              <TableCell colSpan={8}>
                <Box sx={{ display: "flex", justifyContent: "center", padding: 2 }}>
                  <Typography variant="subtitle1" color="textSecondary">
                    <Loader />
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
            ) : filterData.length === 0 ? (
              <TableRow>
                <TableCell colSpan={9}>
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      padding: 2,
                    }}
                  >
                    <Typography variant="subtitle1" color="textSecondary">
                      No Data found.
                    </Typography>
                  </Box>
                </TableCell>
              </TableRow>
            ) : (
              filterData.map((row) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  className="styled-table-row"
                >
                  <TableCell component="th" scope="row">
                    {row?.policyNumber}
                  </TableCell>
                  <TableCell align="left">{row?.createdDate}</TableCell>
                  <TableCell align="left">{row?.dueDate}</TableCell>
                  <TableCell align="left">{row?.status}</TableCell>
                  <TableCell
                    align="left"
                    style={getPriorityStyle(row?.priority)}
                  >
                    {row?.priority}
                  </TableCell>
                  <TableCell align="left">{row?.assignedTo}</TableCell>

                  <TableCell align="left">{row?.subject}</TableCell>
                  <TableCell align="left">
                    <EditIcon onClick={()=> handleEditTask(row.id)} />
                  </TableCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={pagination.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
        component="div"
        count={pagination.totalElements}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md" id="edit-priority-task-dialog">
  <DialogContent>
    <DialogActions
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        padding: '0'
      }}
      id="dialog-actions"
    >
      <h3 id="edit-priority-task-title">Edit Priority Task</h3>
      <Button onClick={handleClose} id="close-button">Close</Button>
    </DialogActions>
    {/* Pass selectedId as props to PolicyDetails */}
    <DashboardPriorityTaskEditPopup 
      closePopup={handleClose} 
      taskFilterData={detailPriorityTask} 
      id="dashboard-priority-task-edit-popup"
      savePopup={fetchUpdatedData} 
    />
  </DialogContent>            
</Dialog>
    </>
  );
};

export default DashboardTaskPriority;
