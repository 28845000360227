// import React, { useState,useEffect } from 'react';
// import { Container, Collapse,Grid, Box, TextField, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, IconButton } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Sms from './Sms';
// import Phone from './Phone';
// import { getAllEmailAction,searchEmailAction } from '../../../redux/actions/communicationAction';
// import { useDispatch,useSelector } from 'react-redux';

// const Communication = () => {
//   const [expandedRows, setExpandedRows] = useState([]);
//   const emails = useSelector((state) => state.communication.allEmail);
//   const searchEmails = useSelector((state) => state.communication.searchEmail);
//   const dispatch = useDispatch();
//   console.log("emails",emails);

//   const [search, setSearch] = useState("");
//   const [emailData, setEmailData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(6);

//   useEffect(() => {
//     dispatch(getAllEmailAction(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     if (hasSearched) {
//       setEmailData(searchEmails);
//     } else {
//       setEmailData(emails);
//     }
//   }, [emails, searchEmails, hasSearched]);

//   const handleSearch = () => {
//     dispatch(searchEmailAction({ search }, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };
//   const handleChangeRowsPerPage = (event) => {

//     setRowsPerPage(parseInt(event.target.value, 5));
//     setPage(0);
//   };

//   const handleExpandClick = (index) => {
//     setExpandedRows(prevExpandedRows =>
//       prevExpandedRows.includes(index)
//         ? prevExpandedRows.filter(row => row !== index)
//         : [...prevExpandedRows, index]
//     );
//   };

//   const poppinsFont = {
//     fontFamily: '"Poppins", sans-serif',
//   };

//   return (
//     <Box>
//      <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="From"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="To"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Subject"
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Select Policy"
//             select
//             SelectProps={{ native: true }}
//             fullWidth
//             sx={poppinsFont}
//           >
//             <option value="All">All</option>
//           </TextField>
//         </Grid>
//         <Grid item xs={12} display="flex" justifyContent="flex-end">
//           <Button variant="contained" color="primary" sx={{ ...poppinsFont, mr: 2 }}>Search</Button>
//         </Grid>
//       </Grid>




//       <Accordion sx={{poppinsFont, marginTop: '1rem'}}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Email</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <TableContainer component={Paper}>
//             <Table sx={poppinsFont}>
//               <TableHead>
//                 <TableRow>
//                   <TableCell sx={poppinsFont}>Policy Number</TableCell>
//                   <TableCell sx={poppinsFont}>Type</TableCell>
//                   <TableCell sx={poppinsFont}>Date</TableCell>
//                   <TableCell sx={poppinsFont}>Subject</TableCell>
//                   <TableCell sx={poppinsFont}>Delivered</TableCell>
//                   <TableCell sx={poppinsFont}>Response</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {emails.map((row, index) => (
//                   <React.Fragment key={index}>
//                     <TableRow>
//                       <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.type}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.date}</TableCell>
//                       <TableCell sx={poppinsFont}>
//                         <IconButton onClick={() => handleExpandClick(index)}>
//                           {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
//                         </IconButton>
//                         {row.subject || "No Subject"}
//                       </TableCell>
//                       <TableCell sx={poppinsFont}>{row.delivered ? "Yes" : "No"}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.response}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
//                         <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
//                           <Box margin={1} sx={poppinsFont}>
//                             <div>
//                               Detailed information about the subject: {row.subject}
//                             </div>
//                           </Box>
//                         </Collapse>
//                       </TableCell>
//                     </TableRow>
//                   </React.Fragment>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>SMS</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Sms />
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Phone</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Phone />
//         </AccordionDetails>
//       </Accordion>
//     </Box>
//   );
// };

// export default Communication;


// import React, { useState, useEffect } from 'react';
// import { Grid, Box, TextField,Collapse, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, Button, IconButton, TablePagination } from '@mui/material';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import ArrowRightIcon from '@mui/icons-material/ArrowRight';
// import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
// import Sms from './Sms';
// import Phone from './Phone';
// import { getAllEmailAction, searchEmailAction } from '../../../redux/actions/communicationAction';
// import { useDispatch, useSelector } from 'react-redux';

// const Communication = () => {
//   const [expandedRows, setExpandedRows] = useState([]);
//   const emails = useSelector((state) => state.communication.allEmail);
//   const searchEmails = useSelector((state) => state.communication.searchEmail);
//   const dispatch = useDispatch();
//   console.log("searchEmails",searchEmails);
//   console.log("Emails",emails);

//   const [search, setSearch] = useState("");
//   const [emailData, setEmailData] = useState([]);
//   const [hasSearched, setHasSearched] = useState(false);
//   const [page, setPage] = useState(0);
//   const [rowsPerPage, setRowsPerPage] = useState(6);

//   useEffect(() => {
//     dispatch(getAllEmailAction(page, rowsPerPage));
//   }, [dispatch, page, rowsPerPage]);

//   useEffect(() => {
//     if (hasSearched) {
//       setEmailData(searchEmails);
//     } else {
//       setEmailData(emails);
//     }
//   }, [emails, searchEmails, hasSearched]);

//   const handleSearch = () => {
//     dispatch(searchEmailAction({ search }, () => {
//       setHasSearched(true);
//     }));
//   };

//   const resetSearch = () => {
//     setHasSearched(false);
//     setSearch("");
//     setPage(0);
//     dispatch(getAllEmailAction(0, rowsPerPage));
//   };

//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(parseInt(event.target.value, 10));
//     setPage(0);
//   };

//   const handleExpandClick = (index) => {
//     setExpandedRows(prevExpandedRows =>
//       prevExpandedRows.includes(index)
//         ? prevExpandedRows.filter(row => row !== index)
//         : [...prevExpandedRows, index]
//     );
//   };

//   const poppinsFont = {
//     fontFamily: '"Poppins", sans-serif',
//   };

//   return (
//     <Box>
//       <Grid container spacing={2} sx={{ mb: 3 }}>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="From"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="To"
//             type="date"
//             InputLabelProps={{ shrink: true }}
//             fullWidth
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Subject"
//             fullWidth
//             value={search}
//             onChange={(e) => setSearch(e.target.value)}
//             sx={poppinsFont}
//           />
//         </Grid>
//         <Grid item xs={12} md={3}>
//           <TextField
//             label="Select Policy"
//             select
//             SelectProps={{ native: true }}
//             fullWidth
//             sx={poppinsFont}
//           >
//             <option value="All">All</option>
//           </TextField>
//         </Grid>
//         <Grid item xs={12} display="flex" justifyContent="flex-end">
//           <Button variant="contained" color="primary" onClick={handleSearch} sx={{ ...poppinsFont, mr: 2 }}>Search</Button>
//           <Button variant="outlined" onClick={resetSearch} sx={poppinsFont}>Reset</Button>
//         </Grid>
//       </Grid>

//       <Accordion sx={{ poppinsFont, marginTop: '1rem' }}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Email</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <TableContainer component={Paper}>
//             <Table sx={poppinsFont}>
//               <TableHead>
//                 <TableRow>
//                   <TableCell sx={poppinsFont}>Policy Number</TableCell>
//                   <TableCell sx={poppinsFont}>Type</TableCell>
//                   <TableCell sx={poppinsFont}>Date</TableCell>
//                   <TableCell sx={poppinsFont}>Subject</TableCell>
//                   <TableCell sx={poppinsFont}>Delivered</TableCell>
//                   <TableCell sx={poppinsFont}>Response</TableCell>
//                 </TableRow>
//               </TableHead>
//               <TableBody>
//                 {emailData.map((row, index) => (
//                   <React.Fragment key={index}>
//                     <TableRow>
//                       <TableCell sx={poppinsFont}>{row.policyNumber}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.type}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.date}</TableCell>
//                       <TableCell sx={poppinsFont}>
//                         <IconButton onClick={() => handleExpandClick(index)}>
//                           {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
//                         </IconButton>
//                         {row.subject || "No Subject"}
//                       </TableCell>
//                       <TableCell sx={poppinsFont}>{row.delivered ? "Yes" : "No"}</TableCell>
//                       <TableCell sx={poppinsFont}>{row.response}</TableCell>
//                     </TableRow>
//                     <TableRow>
//                       <TableCell colSpan={6} style={{ paddingBottom: 0, paddingTop: 0 }}>
//                         <Collapse in={expandedRows.includes(index)} timeout="auto" unmountOnExit>
//                           <Box margin={1} sx={poppinsFont}>
//                             <div>
//                               Detailed information about the subject: {row.subject}
//                             </div>
//                           </Box>
//                         </Collapse>
//                       </TableCell>
//                     </TableRow>
//                   </React.Fragment>
//                 ))}
//               </TableBody>
//             </Table>
//           </TableContainer>

//           <TablePagination
//             component="div"
//             count={emailData.length}
//             page={page}
//             onPageChange={handleChangePage}
//             rowsPerPage={rowsPerPage}
//             onRowsPerPageChange={handleChangeRowsPerPage}
//           />
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>SMS</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Sms />
//         </AccordionDetails>
//       </Accordion>

//       <Accordion sx={poppinsFont}>
//         <AccordionSummary expandIcon={<ExpandMoreIcon />}>
//           <Typography sx={poppinsFont}>Phone</Typography>
//         </AccordionSummary>
//         <AccordionDetails>
//           <Phone />
//         </AccordionDetails>
//       </Accordion>
//     </Box>
//   );
// };

// export default Communication;


import React, { useState, useEffect } from 'react';
import { Container, Collapse, Grid, Box, TextField, Accordion, AccordionSummary, AccordionDetails, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography, IconButton, TablePagination,Select, MenuItem, Button, InputLabel, FormControl } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Sms from './Sms';
import Phone from './Phone';
import { getAllEmailAction, searchAction,getAllSmsAction,searchSmsAction,getAllPhoneAction,searchPhoneAction } from '../../../redux/actions/communicationAction';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from "@mui/material/styles";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';


const poppinsFont = {
  fontFamily: '"Poppins", sans-serif'
};

// Styled component for table header cells
const StyledTableHeaderCell = styled(TableCell)(({ theme }) => ({
  fontWeight: '700', // Bold font weight for header cells
  backgroundColor: '#1565c0', // Header background color
  color: '#ffffff', // White text color
  fontFamily: '"Poppins", sans-serif',
  textAlign: 'center', // Center text alignment
  padding: '10px !important', // Custom padding for header cells
}));

const StyledTable = styled(Table)({
  borderCollapse: 'collapse', // Ensure no gaps between rows or cells
  width: '100%', // Optional: Ensure table takes full width
});

const StyledTableBodyRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: '#f5f5f5', // Grey background for odd rows
  },
  '&:nth-of-type(even)': {
    backgroundColor: '#ffffff', // White background for even rows
  },
  '&:hover': {
    backgroundColor: theme.palette.action.selected, // Row background changes on hover
  },
}));


const StyledTableBodyCell = styled(TableCell)(({ theme }) => ({
  fontFamily: '"Poppins", sans-serif', // Applying Poppins font
  textAlign: 'center', // Aligning text to center
  padding: '8px', // Customize padding for the cell
  borderBottom: '1px solid rgba(224, 224, 224, 1)', // Optional: Thin border
  margin: 0, // Ensure no extra margin
}));



const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#1565c0', // Match header background color
  color: '#ffffff', // White text color
  fontFamily: '"Poppins", sans-serif', // Match font
  textTransform: 'none', // Prevent uppercase text
  padding: '10px 20px', // Add padding
  '&:hover': {
    backgroundColor: '#104a8a', // Darker blue on hover
  },
}));

const FormWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  flexWrap: 'wrap', // Ensure responsiveness
  marginBottom: theme.spacing(3), // Add spacing at the bottom
}));

const StyledFormControl = styled(FormControl)({
  minWidth: '200px', // Consistent width for dropdowns
});

const CommunicationTable = () => {
  // State management
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [subject, setSubject] = useState("");
  const [communicationType, setCommunicationType] = useState("");
  const [hasSearched, setHasSearched] = useState(false);
  const [expandedRows, setExpandedRows] = useState([]);

  // Pagination states
  const [emailPage, setEmailPage] = useState(0);
  const [smsPage, setSmsPage] = useState(0);
  const [phonePage, setPhonePage] = useState(0);
  const [rowsPerPageEmail, setRowsPerPageEmail] = useState(5);
  const [rowsPerPageSms, setRowsPerPageSms] = useState(5);
  const [rowsPerPagePhone, setRowsPerPagePhone] = useState(5);

  // Redux selectors
  const emails = useSelector((state) => state.communication?.allEmail?.content || []);
  const smspagination = useSelector((state) => state.communication?.allSms?.content || []);
  const phonespagination = useSelector((state) => state.communication?.allPhones?.content || []);
  const searchdatas = useSelector((state) => state.communication?.allSearch || []);

  const emailsPagination = useSelector((state) => state.communication?.allEmail || { totalElements: 0, page: 0 });
  const smsPagination = useSelector((state) => state.communication?.allSms || { totalElements: 0, page: 0 });
  const phonesPagination = useSelector((state) => state.communication?.allPhones || { totalElements: 0, page: 0 });

  const dispatch = useDispatch();

  // Filtered data states
  const [emailData, setEmailData] = useState([]);
  const [smsData, setSmsData] = useState([]);
  const [phoneData, setPhoneData] = useState([]);

  // Initial data fetch
  useEffect(() => {
    dispatch(getAllEmailAction(emailPage, rowsPerPageEmail));
    dispatch(getAllSmsAction(smsPage, rowsPerPageSms));
    dispatch(getAllPhoneAction(phonePage, rowsPerPagePhone));
  }, [dispatch, emailPage, smsPage, phonePage, rowsPerPageEmail, rowsPerPageSms, rowsPerPagePhone]);

  // Data filtering effect
  useEffect(() => {
    if (hasSearched && Array.isArray(searchdatas)) {
      const filterData = (type) => searchdatas.filter(item => item?.type === type);

      if (communicationType) {
        switch (communicationType) {
          case 'email':
            setEmailData(filterData('email'));
            break;
          case 'sms':
            setSmsData(filterData('sms'));
            break;
          case 'phone':
            setPhoneData(filterData('phone'));
            break;
          default:
            break;
        }
      } else {
        setEmailData(filterData('email'));
        setSmsData(filterData('sms'));
        setPhoneData(filterData('phone'));
      }
    } else {
      setEmailData(emails);
      setSmsData(smspagination);
      setPhoneData(phonespagination);
    }
  }, [emails, smspagination, phonespagination, searchdatas, hasSearched, communicationType]);

  // Event handlers
  const handleSearch = () => {
    const searchParams = {
      fromDate,
      toDate,
      subject,
      communicationType,
    };
    dispatch(searchAction(searchParams, () => setHasSearched(true)));
  };

  const resetSearch = () => {
    setHasSearched(false);
    setFromDate("");
    setToDate("");
    setSubject("");
    setCommunicationType("");
    dispatch(getAllEmailAction(emailPage, rowsPerPageEmail));
    dispatch(getAllSmsAction(smsPage, rowsPerPageSms));
    dispatch(getAllPhoneAction(phonePage, rowsPerPagePhone));
  };

  // Pagination handlers
  const handleChangePageEmail = (event, newPage) => setEmailPage(newPage);
  const handleChangePageSms = (event, newPage) => setSmsPage(newPage);
  const handleChangePagePhone = (event, newPage) => setPhonePage(newPage);

  const handleChangeRowsPerPageEmail = (event) => {
    setRowsPerPageEmail(parseInt(event.target.value, 10));
    setEmailPage(0);
  };

  const handleChangeRowsPerPageSms = (event) => {
    setRowsPerPageSms(parseInt(event.target.value, 10));
    setSmsPage(0);
  };

  const handleChangeRowsPerPagePhone = (event) => {
    setRowsPerPagePhone(parseInt(event.target.value, 10));
    setPhonePage(0);
  };

  const handleExpandClick = (index) => {
    setExpandedRows(prev =>
      prev.includes(index) ? prev.filter(row => row !== index) : [...prev, index]
    );
  };
  
// Table rendering function
const renderTable = (data = [], label, pagination = { totalElements: 0 }, page, rowsPerPage, handlePageChange, handleRowsPerPageChange) => {
  const totalCount = pagination.totalElements || data.length; // Use pagination or fallback to data length
  return (
    <Accordion defaultExpanded sx={{ mt: 2, backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={poppinsFont}>{label}</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TableContainer component={Paper}>
          <StyledTable>
            <TableHead>
              <TableRow>
                <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>Policy Number</TableCell>
                <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>Type</TableCell>
                <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>Date</TableCell>
                <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>Subject</TableCell>
                <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>Delivered</TableCell>
                <TableCell className="styled-table-heading" sx={{fontWeight: 'bold'}}>Response</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(data) && data.length > 0 ? (
                data.map((row, index) => (
                  <React.Fragment key={index}>
                    <TableRow className="styled-table-row">
                      <TableCell>{row.policyNumber}</TableCell>
                      <TableCell>{row.type}</TableCell>
                      <TableCell>{row.date}</TableCell>
                      <TableCell>
                        <IconButton onClick={() => handleExpandClick(index)}>
                          {expandedRows.includes(index) ? <ArrowDropDownIcon /> : <ArrowRightIcon />}
                        </IconButton>
                        {row.subject || 'No Subject'}
                      </TableCell>
                      <TableCell>{row.delivered ? 'Yes' : 'No'}</TableCell>
                      <TableCell>{row.response}</TableCell>
                    </TableRow>
                  </React.Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={6} align="center">
                    No data available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={emailsPagination.totalPages === 0 ? [] : [5, 10, 15, 25, 50, 100]}
          component="div"
          count={emailsPagination.totalElements}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePageEmail}
          onRowsPerPageChange={handleChangeRowsPerPageEmail}
          id="pagination"
        />
      </AccordionDetails>
    </Accordion>
  );
};
  
  
return (
  <Box>
   <Box
  sx={{
    display: 'flex',
    gap: '20px', // Consistent space between all items
    alignItems: 'center', // Align items vertically
    justifyContent: 'flex-start', // Start all items from the left
    mb: 2, // Margin bottom for spacing
  }}
>
  <Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <Typography variant="body1" sx={{ mb: 0.5 }}>From</Typography>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        value={fromDate ? dayjs(fromDate) : null}
        onChange={(newValue) => setFromDate(newValue ? newValue.format('MM-DD-YYYY') : '')}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              width: '225px',
              '& .MuiInputBase-root': {
                height: '56px',
                display: 'flex',
                alignItems: 'center',
              },
              '& input': { height: '100%' }, // Ensure the input element stretches
            }}
          />
        )}
      />
    </LocalizationProvider>
  </Box>

  <Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <Typography variant="body1" sx={{ mb: 0.5 }}>To</Typography>
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        placeholder="MM-DD-YYYY"
        value={toDate ? dayjs(toDate) : null}
        format="MM-DD-YYYY"
        onChange={(newValue) => setToDate(newValue ? newValue.format('MM-DD-YYYY') : '')}
        renderInput={(params) => (
          <TextField
            {...params}
            size="small"
            sx={{
              width: 225,
              height: '56px',
              '& .MuiInputBase-root': { height: '56px' },
              ...poppinsFont,
            }}
          />
        )}
      />
    </LocalizationProvider>
  </Box>

  <Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <Typography variant="body1" sx={{ mb: 0.5 }}>Subject</Typography>
    <TextField
      sx={poppinsFont}
      value={subject}
      onChange={(e) => setSubject(e.target.value)}
    />
  </Box>

  <Box
    sx={{
      width: '225px',
      height: '50px',
      marginTop: '-30px',
    }}
  >
    <FormControl sx={{ width: '220px' }}>
      <Typography variant="body1" sx={{ mb: 0.5 }}>Select to search</Typography>
      <Select
        value={communicationType}
        onChange={(e) => setCommunicationType(e.target.value)}
      >
        <MenuItem value="">All</MenuItem>
        <MenuItem value="email">Email</MenuItem>
        <MenuItem value="sms">SMS</MenuItem>
        <MenuItem value="phone">Phone</MenuItem>
      </Select>
    </FormControl>
  </Box>

  <Box>
    <Button
      onClick={handleSearch}
      className="btn-primary"
      sx={{
        textTransform: 'none',
        marginTop:'30px',
        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
        '&:hover': { backgroundColor: '#104a8a' },
      }}
    >
      Search
    </Button>
  </Box>

  <Box>
    <Button
      onClick={resetSearch}
      className="btn-secondary"
      sx={{
        textTransform: 'none',
        marginTop:'30px',
        
        fontFamily: '"Poppins", sans-serif',
        fontSize: '14px',
        '&:hover': { backgroundColor: '#104a8a' },
      }}
    >
      Reset
    </Button>
  </Box>
</Box>


    {!communicationType || communicationType === 'email'
        ? renderTable(emailData, 'Email', emailsPagination, emailPage, rowsPerPageEmail, handleChangePageEmail, handleChangeRowsPerPageEmail)
        : null}
      {!communicationType || communicationType === 'sms'
        ? renderTable(smsData, 'SMS', smsPagination, smsPage, rowsPerPageSms, handleChangePageSms, handleChangeRowsPerPageSms)
        : null}
      {!communicationType || communicationType === 'phone'
        ? renderTable(phoneData, 'Phone', phonesPagination, phonePage, rowsPerPagePhone, handleChangePagePhone, handleChangeRowsPerPagePhone)
        : null}
  </Box>
);
};

export default CommunicationTable;
