
const initialState = {
    theme: {},
    error: null,
    setDefaultTheme: {},
  };
  
  const themeReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'GET_THEME_SUCCESS':
            return { theme: action.payload, error: null };
        case 'GET_DEFAULT_THEME_SUCCESS':
        return { setDefaultTheme: action.payload, error: null };
        case 'SAVE_THEME_SUCCESS':
        case 'UPDATE_THEME_SUCCESS':
            return { ...state, theme: action.payload, error: null };
        case 'GET_THEME_FAILURE':
        case 'SAVE_THEME_FAILUTE':
        case 'UPDATE_THEME_FAILUTE':
            return { ...state, error: action.payload };
        default:
            return state;
    }
  };
  
  export default themeReducer;